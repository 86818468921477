import ApiService from './api';

export default class UserAccess extends ApiService {
  constructor(token) {
    super(token);
  }

  async getUserAccessForServerGroup(serverGroupId) {
    return this.get(`/hsinfra/environment_user_access/for_servergroup/${serverGroupId}/`);
  }

  async createUserAccessForServerGroup(serverGroupId, userAccess) {
    return this.post(`/hsinfra/environment_user_access/for_servergroup/${serverGroupId}/`, userAccess);
  }

  async removeUserAccess(userAccessId) {
    return this.delete(`/hsinfra/environment_user_access/${userAccessId}`);
  }

}
