import React from 'react';
import { useDispatch } from 'react-redux';
import { downloadAttachment } from '../../store/actions/ticket';

export const Attachment = ({ ticket, article, attachment }) => {
    const dispatch = useDispatch();

    const onLinkPress = () => {
        if (ticket && article && attachment) {
            dispatch(downloadAttachment({
                ticketId: ticket.id, 
                articleId: article.id,
                attachmentId: attachment.id,
                filename: attachment.filename,
            }));
        }
    }

    return (
        <div onClick={onLinkPress} style={{ color: '#00abd6', cursor: 'pointer' }}>{attachment?.filename}</div>
    )
}