import PropTypes from "prop-types";
import React from "react";
import { Modal } from "antd";

class AntdModal extends React.Component {
  static propTypes = {
    header: PropTypes.node,
    footer: PropTypes.node,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    bodyStyle: PropTypes.object,
    width: PropTypes.number,
    maskClosable: PropTypes.bool,
    closable: PropTypes.bool,
  };

  static defaultProps = {
    closable: true,
    maskClosable: true
  };

  render() {
    const { header, children, isOpen, onClose, footer, bodyStyle, width, maskClosable, closable } = this.props;

    const props = {
      visible: isOpen,
      title: header,
      onOk: onClose,
      onCancel: onClose,
      footer: footer,
      bodyStyle: bodyStyle,
      width: width,
      maskClosable,
      closable,
    };

    return <Modal {...props}>{children}</Modal>;
  }
}

export default AntdModal;
