import React from "react";
import { Menu, Divider } from "antd";
import { withTranslation } from "react-i18next";
import {
  SettingOutlined,
  CloudServerOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  UserOutlined,
  LogoutOutlined,
  EnvironmentOutlined,
  UpOutlined,
} from "@ant-design/icons";
import CompanySelector from "./CompanySelector";
import logoImage from "../../assets/logoShort.svg";
import history from "../../history";
import routes from "../../shared/routes";
import KeycloakService from "../../service/KeycloakService";
import { useSelector } from "react-redux";
import PermissionedComponent from "../global/PermissionedComponent";
import { PERMISSIONS } from "../../shared/constants";
import PermissionService from "../../service/permission";

const { SubMenu } = Menu;

const Navigation = (props) => {
  const { location } = history;
  const { t } = props;
  const currentCustomer = useSelector(state => state.currentCustomer);

  const navigateTo = (url) => {
    history.push(url);
  };
  const logout = () => {
    KeycloakService.doLogout();
  };

  const selectedRoute = routes.filter((route) => {
    return location.pathname.indexOf(route.path) > -1;
  });

  const locationsPermissionsGranted = () => {
    return PermissionService.isGranted(currentCustomer?.permissions, PERMISSIONS.SERVICE_SETTINGS);
  }

  const projectsPermissionsGranted = () => {
    return PermissionService.isGranted(currentCustomer?.permissions, PERMISSIONS.SERVICE_SETTINGS);
  }

  const supportPermissionsGranted = () => {
    return PermissionService.isGranted(currentCustomer?.permissions, PERMISSIONS.TICKET);
  }

  const settingsUsersPermissionsGranted = () => {
    return PermissionService.isGranted(currentCustomer?.permissions, PERMISSIONS.READ_LIST_USERS);
  }

  const settingsServiceAccountsPermissionsGranted = () => {
    return PermissionService.isGranted(currentCustomer?.permissions, PERMISSIONS.READ_LIST_USERS);
  }

  const selectedRouteName = selectedRoute[0]?.name || "";
  const openSubMenu = selectedRoute[0]?.openSubMenu;

  return (
    <div className="menu-container">
      <CompanySelector />
      <Menu
        mode="inline"
        defaultSelectedKeys={[selectedRouteName]}
        defaultOpenKeys={[openSubMenu]}
        className="top-menu"
      >
        {locationsPermissionsGranted() &&
        <Menu.Item
          key="locations"
          onClick={() => navigateTo("/pools")}
          icon={<EnvironmentOutlined />}
        >
          {t("navigation:locations")}
        </Menu.Item>
        }
        {projectsPermissionsGranted() &&
        <Menu.Item
          key="system-overview"
          onClick={() => navigateTo("/environment")}
          icon={<CloudServerOutlined />}
        >
          {t("navigation:projects")}
        </Menu.Item>
        }
        {supportPermissionsGranted() &&
        <Menu.Item
          key="support"
          icon={<MessageOutlined />}
          onClick={() => navigateTo("/tickets")}
        >
          {t("navigation:support")}
        </Menu.Item>
        }
        <SubMenu
          key="company-settings"
          icon={<SettingOutlined />}
          title={t("navigation:settings")}
        >
          {settingsUsersPermissionsGranted() &&
          <Menu.Item
            key="settings_users"
            onClick={() => navigateTo("/settings/users")}
          >
            {t("navigation:users")}
          </Menu.Item>
          }
          {settingsServiceAccountsPermissionsGranted() &&
          <Menu.Item
            key="settings_service_accounts"
            onClick={() => navigateTo("/settings/service_accounts")}
          >
            {t("navigation:service-accounts")}
          </Menu.Item>
          }
          <Menu.Item
            key="settings_company_profile"
            onClick={() => navigateTo("/settings/company_profile")}
          >
            {t("navigation:company-profile")}
          </Menu.Item>
        </SubMenu>

      </Menu>

      <Menu mode="inline" className="bottom-menu">
        <Menu.Item
          key="my-profile"
          onClick={() => navigateTo("/my-profile")}
          icon={<UserOutlined />}
        >
          {t("navigation:my_profile")}
        </Menu.Item>
        
        <Menu.Item 
          key="logout" 
          icon={<LogoutOutlined />}
          onClick={() => logout()}
        >
          {t("navigation:logout")}
        </Menu.Item>
        
        <Menu.Divider className="navigation-divider" />

        <Menu.Item key="hs-plus">
          <img src={logoImage} alt="logo" />
          <span className="hsPlusInfoCircle">
            <InfoCircleOutlined />
          </span>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default withTranslation()(Navigation);
