import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Page from "../../components/Page";
import { Button, Row, PageHeader } from 'antd';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchServerGroup, fetchDocumentation, 
} from '../../store/actions/systemEnvironment';
import { saveCustomerReference, fetchPricingForServerGroup } from '../../store/actions/billing';
import ServerGroupInfo from '../../components/shared/ServerGroupInfo';
import { fetchClusters, setCluster, fetchCluster } from '../../store/actions/systemEnvironment';

const ServerGroup = (props) => {
  const { t, serverGroup, cluster, } = props;

  const renderHeaderComponents = () => {
    return (
      <Row key="header-components">
        {/* <Button onClick={() => {}}>{t('contactSupport')}</Button> */}
      </Row>
    );
  }

  const dynamicBreadcrumbs = [
    {
      breadcrumbName: cluster?.name,
      path: `${cluster?.id}`,
    },
    {
      breadcrumbName: serverGroup?.name,
      path: "",
    }
  ]

  useEffect(() => {
    const serverGroupId = props.match.params?.serverGroupId;
    if (serverGroupId) {
      props.fetchServerGroup(serverGroupId);
    }
  }, []);

  useEffect(() => {
    const clusterId = props.match?.params?.clusterId;
    if (clusterId) {
      props.fetchCluster(clusterId);
    }

  }, [props.match?.params?.clusterId]);

  if (!serverGroup) {
    return <></>;
  }

  return (
    <Page 
      breadcrumb="server_groups" 
      dynamicBreadcrumbs={dynamicBreadcrumbs}
    >
      <PageHeader
        className="site-page-header"
        title={serverGroup?.name}
        onBack={() => window.history.back()}
        extra={[renderHeaderComponents()]}
      />

      <ServerGroupInfo serverGroup={serverGroup} />
    </Page>
  )
}

ServerGroup.propTypes = {
  t: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchServerGroup,
    saveCustomerReference,
    fetchPricingForServerGroup,
    fetchDocumentation,
    fetchCluster,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    serverGroup: state.serverGroup,
    cluster: state.cluster,
    pool: state.pool,
    pricingCluster: state.pricingCluster,
    pricingServerGroup: state.pricingServerGroup,
    documentation: state.documentation,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('systemEnvironment')(ServerGroup));