import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import PermissionedComponent from '../../components/global/PermissionedComponent';
import { PERMISSIONS } from '../../shared/constants/permissions';
import {
  fetchUserTypes, fetchServiceAccounts, saveUser, deleteServiceAccount
} from '../../store/actions/user';
import PermissionService from '../../service/permission';
import { setPageheader } from '../../store/actions/navigation';
import { Typography, Button, Row, Col, Input, PageHeader, Form, } from 'antd';
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import AntdTable from "../../components/global/AntdTable";
import Page from "../../components/Page";
import AntdModal from '../../components/global/AntdModal';
import AntdSelect from "../../components/form/AntdSelect";
import AntdInput from "../../components/form/AntdInput";
import DeleteBtn from '../../components/shared/DeleteBtn';
import { passwordValidationRule, requiredRule } from '../../shared/helper/inputRules';

const ServiceAccounts = (props) => {

  const { t, userTypes, authenticatedUser, serviceAccounts, currentCustomer } = props;

  const sortName = (a, b) => {
    if (!a.username || !b.username) {
      return 0;
    }

    return sortData(a.username, b.username);
  }

  const sortData = (a, b) => {
    if (a < b) {
      return -1;
    }

    if (a > b) {
      return 1;
    }

    return 0;
  }

  const onDeleteServiceAccount = (user) => {
    props.deleteServiceAccount(user);
  }

  const handleRowClick = (user) => {
    if(PermissionService.isGranted(user.permissions, PERMISSIONS.READ)){
      onEditUser(user.id);
    }
  }

  const onEditUser = (id) => {
    props.history.push(`/settings/service_accounts/${id}`);
  }

  const onSubmitCreateServiceAccount = (values) => {
    props.saveUser({
      username: values.username,
      type: Number(values.type),
      password: values.password,
    });
    setIsModalOpen(false);
  }

  const handleSearch = ({ target: { value } }) => {
    setSearchChars(value);
  }

  const tableColumns = [
    {
      title: t('tableHeaderUsername'),
      dataIndex: 'username',
      sorter: sortName,
      render: (_, user) => (
        <>
        {PermissionService.isGranted(user.permissions, PERMISSIONS.UPDATE)
        ?
        <Link to={`/settings/service_accounts/${user.id}`}>{user.username}</Link>
        :
        <Typography.Text>{user.username}</Typography.Text>
        }
        </>
      ),
    },
    {
      title: t('tableHeaderType'),
      render: (_, user) => (
        <Typography.Text>{getTypeLabel(user)}</Typography.Text>
      ),
      sorter: sortData,
    },
    {
      title: '',
      render: (_, user) => (
      <div>
        {authenticatedUser.id !== user.id && (
          <PermissionedComponent
            grantedPermissions={user.permissions}
            requestedPermission={PERMISSIONS.DELETE}
          >
            <DeleteBtn
              onConfirm={() => onDeleteServiceAccount(user) } 
              confirmTitle={t("confirmDeleteServiceAccount")}
              okText={t("buttons:confirm")}
              cancelText={t("buttons:cancel")}
            />
          </PermissionedComponent>
        )}
      </div>
      )
    }
  ];

  const getTypeLabel = (user) => {
    return userTypes?.data?.[user?.type];
  }

  const getDefaultTypeOption = () => {
    return userTypes?.data && Object.keys(userTypes?.data)[0];
  }

  const getTypeSelectOptions = () => {
    return userTypes && userTypes.data ? Object.keys(userTypes.data).map((key) => ({
      label: userTypes.data[key],
      value: key
    })) : [];
  }

  const openModal = () => {
    form.resetFields();
    setIsModalOpen(true);
  }

  const renderHeaderComponents = () => {
    return (
      <Row gutter={[24, 24]} key="header-components">
        <Col xs={24} sm={13}>
          <Input
            prefix={<SearchOutlined />}
            key="search"
            name="search"
            placeholder={t("placeholderSearch")}
            onChange={handleSearch}
          />
        </Col>
        <Col xs={24} sm={11}>
          <PermissionedComponent
            key="button"
            grantedPermissions={currentCustomer?.permissions}
            requestedPermission={PERMISSIONS.CREATE_SERVICE_ACCOUNT}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              onClick={openModal}
            >
              {t("buttons:createServiceAccount")}
            </Button>
          </PermissionedComponent>
        </Col>
      </Row>
    );
  }

  const renderModal = () => {
    return (
      <AntdModal
        header={t("buttons:createServiceAccount")}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            {t("buttons:cancel")}
          </Button>,

          <Button form="createServiceAccount" key="submit" type="primary" htmlType="submit">
            {t("buttons:submit")}
          </Button>
        ]}
      >
        <Form
          name="createServiceAccount"
          layout="vertical"
          onFinish={onSubmitCreateServiceAccount}
          form={form}
          initialValues={{
            type: getDefaultTypeOption(),
          }}
        >
          <AntdSelect
            name="type"
            type="select"
            label={t("labelType")}
            options={getTypeSelectOptions()}
            rules={[requiredRule()]}
          />
          
          <AntdInput
            name="username"
            type="text"
            placeholder={t("users:labelUsername")}
            label={t("users:labelUsername")}
            rules={[requiredRule()]}
          />

          <AntdInput
            name="password"
            type="password"
            placeholder={t("labelNodePasswordSet")}
            label={t("labelNodePasswordSet")}
            rules={[passwordValidationRule()]}
          />
          
        </Form>
      </AntdModal>
    )
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchChars, setSearchChars] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    props.fetchServiceAccounts();
    props.fetchUserTypes();
  }, []);

  useEffect(() => {
    props.fetchServiceAccounts();
    props.fetchUserTypes();
  }, [props.currentCustomer]);

  useEffect(() => {
    if (searchChars) {
      const filteredServiceAccounts = serviceAccounts.filter((user) => {
        return user.username?.toLowerCase().includes(searchChars.toLowerCase());
      });

      setDataToDisplay(filteredServiceAccounts);
    } else {
      setDataToDisplay([...serviceAccounts]);
    }
  }, [serviceAccounts, searchChars]);

  return (
    <Page breadcrumb="settings_service_accounts">
      
      {renderModal()}

      <PageHeader
        className="site-page-header"
        title={t("pageTitleServiceAccounts")}
        extra={[renderHeaderComponents()]}
      />

      <AntdTable
        rowKey={(record) => record.username}
        columns={tableColumns}
        data={dataToDisplay}
        onRow={(record) => {
          return {
            onClick: () => { handleRowClick(record) },
          };
        }}
      />
    </Page>
  )
}

ServiceAccounts.propTypes = {
  authenticatedUser: PropTypes.object,
  currentCustomer: PropTypes.object,
  serviceAccounts: PropTypes.array,
  t: PropTypes.func,
  fetchServiceAccounts: PropTypes.func,
  fetchUserTypes: PropTypes.func,
  saveUser: PropTypes.func,
  deleteServiceAccount: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchServiceAccounts,
    setPageheader,
    fetchUserTypes,
    saveUser,
    deleteServiceAccount,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    authenticatedUser: state.authenticatedUser,
    serviceAccounts: state.serviceAccounts,
    userTypes: state.userTypes
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('users')(ServiceAccounts));
