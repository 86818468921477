import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Popconfirm } from "antd";
import AntdSelect from "./AntdSelect";
import "./inlineSelect.less";

function InlineEditSelect({
  value,
  okText,
  cancelText,
  name,
  isGranted,
  options,
  text,
  onUpdate
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();

  const onClose = () => {
    setIsVisible(false);
    form.setFieldsValue(value);
  };

  const handleOnChange = () => {
    setIsVisible(true);
  };

  const handleConfirm = () => {
    form.submit();
    setIsVisible(false);
  };

  return (
    <div className="inline-edit">
      {
        <>
          <Form
            form={form}
            initialValues={value}
            onFinish={(values) => {
              onUpdate(values);
            }}
          >
            <Popconfirm
              placement="bottomRight"
              title={text}
              visible={isVisible}
              onConfirm={handleConfirm}
              onCancel={onClose}
              okText={okText}
              cancelText={cancelText}
            >
              <AntdSelect
                className="inline-select"
                name={name}
                type="select"
                options={options || {}}
                disabled={!isGranted}
                onChange={handleOnChange}
              />
            </Popconfirm>
          </Form>
        </>
      }
    </div>
  );
}

InlineEditSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  displayValue: PropTypes.string,
  options: PropTypes.array,
  isGranted: PropTypes.bool,
  onUpdate: PropTypes.func
};

export default InlineEditSelect;
