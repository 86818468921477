import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { Card, Button, Typography, Row, Col } from 'antd';
import { fetchInvite, saveInvite } from '../../store/actions/user';
import './UserInvitation.less';

const UserInvitation = (props) => {
  const { t, invite } = props;

  useEffect(() => {
    const { match, fetchInvite } = props;

    const id = match?.params?.id;

    if (id) {
      fetchInvite(id);
    }
  }, []);

  const handleClick = (hasBeenAccepted) => {
    const { saveInvite, match } = props;
    const id = match?.params?.id;

    saveInvite({
      id,
    });
  }

  if (!invite) return <></>;

  return (
    <div className='user-invitation-container'>
      <Col offset={6} span={12}>
        <Card className='card'>
          <Row align='center'>
            <Typography.Title level={3}>{t('youHaveBeenInvited', { company: invite?.customer_name })}</Typography.Title>
          </Row>
          <Row align='center'>
            <Typography.Text>{t('doYouWantToAcceptIt')}</Typography.Text>
          </Row>
          <Row align='center' className='buttons-row'>
            <Button type="primary" onClick={() => handleClick(true)}>{t('statuses:yes')}</Button>
            <Button danger onClick={() => handleClick(false)}>{t('statuses:no')}</Button>
          </Row>
        </Card>
      </Col>
    </div>
  );
}

UserInvitation.propTypes = {
  history:PropTypes.object,
  fetchInvite: PropTypes.func,
  saveInvite: PropTypes.func,
  invite: PropTypes.object,
  t: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvite,
    saveInvite 
  }, dispatch);
}

function mapStateToProps(state) {
  return { invite: state.invite };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('users')(UserInvitation));
