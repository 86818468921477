import { takeEvery, put, call, select } from "redux-saga/effects";
import * as types from "../types";
import SystemEnvironmentService from "../../service/systemEnvironment";
import i18n from "../../shared/i18n";
import {
  errorNotification,
  successNotification
} from "../actions/notification";
import { waitFor } from "./general";

function* fetchClusters(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    let { clusterId, customerId } = action.payload;
    const clusters = yield call(
      [systemEnvironmentService, systemEnvironmentService.getClusters],
      customerId
    );

    yield put({
      type: types.SET_CLUSTERS,
      payload: clusters
    });
    yield put({
      type: types.SET_POOL,
      payload: null
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:fetchClustersError", {
          error: error.message
        })
      })
    );
    console.error("fetchClusters error", error);
  }
}

function* fetchPools(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(() => new SystemEnvironmentService(token));

    const pools = yield call([
      systemEnvironmentService,
      systemEnvironmentService.getPools
    ], action.payload);

    yield put({
      type: types.SET_POOLS,
      payload: pools
    });

    yield put({ type: types.DEC_LOADING });

  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchPoolsError", { error: error.message })
      })
    );
    console.error("fetchPools error", error);
  }
}

function* fetchPool(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    const pool = yield call(
      [systemEnvironmentService, systemEnvironmentService.getPool],
      action.payload
    );

    yield put({
      type: types.SET_POOL,
      payload: pool
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:fetchPoolError", {
          error: error.message
        })
      })
    );
    console.error("fetchPool error", error);
  }
}

function* fetchServerGroups(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    const groups = yield call(
      [systemEnvironmentService, systemEnvironmentService.getServerGroups],
      action.payload
    );
    yield put({
      type: types.SET_SERVER_GROUPS,
      payload: groups
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:fetchServerGroupsError", {
          error: error.message
        })
      })
    );
    console.error("fetchServerGroups error", error);
  }
}

function* fetchServerGroup(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    const group = yield call(
      [systemEnvironmentService, systemEnvironmentService.getServerGroup],
      action.payload
    );
    yield put({
      type: types.SET_SERVER_GROUP,
      payload: group
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:fetchServerGroupError", {
          error: error.message
        })
      })
    );
    console.error("fetchServerGroup error", error);
  }
}

function* fetchCluster(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    const cluster = yield call(
      [systemEnvironmentService, systemEnvironmentService.getCluster],
      action.payload
    );
    yield put({
      type: types.SET_CLUSTER,
      payload: cluster
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:fetchClusterError", {
          error: error.message
        })
      })
    );
    console.error("fetchCluster error", error);
  }
}

function* fetchDocumentation(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    const documentation = yield call(
      [systemEnvironmentService, systemEnvironmentService.getDocumentation],
      action.payload
    );
    yield put({
      type: types.SET_DOCUMENTATION,
      payload: documentation
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:fetchDocumentationError", {
          error: error.message
        })
      })
    );
    console.error("fetchDocumentation error", error);
  }
}

function* fetchServices(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    const services = yield call(
      [systemEnvironmentService, systemEnvironmentService.getServices],
      action.payload
    );
    yield put({
      type: types.SET_SERVICES,
      payload: services
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:fetchServicesError", {
          error: error.message
        })
      })
    );
    console.error("fetchServicesError error", error);
  }
}

function* fetchVhosts(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    const vhosts = yield call(
      [systemEnvironmentService, systemEnvironmentService.getVhosts],
      action.payload
    );
    yield put({
      type: types.SET_VHOSTS,
      payload: vhosts
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:fetchVhostError", {
          error: error.message
        })
      })
    );
    console.error("fetchVhostError error", error);
  }
}

function* fetchVhost(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    const vhost = yield call(
      [systemEnvironmentService, systemEnvironmentService.getVhost],
      action.payload
    );
    yield put({
      type: types.SET_VHOST,
      payload: vhost
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:fetchVhostError", {
          error: error.message
        })
      })
    );
    console.error("fetchVhost error", error);
  }
}
function* saveVhost(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    const systemEnvironmentService = yield call(
      () => new SystemEnvironmentService(token)
    );
    let { vhost, serverGroupId } = action.payload;
    if (vhost.id) {
      yield call(
        [systemEnvironmentService, systemEnvironmentService.putVhost],
        vhost
      );
    } else {
      yield call(
        [systemEnvironmentService, systemEnvironmentService.postVhost],
        vhost,
        serverGroupId
      );
    }
    yield put(
      successNotification({ message: i18n.t("systemEnvironment:saveVhost") })
    );

    // const vhost = yield call([
    //   systemEnvironmentService,
    //   systemEnvironmentService.getVhost,
    // ], action.payload);
    // yield put({
    //   type: types.SET_VHOST,
    //   payload: vhost,
    // });
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("systemEnvironment:saveVhostError", {
          error: error.message
        })
      })
    );
    console.error("saveVhost error", error);
  }
}

export function* systemEnvironmentSagas() {
  yield takeEvery(types.FETCH_CLUSTERS, fetchClusters);
  yield takeEvery(types.FETCH_POOLS, fetchPools);
  yield takeEvery(types.FETCH_POOL, fetchPool);
  yield takeEvery(types.FETCH_SERVER_GROUPS, fetchServerGroups);
  yield takeEvery(types.FETCH_SERVER_GROUP, fetchServerGroup);
  yield takeEvery(types.FETCH_SERVICES, fetchServices);
  yield takeEvery(types.FETCH_VHOSTS, fetchVhosts);
  yield takeEvery(types.FETCH_VHOST, fetchVhost);
  yield takeEvery(types.SAVE_VHOST, saveVhost);
  yield takeEvery(types.FETCH_CLUSTER, fetchCluster);
  yield takeEvery(types.FETCH_DOCUMENTATION, fetchDocumentation);
}

export default systemEnvironmentSagas;
