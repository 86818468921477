import ApiService from './api';

export default class TicketService extends ApiService {
  constructor(token) {
    super(token);
  }

  async getTickets({ customerId, archived }) {
    return this.get(`/hsservice/ticket/for_customer/${customerId}/${archived ? "?archived=1" : ""}`);
  }

  async getTicket(ticketId) {
    return this.get(`/hsservice/ticket/${ticketId}/`);
  }

  async createTicket({ customerId, body }) {
    return this.post(`/hsservice/request/for_customer/${customerId}/`, body, false);
  }

  async createAnswer({ ticketId, body }) {
    return this.post(`/hsservice/ticket/${ticketId}/answer/`, body, false);
  }
  async closeTicket(ticketId) {
    return this.post(`/hsservice/ticket/${ticketId}/close/`);
  }

  async getCustomerObjects({ customerId, relation }) {
    return this.get(`/hsservice/objects/for_customer/${customerId}/?objects=${relation.join(",")}`);
  }

  async getRequests() {
    return this.get(`/hsservice/request/`);
  }

  async getDownloadUrl({ ticketId, articleId, attachmentId }) {
    const data = {
      url: `${process.env.REACT_APP_API_URL}/hsservice/attachment/${ticketId}/${articleId}/${attachmentId}/`,
    }
    return this.post(`/download_url/token/`, data);
  }
}
