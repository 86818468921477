import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import GrayedLabel from '../../components/global/GrayedLabel';

const SupportOpenTickets = (props) => {
  const { ticketsCount, onTicketsPress, t } = props;

  const label = t('openTickets', {
    count: ticketsCount,
  });

  const onClick = (e) => {
    e.stopPropagation();
    onTicketsPress();
  }

  return (
    <Row>
      <Col>
        <GrayedLabel>{t("support")}</GrayedLabel>
        {ticketsCount > 0
        ?
        <Button className="link" type="link" style={{ paddingLeft: 0, paddingRight: 0 }} onClick={onClick}>{label}</Button>
        :
        <Typography.Text>{label}</Typography.Text>
        }
      </Col>
    </Row>
  )
}

SupportOpenTickets.propTypes = {
  ticketsCount: PropTypes.number,
  t: PropTypes.func,
  onTicketsPress: PropTypes.func,
};

export default withTranslation('systemEnvironment')(SupportOpenTickets);