import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Page from "../../components/Page";
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageHeader, Row, Col } from 'antd';
import { fetchClusters, setCluster } from '../../store/actions/systemEnvironment';
import ProjectCard from './ProjectCard';
import history from "../../history";
import './styles.less';
import PermissionService from '../../service/permission';
import { PERMISSIONS } from '../../shared/constants';

const SystemEnvironment = (props) => {
  const { t, currentCustomer, clusters } = props;

  const onClusterClick = (cluster) => {
    props.setCluster(cluster);
    history.push(`/environment/${cluster?.id}`);
  }

  const onTicketsPress = () => {
    history.push('/tickets');
  }

  useEffect(() => {
    if (currentCustomer && !PermissionService.isGranted(currentCustomer.permissions, PERMISSIONS.SERVICE_SETTINGS)) {
      history.push("/my-profile");
    } else {
      const customerId = currentCustomer?.id;
      if (customerId) {
        const clusterId = props.match.params?.clusterId;
        props.fetchClusters(customerId, clusterId);
      }
    }
  }, [currentCustomer]);

  return (
    <Page breadcrumb="system_environment">
      <PageHeader
        className="site-page-header"
        title={t('allProjects')}
      />

      <Row gutter={10}>
        {clusters.map((cluster, index) => {
          return (
            <Col key={`${index}`} xs={24} sm={8} style={{ marginBottom: 10 }}>
              <ProjectCard
                cluster={cluster} 
                onTicketsPress={onTicketsPress}
                onClick={onClusterClick}
              />
            </Col>
          )
        })}
      </Row>
    </Page>
  )
}

SystemEnvironment.propTypes = {
  t: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchClusters,
    setCluster,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    authenticatedUser: state.authenticatedUser,
    clusters: state.clusters,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('systemEnvironment')(SystemEnvironment));

