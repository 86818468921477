import * as types from '../types';

let alerts = [];

export const initialState = [];
const moduleReducers = { alerts: setAlert };

function addAlert(alert) {
  const exists = alerts.filter((item) => {
    return (
      item.message === alert.message &&
      item.level === alert.level &&
      item.context === alert.context
    );
  }).length;
  if (!exists) {
    alerts.push(alert);
  }
  return alerts;
}

function removeAlert(alert) {
  alerts = alerts.filter(function (item) {
    return item.uuid !== alert.uuid;
  });
  return alerts;
}

function setAlert(state = initialState, action) {
  switch (action.type) {
  case types.ADD_ALERT:
    return addAlert(action.payload);
  case types.REMOVE_ALERT:
    return removeAlert(action.payload);
  default:
    return state;
  }
}

export default moduleReducers;
