import React, { useState } from "react";
import { Form, Row, Col, Button, Typography } from "antd";
import AntdInput from "../../../components/form/AntdInput";
import AntdSelect from "../../../components/form/AntdSelect";
import { saveUser } from "../../../store/actions/user";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "./styles.less";

const { Title } = Typography;

const PersonalInfoForm = (props) => {
  const { t, user, isUpdateGranted } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const handleSubmit = (values) => {
    dispatch(saveUser(values));
    setIsFormUpdated(false);
  }

  const onResetClick = () => {
    const { user } = props;
    form.setFieldsValue(user);
    setIsFormUpdated(false);
  }

  const onValuesChange = (changedValues) => {
    const { user } = props;

    let isUpdated = false;

    Object.keys(changedValues).forEach((propertyName) => {      
      if (user[propertyName] !== changedValues[propertyName]) {
        isUpdated = true;
        return;
      }
    });

    setIsFormUpdated(isUpdated);
  }

  const languages = {
    de: t("general:langGerman"),
    en: t("general:langEnglish"),
    fr: t("general:langFrench")
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={user}
      className="my-profile"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      validateMessages={{ required: t("form:fieldWarning") }}
    >
      <AntdInput hidden name="id" />

      <Title className="sub-header" level={5}>
        {t("myProfile:personalInfo")}
      </Title>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <AntdInput
            name="first_name"
            type="text"
            placeholder={t("labelFirstName")}
            label={t("labelFirstName")}
            required
            disabled={!isUpdateGranted}
          />
        </Col>
        <Col xs={24} md={12}>
          <AntdInput
            name="last_name"
            type="text"
            placeholder={t("labelLastName")}
            label={t("labelLastName")}
            required
            disabled={!isUpdateGranted}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <AntdInput
            name="phone"
            type="text"
            placeholder={t("labelPhone")}
            label={t("labelPhone")}
            disabled={!isUpdateGranted}
          />
        </Col>
        <Col xs={24} md={12}>
          <AntdInput
            name="mobile"
            type="text"
            placeholder={t("labelMobile")}
            label={t("labelMobile")}
            disabled={!isUpdateGranted}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <AntdInput
            name="username"
            type="text"
            placeholder={t("labelUsername")}
            label={t("labelUsername")}
            required
            disabled
          />
        </Col>
        <Col xs={24} md={12}>
          <AntdSelect
            name="language"
            type="select"
            placeholder={t("labelLanguage")}
            label={t("labelLanguage")}
            required
            options={Object.keys(languages).map((key) => ({
              label: languages[key],
              value: key,
            }))}
            disabled={!isUpdateGranted}
          />
        </Col>
      </Row>
      {isUpdateGranted &&
      <Row gutter={8} justify="end">
        <Col>
          <Button
            color="primary"
            className="right"
            htmlType="button"
            disabled={!isFormUpdated}
            onClick={onResetClick}
          >
            {t("buttons:cancelShort")}
          </Button>
        </Col>
        <Col>
          <Button
            color="primary"
            className="right"
            htmlType="submit"
            disabled={!isFormUpdated}
          >
            {t("buttons:save")}
          </Button>
        </Col>
      </Row>
      }
    </Form>
  )
}

export default withTranslation("users")(PersonalInfoForm);
