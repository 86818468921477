import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Row, Col } from 'antd';
import GrayedLabel from '../../../components/global/GrayedLabel';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import './styles.less';

const ServiceCard = (props) => {
  const { service, t } = props;

  return (
    <Card>
      <Typography.Title level={5}>{service.service_name}</Typography.Title>

      <Row>
        <Col span={12}>
          <GrayedLabel>{t('version')}</GrayedLabel>
          <Typography.Text>{service.version}</Typography.Text>
        </Col>
        <Col span={12}>
          <GrayedLabel>{t('endOfLive')}</GrayedLabel>
          <Typography.Text>{moment(service.end_of_life).format('DD.MM.YYYY')}</Typography.Text>
        </Col>
      </Row>
    </Card>
  )
}

ServiceCard.propTypes = {
  service: PropTypes.object,
}

export default withTranslation('systemEnvironment')(ServiceCard);