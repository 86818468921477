import React, { useEffect, useState } from "react";
import { fetchSshEncryptionTypes } from "../../../store/actions/user";
import { useSelector, useDispatch } from "react-redux";
import PermissionService from "../../../service/permission";
import { PERMISSIONS } from "../../../shared/constants/permissions";
import SshKeysForm from "./SshKeysForm";
import LoginInfoForm from "./LoginInfoForm";
import PersonalInfoForm from "./PersonalInfoForm";
import "./styles.less";

const EditUserForm = (props) => {
  const { user } = props;

  const sshEncryptionTypes = useSelector((state) => state.sshEncryptionTypes || { data: [] });
  const dispatch = useDispatch();

  const [isUpdateGranted, setIsUpdateGranted] = useState(false);

  useEffect(() => {
    dispatch(fetchSshEncryptionTypes());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      const isGranted = PermissionService.isGranted(
        user.permissions,
        PERMISSIONS.UPDATE
      );
      setIsUpdateGranted(isGranted);
    }
  }, [user]);

  return (
    <>
      <PersonalInfoForm
        user={user}
        isUpdateGranted={isUpdateGranted}
      />

      <LoginInfoForm
        user={user}
        isUpdateGranted={isUpdateGranted}
      />
      
      <SshKeysForm
        user={user}
        isUpdateGranted={isUpdateGranted}
        sshEncryptionTypes={sshEncryptionTypes}
      />
    </>
  );
};

export default EditUserForm;
