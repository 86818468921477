export const ALERT = {
  DEFAULT_CONTEXT: 'global',
  DEFAULT_LEVEL: 'info',
  LEVELS: {
    SUCCESS: 'success',
    ERROR: 'danger',
    WARNING: 'warning',
    INFO: 'info',
  },
  COLORS: {
    PRIMARY: 'primary',
    SECONDAY: 'secondary',
    LIGHT: 'light',
    DARK: 'dark',
  }
};