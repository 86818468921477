import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Alert, Typography, Button } from "antd";
import ServerStateCircle from "../ServerStateCircle";
import PropTypes from "prop-types";

export const ServerTableRow = (props) => {
    const { server, tableWidth, rowHeight, rowsAmount } = props;

    const alertsContainerRef = useRef();
    const [alertsContainerHeight, setAlertsContainerHeight] = useState(0);

    useEffect(() => {
        setAlertsContainerHeight(alertsContainerRef?.current?.clientHeight);
    }, [alertsContainerRef?.current?.clientHeight]);

    const getServerState = (checks) => {
        if (checks?.find((check) => check.state === ServerCheckState.Critical.value) !== undefined) {
            return ServerCheckState.Critical.value;
        } else if (checks?.find((check) => check.state === ServerCheckState.Warning.value) !== undefined) {
            return ServerCheckState.Warning.value;
        }

        return ServerCheckState.Success.value;
    };

    const ServerCheckState = {
        Critical: {
            value: "Critical",
            alertType: "error",
        },
        Warning: {
            value: "Warning",
            alertType: "warning",
        },
        Success: {
            value: "Success",
            alertType: "Info",
        }
    };

    const renderAlertButton = (check) => {
        if (check.state === ServerCheckState.Critical.value) {
          return <Button style={{padding: 0}} type="link">{"View Open Ticket"}</Button>
        } else if (check.state === ServerCheckState.Warning.value) {
          return <Button style={{padding: 0}} type="link">{"Contact Support"}</Button>
        }
    
        return <></>;
    }

    return (
        <Col
            style={{
                height: rowHeight * rowsAmount + (alertsContainerHeight),
            }}
        >
            <Row align="middle">
                <ServerStateCircle state={getServerState(server?.checks)} />
                <Typography.Text>{server.name}</Typography.Text>
            </Row>
            <div ref={alertsContainerRef} className="alerts-container">
                {server?.checks?.map((check, index) => {
                    return (
                        <Alert
                            key={`${index}`}
                            showIcon={false}
                            className="alert"
                            style={{ width: tableWidth - 32 }}
                            message={
                                <Row justify="space-between" align="middle">
                                    <Col style={{ flex: 1 }}>
                                        <Typography.Text>
                                            {check.display_name} - {check.output}
                                        </Typography.Text>
                                    </Col>
                                    {/* <Col>
                                        {renderAlertButton(check)}
                                    </Col> */}
                                </Row>
                            }
                            type={ServerCheckState[check.state]?.alertType || "info"}
                        />
                    );
                })}
            </div>
        </Col>
    );
};

ServerTableRow.propTypes = {
    server: PropTypes.object.isRequired,
    tableWidth: PropTypes.number.isRequired,
    rowHeight: PropTypes.number.isRequired,
    rowsAmount: PropTypes.number.isRequired,
}
