import * as types from '../types';

const moduleReducers = {
  pricingServerGroup: setPricingServerGroup,
  pricingCluster: setPricingCluster,
  pricingCustomer: setPricingCustomer,
  pricingPdf: setPdf,
};

function setPricingServerGroup(state = [], action) {
  switch (action.type) {
  case types.SET_PRICING_SERVER_GROUP:
    return action.payload;
  default:
    return state;
  }
}
function setPricingCluster(state = [], action) {
  switch (action.type) {
  case types.SET_PRICING_CLUSTER:
    return action.payload;
  default:
    return state;
  }
}
function setPricingCustomer(state = [], action) {
  switch (action.type) {
  case types.SET_PRICING_CUSTOMER:
    return action.payload;
  default:
    return state;
  }
}
function setPdf(state = null, action) {
  switch (action.type) {
  case types.SET_PDF:
    return action.payload;
  default:
    return state;
  }
}

export default moduleReducers;
