import { takeEvery, put, call, select } from "redux-saga/effects";
import * as types from "../types";
import CustomerService from "../../service/customer";
import i18n from "../../shared/i18n";
import {
  errorNotification,
  successNotification
} from "../actions/notification";
import { waitFor } from "./general";
import history from "../../history";

const RECENT_CUSTOMERS_LENGTH = 4;

function* fetchCustomers() {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const customerService = yield call(() => new CustomerService(token));
    const customers = yield call([
      customerService,
      customerService.getCustomers
    ]);
    yield put({
      type: types.SET_CUSTOMERS,
      payload: customers
    });
    yield call(restoreRecentCustomers);
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchCustomersError", { error: error.message })
      })
    );
    console.error("fetchCustomers error", error);
  }
}

function* fetchCustomer(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const customerService = yield call(() => new CustomerService(token));
    const customer = yield call(
      [customerService, customerService.getCustomerById],
      action.payload
    );
    yield put({
      type: types.SET_CURRENT_CUSTOMER,
      payload: customer
    });
    // yield put({ type:types.RESTORE_RECENT_CUSTOMERS });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchCustomerError", { error: error.message })
      })
    );
    console.error("fetchCustomer error", error);
  }
}

function* restoreRecentCustomers() {
  try {
    const recentCustomers =
      JSON.parse(localStorage.getItem("recentCustomers")) || [];
    const { availableCustomers, currentCustomer } = yield select(
      (state) => state
    );
    let storableRecentCustomers = [];
    recentCustomers.forEach((recentCustomer) => {
      let match = availableCustomers.find((customer) => {
        return customer.id == recentCustomer.id;
      });
      if (match) {
        storableRecentCustomers.push(match);
      }
    });

    if (!storableRecentCustomers.length && availableCustomers.length) {
      storableRecentCustomers = availableCustomers.slice(
        0,
        RECENT_CUSTOMERS_LENGTH
      );
    } else if (!storableRecentCustomers.length && !availableCustomers.length) {
      console.error("no customers for this user");
      return;
    }
    if (recentCustomers.length == 0) {
      yield localStorage.setItem(
        "recentCustomers",
        JSON.stringify(storableRecentCustomers)
      );
    }
    yield put({
      type: types.SET_RECENT_CUSTOMERS,
      payload: storableRecentCustomers
    });
    yield put({
      type: types.FETCH_CUSTOMER,
      payload: storableRecentCustomers[0]?.id
    });
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:restoreRecentCustomersError", {
          error: error.message
        })
      })
    );
    console.error("fetchRecentCustomers error", error);
  }
}

function* switchCustomer(action) {
  yield call(addRecentCustomer, action.payload);

  yield put({
    type: types.FETCH_CUSTOMER,
    payload: action.payload.id
  });
  yield call(waitFor, (state) => state.currentCustomer.id == action.payload.id);

  history.push("/environment");
}

function* addRecentCustomer(customer) {
  try {
    const recentCustomers =
      JSON.parse(localStorage.getItem("recentCustomers")) || [];
    let newRecentCustomers = recentCustomers.filter(
      (resentCustomer) => resentCustomer.id !== customer.id
    );
    newRecentCustomers.unshift(customer);
    newRecentCustomers = newRecentCustomers.slice(0, RECENT_CUSTOMERS_LENGTH);
    yield localStorage.setItem(
      "recentCustomers",
      JSON.stringify(newRecentCustomers)
    );
    yield put({
      type: types.SET_RECENT_CUSTOMERS,
      payload: newRecentCustomers
    });
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:addRecentCustomerError", { error: error.message })
      })
    );
    console.error("addRecentCustomer error", error);
  }
}

function* saveCustomer(action) {
  try {
    const customerService = yield call(() => new CustomerService());
    const result = yield call(
      [customerService, customerService.saveCustomer],
      action.payload
    );
    const customerId = action.payload?.id;
    if (customerId) {
      yield put({ type: types.FETCH_CUSTOMER, payload: customerId });
    }
    yield put(successNotification({ message: "The customer was saved!" }));
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:saveCustomerError", { error: error.message })
      })
    );
    console.error("saveCustomer error", error);
  }
}

export function* customersSagas() {
  yield takeEvery(types.FETCH_CUSTOMERS, fetchCustomers);
  yield takeEvery(types.FETCH_CUSTOMER, fetchCustomer);
  yield takeEvery(types.RESTORE_RECENT_CUSTOMERS, restoreRecentCustomers);
  yield takeEvery(types.SWITCH_CUSTOMER, switchCustomer);
  yield takeEvery(types.SAVE_CUSTOMER, saveCustomer);
}

export default customersSagas;
