import React from 'react';
import PropTypes from 'prop-types';

const ServerStateCircle = ({ state }) => {
  const style = {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginRight: 8,
  };

  const getClassName = () => {
    return `circle-${state.toLowerCase()}`;
  }

  return (
    <div style={style} className={getClassName()}/>
  )
}

ServerStateCircle.propTypes = {
  state: PropTypes.string,
}

export default ServerStateCircle;