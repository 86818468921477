import React from "react";
import { Select, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";

import './CompanySelector.less';
import { switchCustomer } from "../../store/actions/customer";

const { Option } = Select;

const CompanySelector = () => {
  const dispatch = useDispatch();
  const {availableCustomers = [], currentCustomer = {}} = useSelector(state => state);

  if (!availableCustomers || availableCustomers.length === 0) {
    return null;
  }

  const handleSwitchCustomer = (customerId) => {
    dispatch(switchCustomer({id: customerId}));
  };

  return availableCustomers.length > 1 ? (
    <Select
      className="company-selector"
      listItemHeight={3}
      listHeight={214}
      key={!currentCustomer ? 'notSelected' : 'selected'}
      onChange={handleSwitchCustomer}
      defaultValue={currentCustomer?.id ?? null}
      dropdownMatchSelectWidth={false}
    >
      {availableCustomers.map((company) => {
        return (
          <Option
            selected
            value={company.id}
            key={company.id}
            disabled={company.isDisabled ?? false}
            
          >
            <div className="company-name">
              <Avatar
                shape="square"
                size="small"
                className={`${company.isDisabled ? "disabled" : ""}`}
              >
                {company.name.substring(0, 1).toUpperCase()}
              </Avatar>
              <span>{company.name}</span>
            </div>
          </Option>
        );
      })}
    </Select>
  ) : (
    <div className="company-name single-item">
      <Avatar shape="square" size="small">
        {availableCustomers[0].name.substring(0, 1).toUpperCase()}
      </Avatar>
      <span>{availableCustomers[0].name}</span>
    </div>
  );
};

export default CompanySelector;
