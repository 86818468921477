import React from "react";
import { compose } from "redux";
import { PageHeader } from "antd";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Container from "../../components/layout/Container";
import Page from "../../components/Page";

import EditUserForm from "../../components/shared/EditUserForm";

const MyProfile = (props) => {
  const { t, authenticatedUser } = props;

  return (
    <Page breadcrumb="my_profile">
      <PageHeader className="site-page-header" title={t("myProfile:title")} />
      {!authenticatedUser ? (
        <React.Fragment />
      ) : (
        <EditUserForm user={authenticatedUser} />
      )}
    </Page>
  );
};

function mapStateToProps({ authenticatedUser }) {
  return {
    authenticatedUser
  };
}

export default compose(connect(mapStateToProps, null))(
  withTranslation("users")(MyProfile)
);
