import {
  SAVE_PRICING_CUSTOMER_REFERENCE,
  FETCH_PRICING_SERVER_GROUP,
  FETCH_BILLINGS,
  FETCH_PDF,
  FETCH_PRICING_CLUSTER,
} from '../types';

export function fetchPricingForServerGroup(serverGroupId) {
  return {
    type: FETCH_PRICING_SERVER_GROUP,
    payload: serverGroupId,
  };
}

export function fetchPricingForCluster(clusterId) {
  return {
    type: FETCH_PRICING_CLUSTER,
    payload: clusterId, 
  }
}


export function saveCustomerReference(pricing) {
  return {
    type: SAVE_PRICING_CUSTOMER_REFERENCE,
    payload: pricing,
  };
}

export function fetchBillings(customerId) {
  return {
    type: FETCH_BILLINGS,
    payload: customerId,
  };
}

export function fetchPdf(invoiceId) {
  return {
    type: FETCH_PDF,
    payload: invoiceId,
  };
}
