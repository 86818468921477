import { SET_PAGE_HEADER, TOGGLE_SIDEBAR } from '../types';

export function setPageheader(settings) {
  return {
    type: SET_PAGE_HEADER,
    payload: settings,
  };
}
export function toggleSidebar() {
  return { type: TOGGLE_SIDEBAR };
}
