/** ALERT ACTION TYPES */
export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const NEW_ALERT_SUCCESS = "NEW_ALERT_SUCCESS";
export const NEW_ALERT_INFO = "NEW_ALERT_INFO";
export const NEW_ALERT_WARNING = "NEW_ALERT_WARNING";
export const NEW_ALERT_ERROR = "NEW_ALERT_ERROR";

/** LOGIN ACTION TYPES */
export const LOGIN = "LOGIN";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOGING_IN = "SET_LOGING_IN";
export const SET_REDIRECT_AFTER_LOGIN = "SET_REDIRECT_AFTER_LOGIN";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_AUTHENTICATED_USER = "SET_AUTHENTICATED_USER";
export const RESTORE_USER = "RESTORE_USER";
export const IMPERSONATE = "IMPERSONATE";
export const STOP_IMPERSONATE = "STOP_IMPERSONATE";
export const SET_ACTUAL_TOKEN = "SET_ACTUAL_TOKEN";
export const RESET_STATE = "RESET_STATE";

/**  CUSTOMERS TYPES */
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_RECENT_CUSTOMERS = "SET_RECENT_CUSTOMERS";
export const SET_CURRENT_CUSTOMER = "SET_CURRENT_CUSTOMER";
export const SWITCH_CUSTOMER = "SWITCH_CUSTOMER";
export const RESTORE_RECENT_CUSTOMERS = "RESTORE_RECENT_CUSTOMERS";
export const SAVE_CUSTOMER = "SAVE_CUSTOMER";

/** USER RELATED CUSTOMERS TYPES */
export const FETCH_USERS = "FETCH_USERS";
export const SET_USERS = "SET_USERS";
export const SET_SERVICE_ACCOUNTS = "SET_SERVICE_ACCOUNTS";
export const FETCH_TRANSLATIONS = "FETCH_TRANSLATIONS";
export const FETCH_INVITES = "FETCH_INVITES";
export const SET_INVITES = "SET_INVITES";
export const FETCH_INVITE = "FETCH_INVITE";
export const SET_INVITE = "SET_INVITE";
export const SAVE_INVITE = "SAVE_INVITE";
export const UNINVITE_USER = "UNINVITE_USER";
export const REGISTER_USER = "REGISTER_USER";
export const CREATE_INVITE = "CREATE_INVITE";
export const DELETE_INVITE = "DELETE_INVITE";
export const FETCH_ROLES = "FETCH_ROLES";
export const SET_ROLES = "SET_ROLES";
export const FETCH_USER_TYPES = "FETCH_USER_TYPES";
export const SET_USER_TYPES = "SET_USER_TYPES";
export const FETCH_USER = "FETCH_USER";
export const SET_USER = "SET_USER";
export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_CUSTOMER = "SAVE_USER_CUSTOMER";
export const DELETE_USER = "DELETE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const FETCH_SSH_ENCRYPTION_TYPES = "FETCH_SSH_ENCRYPTION_TYPES";
export const SET_SSH_ENCRYPTION_TYPES = "SET_SSH_ENCRYPTION_TYPES";
export const FETCH_SERVICE_ACCOUNTS = "FETCH_SERVICE_ACCOUNTS";
export const SEND_VERIFICATION_MAIL = "SEND_VERIFICATION_MAIL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_STATUS = "VERIFY_EMAIL_STATUS";
export const CANCEL_EMAIL_VERIFICATION = "CANCEL_EMAIL_VERIFICATION";

/** LOCATIONS */
export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const SET_LOCATION = "SET_LOCATION";

/** SYSTEM ENVIRONMENT TYPES */
export const FETCH_CLUSTERS = "FETCH_CLUSTERS";
export const SET_CLUSTERS = "SET_CLUSTERS";
export const SET_CLUSTER = "SET_CLUSTER";
export const FETCH_CLUSTER = "FETCH_CLUSTER";
export const FETCH_POOLS = "FETCH_POOLS";
export const FETCH_POOL = "FETCH_POOL";
export const SET_POOLS = "SET_POOLS";
export const SET_POOL = "SET_POOL";
export const FETCH_SERVER_GROUPS = "FETCH_SERVER_GROUPS";
export const SET_SERVER_GROUPS = "SET_SERVER_GROUPS";
export const FETCH_SERVICES = "FETCH_SERVICES";
export const FETCH_DOCUMENTATION = "FETCH_DOCUMENTATION";
export const SET_DOCUMENTATION = "SET_DOCUMENTATION";
export const SET_SERVICES = "SET_SERVICES";
export const FETCH_VHOSTS = "FETCH_VHOSTS";
export const FETCH_VHOST = "FETCH_VHOST";
export const SET_VHOSTS = "SET_VHOSTS";
export const SET_VHOST = "SET_VHOST";
export const SAVE_VHOST = "SAVE_VHOST";
export const FETCH_SERVER_GROUP = "FETCH_SERVER_GROUP";
export const SET_SERVER_GROUP = "SET_SERVER_GROUP";
export const FETCH_USER_ACCESS_CLUSTER = "FETCH_USER_ACCESS_CLUSTER";
export const SET_USER_ACCESS_CLUSTER = "SET_USER_ACCESS_CLUSTER";
export const CREATE_USER_ACCESS_CLUSTER = "CREATE_USER_ACCESS_CLUSTER";
export const FETCH_USER_ACCESS_SERVER_GROUP = "FETCH_USER_ACCESS_SERVER_GROUP";
export const SET_USER_ACCESS_SERVER_GROUP = "SET_USER_ACCESS_SERVER_GROUP";
export const CREATE_USER_ACCESS_SERVER_GROUP =
  "CREATE_USER_ACCESS_SERVER_GROUP";
export const REMOVE_USER_ACCESS = "REMOVE_USER_ACCESS";

/**  TICKETS TYPES */
export const FETCH_TICKETS = "FETCH_TICKETS";
export const SET_TICKETS = "SET_TICKETS";
export const ADD_TICKET = "ADD_TICKET";
export const FETCH_TICKET = "FETCH_TICKET";
export const SET_TICKET = "SET_TICKET";
export const SAVE_TICKET = "SAVE_TICKET";
export const ANSWER_TICKET = "ANSWER_TICKET";
export const CLOSE_TICKET = "CLOSE_TICKET";
export const FETCH_CUSTOMER_OBJECTS = "FETCH_CUSTOMER_OBJECTS";
export const SET_CUSTOMER_OBJECTS = "SET_CUSTOMER_OBJECTS";
export const FETCH_REQUESTS = "FETCH_REQUESTS";
export const SET_REQUESTS = "SET_REQUESTS";
export const DOWNLOAD_ATTACHMENT = "DOWNLOAD_ATTACHMENT";

/** TOASTS TYPES */
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const NEW_NOTIFICATION_SUCCESS = "NEW_NOTIFICATION_SUCCESS";
export const NEW_NOTIFICATION_INFO = "NEW_NOTIFICATION_INFO";
export const NEW_NOTIFICATION_WARNING = "NEW_NOTIFICATION_WARNING";
export const NEW_NOTIFICATION_ERROR = "NEW_NOTIFICATION_ERROR";

/** navigation types */
export const SET_PAGE_HEADER = "SET_PAGE_HEADER";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SET_SIDEBAR = "SET_SIDEBAR";

/** billing types */
export const FETCH_PRICING_SERVER_GROUP = "FETCH_PRICING_SERVER_GROUP";
export const FETCH_PRICING_CLUSTER = "FETCH_PRICING_CLUSTER";
export const SET_PRICING_SERVER_GROUP = "SET_PRICING_SERVER_GROUP";
export const SET_PRICING_CLUSTER = "SET_PRICING_CLUSTER";
export const SAVE_PRICING_CUSTOMER_REFERENCE =
  "SAVE_PRICING_CUSTOMER_REFERENCE";
export const FETCH_BILLINGS = "FETCH_BILLINGS";
export const SET_PRICING_CUSTOMER = "SET_PRICING_CUSTOMER";
export const FETCH_PDF = "FETCH_PDF";
export const SET_PDF = "SET_PDF";

/** loading */
export const INC_LOADING = "INC_LOADING";
export const DEC_LOADING = "DEC_LOADING";
export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";
