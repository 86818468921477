import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { Upload, Typography, Button, Row, Col, Select, TreeSelect, Popconfirm, Form } from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import { InboxOutlined } from "@ant-design/icons";
import AntdModal from '../../components/global/AntdModal';
import AntdInput from "../../components/form/AntdInput";
import RichEditor from "../../components/shared/RichEditor";
import { fetchCustomerObjects, resetCustomerObjects, fetchRequests, saveTicket } from "../../store/actions/ticket";
import { Attachment } from "./Attachment";
import "./styles.less";
import { isRichEditorEmpty } from "../../shared/richEditorUtil";

const CloseConfirmation = withTranslation('tickets')(({ children, t, onConfirm }) => {
  return (
    <Popconfirm
      okText={t("buttons:yes")}
      cancelText={t("buttons:no")}
      onConfirm={onConfirm}
      title={t("closeCreateTicketModalConfirmation")}
      placement="bottomRight"
    >
      {children}
    </Popconfirm>
  )
})

const CreateTicketModal = (props) => {
  const { t, customerObjects, isOpened, requests, currentCustomer } = props;

  const [step0Form] = Form.useForm();
  const [step1Form] = Form.useForm();
  const [relations, setRelations] = useState({});
  const [step, setStep] = useState(0);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [wizardSteps, setWizardSteps] = useState([]);
  const [editorError, setEditorError] = useState('');

  const onAnswerSelected = (answer, choices) => {
    const question = getChoicesQuestion(choices);
    const index = wizardSteps.findIndex((wizardStep) => wizardStep.question === question);

    const wizardStep = createWizardStepFromChoices(choices, answer);

    if (index > -1) {
      const newRelations = { ...relations };
      let relationsUpdated = false;
      Object.keys(relations).forEach((key) => {
        if (index <= key) {
          relationsUpdated = true;
          delete newRelations[key];
          step0Form.resetFields([`relations[${key}]`]);
        }
      });

      if (relationsUpdated) {
        setRelations(newRelations);
      }

      const updatedWizardSteps = wizardSteps.splice(0, index + 1);
      updatedWizardSteps[index] = wizardStep;
      setWizardSteps(updatedWizardSteps);
    } else {
      setWizardSteps([...wizardSteps, wizardStep]);
    }
  }

  const getSelectedAnswer = () => {
    return wizardSteps[wizardSteps.length - 1]?.answer;
  }

  const onRelationsChange = (stepIndex, values) => {
    setRelations({ ...relations, ...{ [stepIndex]: values } });
  }

  const getChoicesAnswers = (choices) => {
    const values = Object.values(choices || {});
    return values.length > 0 ? values[0] : [];
  }

  const getChoicesQuestion = (choices) => {
    const keys = Object.keys(choices || {});
    return keys.length > 0 ? keys[0] : null;
  }

  const resetModal = () => {
    setStep(0);
    setSubject("");
    setBody("");
    setAttachments([]);
    setWizardSteps([]);
    setRelations({});
    setEditorError('');
    step0Form.resetFields();
    step1Form.resetFields();
  }

  useEffect(() => {
    if (currentCustomer) {
      props.fetchRequests();
      props.resetCustomerObjects()
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (currentCustomer && wizardSteps?.length > 0) {
      const selectedAnswerRelation = getSelectedAnswer()?.relation;
      
      if (selectedAnswerRelation) {
        let needToFetch = false;
        selectedAnswerRelation.forEach((relation) => {
          if (!customerObjects[relation]) {
            needToFetch = true;
          }
        });

        if (needToFetch) {
          props.fetchCustomerObjects(currentCustomer.id, selectedAnswerRelation);
        }
      }
    }
  }, [wizardSteps, currentCustomer]);

  useEffect(() => {
    if (!isOpened) {
      resetModal();
    }
  }, [isOpened]);

  const onNextStepClick = () => {
    if (step < 1) {
      setStep(step + 1);
    } else {
      if (!currentCustomer) {
        return;
      }

      const ticket = {
        objects: JSON.stringify(Object.values(relations).flat()),
        subject: subject,
        body: body,
      };

      const ticketAttributes = collectTicketAttributes();

      if (Object.keys(ticketAttributes).length > 0) {
        ticket.ticket_attributes = JSON.stringify(ticketAttributes);
      }

      props.saveTicket({ 
        customerId: currentCustomer?.id,
        ticket,
        attachments,
      });

      props.onClose();
    }
  }

  const onSubjectChange = (e) => {
    setSubject(e.target.value);
  }

  const onEditorChange = (text) => {
    setBody(text);
    if (isRichEditorEmpty(text)) {
      setEditorError(t('form:fieldWarning'));
    } else {
      setEditorError('');
    }
  }

  const collectTicketAttributes = () => {
    let result = {};

    for(const wizardStep of wizardSteps) {
      result = { ...result, ...(wizardStep.answer?.ticket_attributes || {}) };
    }

    return result;
  }

  const getAnswerByTranslation = (choices, translation) => {
    const answers = getChoicesAnswers(choices);
    return (Array.isArray(answers) && answers.find((answer) => answer.translation === translation)) || null;
  }

  const relationsValid = () => {
    let valid = true;
    Object.keys(relations).forEach((key) => {
      if (relations[key].length === 0) {
        valid = false;
      }
    });

    const currentAnswer = getSelectedAnswer();
    const currentAnswerHasRelations = !!currentAnswer?.relation;
    const currentAnswerRelationsSelected = !!relations[wizardSteps.length - 1];
    if (currentAnswerHasRelations && !currentAnswerRelationsSelected) {
      valid = false;
    }

    return valid;
  }

  const isNextDisabled = () => {
    if (step === 0) {
      const selectedAnswer = getSelectedAnswer();
      if (!selectedAnswer || 
        Object.keys(selectedAnswer.choices || {}).length > 0 || 
        !relationsValid()
      ) {
        return true;
      }
    } else if (step === 1) {
      if (!subject || isRichEditorEmpty(body)) {
        return true;
      }
    }
    return false;
  }

  const onDeleteAttachmentClick = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  }

  const createWizardStepFromChoices = (choices, answer = null) => {
    if (!choices) {
      return null;
    }

    return {
      question: getChoicesQuestion(choices),
      answer,
      choices: choices,
    }
  }

  const renderWizardStep = (wizardStep) => {
    if (!wizardStep || Object.keys(wizardStep || {}).length === 0) {
      return null;
    }

    const answers = getChoicesAnswers(wizardStep.choices);

    return (
      <>
        <Typography.Text>{t(wizardStep.question)}</Typography.Text>
        <Select 
          style={{ width: "100%", marginBottom: 30 }}
          onChange={(value) => { onAnswerSelected(getAnswerByTranslation(wizardStep.choices, value), wizardStep.choices) }}
          value={wizardStep?.answer?.translation ? wizardStep.answer.translation : null}
          placeholder={t("choicePlaceholder")}
        >
          {answers.map((answer, index) => (
            <Select.Option key={`${index}`} value={answer?.translation}>{t(answer?.translation ?? '')}</Select.Option>
          ))}
        </Select>
      </>
    )
  }

  const onRelationsBlur = () => {
    step0Form.validateFields();
  }

  const renderRelations = (relation, stepIndex) => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Row>
            <Typography.Text>{t("relationsTitle")}</Typography.Text>
          </Row>
          <Row>
            <Form.Item
              style={{ width: "100%" }}
              name={`relations[${stepIndex}]`}
              rules={[{ required: true, message: t("form:fieldWarning") }]}
            >
              <TreeSelect 
                onChange={(values) => { onRelationsChange(stepIndex, values) }}
                treeDefaultExpandAll
                value={relations[stepIndex]}
                multiple={true}
                onBlur={onRelationsBlur}
                placeholder={t("relationsPlaceholder")}
              >
                {relation.map((type) => (
                  <TreeSelect.TreeNode
                    key={type}
                    value={type}
                    title={type}
                    selectable={false}
                  >
                    {Object.keys(customerObjects[type] || {}).map((key) => (
                      <TreeSelect.TreeNode
                        key={key}
                        value={key} 
                        title={customerObjects[type][key]}
                      />
                    ))}
                  </TreeSelect.TreeNode>
                ))}
              </TreeSelect>
            </Form.Item>
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <AntdModal
      header={(
        <Row align="space-between">
          <Typography.Text>{t("newSupportRequest")}</Typography.Text>
          <CloseConfirmation onConfirm={props.onClose} >
            <CloseOutlined onClick={() => {}}/>
          </CloseConfirmation>
        </Row>
      )}
      closable={false}
      isOpen={isOpened}
      width={768}
      maskClosable={false}
      footer={[
        <CloseConfirmation key="cancel" onConfirm={props.onClose}>
          <Button onClick={() => {}}>{t("buttons:cancel")}</Button>
        </CloseConfirmation>,

        <Button key="next" disabled={isNextDisabled()} type="primary" onClick={onNextStepClick}>{t(step === 1 ? "buttons:submit" : "buttons:next")}</Button>
      ]}
    >
      <Col>
        <Row>
          <Typography.Text className="step">{t("step", {
            value: step + 1,
            amount: 2,
          })}</Typography.Text>
        </Row>
        {step === 0 &&
        <>
          <Row>
            <Typography.Title level={3}>{t("step0Title")}</Typography.Title>
          </Row>

          {wizardSteps?.length > 0
            ?
            <Form
              layout="vertical"
              form={step0Form}
              style={{ width: "100%" }}
            >
              {wizardSteps?.map((wizardStep, index) => {
                const currentStepHasRelation = !!wizardStep.answer?.relation;
                const relation = wizardStep.answer?.relation;
                const currentStepRelationsValid = relations[index]?.length > 0;
                const isLastStep = index === wizardSteps.length - 1;
                const hasNextStep = !!wizardStep.answer?.choices;
                const shouldRenderNextStep = (!currentStepHasRelation || currentStepRelationsValid) && isLastStep && hasNextStep;
                return (
                  <div key={`${index}`}>
                    {renderWizardStep(wizardStep)}
                    {currentStepHasRelation && renderRelations(relation, index)}
                    {shouldRenderNextStep && renderWizardStep(createWizardStepFromChoices(wizardStep?.answer?.choices))}
                  </div>
                )
              })}
            </Form>
            :
            renderWizardStep(createWizardStepFromChoices(requests))
          } 
        </>
        }

        {step === 1 &&
        <>
          <Row>
            <Typography.Title level={3}>{t("step1Title")}</Typography.Title>
          </Row>

          <Form
            layout="vertical"
            form={step1Form}
          >
            <Typography.Text>{t("subjectTitle")}</Typography.Text>
            <AntdInput 
              value={subject}
              onChange={onSubjectChange}
              name="subject"
              rules={[{ required: true, message: t("form:fieldWarning") }]}
            />

            <Typography.Text>{t("yourMessage")}</Typography.Text>
            <RichEditor 
              onEditorChange={onEditorChange}
              error={editorError}
            />
          </Form>

          <Upload.Dragger
            style={{
              backgroundColor: 'white',
            }}
            beforeUpload={async (_, fileList) => {
              setAttachments([ ...attachments, ...fileList]);
              return Upload.LIST_IGNORE;
            }}
            onChange={(_) => {
            }}
            multiple
          >
            <Row className="upload-container">
              <Col className="upload-icon-container">
                <div className="icon-container">
                  <InboxOutlined className="icon" style={{ fontSize: 18 }}/>
                </div>
              </Col>
              <Col style={{ flex: 1, textAlign: 'left' }}>
                <Typography.Text>{t("attachmentTitle")}</Typography.Text>
                <div><Typography.Text className="upload-comment">{t("attachmentSubtitle")}</Typography.Text></div>
              </Col>
            </Row>
          </Upload.Dragger>

          {attachments?.length > 0 && attachments.map((attachment, index) => (
          <Attachment key={`${index}`}
            title={attachment.name}
            index={index}
            onDeleteClick={onDeleteAttachmentClick}
          />
          ))
          }
        </>
        }
      </Col>
    </AntdModal>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCustomerObjects,
    resetCustomerObjects,
    fetchRequests,
    saveTicket,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    authenticatedUser: state.authenticatedUser,
    customerObjects: state.customerObjects,
    requests: state.requests,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('tickets')(CreateTicketModal));
