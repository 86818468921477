import {
  LOGIN, LOGOUT, RESET_PASSWORD, IMPERSONATE, STOP_IMPERSONATE, RESTORE_USER ,SET_NEW_PASSWORD,SET_REDIRECT_AFTER_LOGIN
} from '../types';

export function restoreUser() {
  return { type: RESTORE_USER };
}
export function login(loginCredentials) {
  return {
    type: LOGIN,
    payload: loginCredentials,
  };
}
export function setRedirectAfterLogin(path) {
  return {
    type: SET_REDIRECT_AFTER_LOGIN,
    payload: path,
  };
}

export function resetPassword(credentials) {
  return {
    type: RESET_PASSWORD,
    payload: credentials,
  };
}

export function setNewPassword(credentials) {
  return {
    type: SET_NEW_PASSWORD,
    payload: credentials,
  };
}

export function impersonate(id) {
  return {
    type: IMPERSONATE,
    payload: id,
  };
}

export function stopImpersonate() {
  return {
    type: STOP_IMPERSONATE,
    payload: {},
  };
}

export function logout() {
  return {
    type: LOGOUT,
    payload: {},
  };
}
