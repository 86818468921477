import * as types from '../types';

const moduleReducers = { loadingCount: setLoadingCount, };
let loadingCount = 0;
function setLoadingCount(state = 0, action) {
  switch (action.type) {
  case types.INC_LOADING:
    loadingCount++;
    return loadingCount;
  case types.DEC_LOADING:
    loadingCount--;
    return loadingCount;
  case types.RESET_LOADING:
    loadingCount = 0;
    return loadingCount;
  default:
    return state;
  }
}

export default moduleReducers;
