import * as types from '../types';

const moduleReducers = {
  users: setUsers,
  serviceAccounts: setServiceAccounts,
  invites: setInvites,
  invite: setInvite,
  roles: setRoles,
  user: setUser,
  sshEncryptionTypes: setSshEncryptionTypes,
  userTypes: setUserTypes,
};

function setUsers(state = [], action) {
  switch (action.type) {
  case types.SET_USERS:
    return action.payload;
  case types.REMOVE_USER:
    return state.filter(i => i.id !== action.payload);
  default:
    return state;
  }
}

function setServiceAccounts(state = [], action) {
  switch (action.type) {
  case types.SET_SERVICE_ACCOUNTS:
    return action.payload;
  default:
    return state;
  }
}

function setInvites(state = [], action) {
  switch (action.type) {
  case types.SET_INVITES:
    return action.payload;
  default:
    return state;
  }
}

function setInvite(state = null, action) {
  switch (action.type) {
  case types.SET_INVITE:
    return action.payload;
  default:
    return state;
  }
}

function setUser(state = null, action) {
  switch (action.type) {
  case types.SET_USER:
    return action.payload;
  default:
    return state;
  }
}
function setRoles(state = {}, action) {
  switch (action.type) {
  case types.SET_ROLES:
    return action.payload;
  default:
    return state;
  }
}
function setUserTypes(state = {}, action) {
  switch (action.type) {
  case types.SET_USER_TYPES:
    return action.payload;
  default:
    return state;
  }
}
function setSshEncryptionTypes(state = [], action) {
  switch (action.type) {
  case types.SET_SSH_ENCRYPTION_TYPES:
    return action.payload;
  default:
    return state;
  }
}

export default moduleReducers;
