import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchDocumentation, 
} from '../../../store/actions/systemEnvironment';
import { saveCustomerReference, fetchPricingForServerGroup } from '../../../store/actions/billing';
import Overview from './Content/Overview';
import ServerDetails from './Content/ServerDetails';
import Dashboard from './Content/Dashboard';
import InvoiceInfo from './Content/InvoiceInfo';
import Documentation from './Content/Documentation';
import PermissionService from '../../../service/permission';
import { PERMISSIONS } from '../../../shared/constants';

const ContentState = {
  OVERVIEW: 'overview',
  SERVER_DETAILS: 'server_details',
  DASHBOARD: 'dashboard',
  DOCUMENTATION: 'documentation',
  INVOICE_INFO: 'invoice_info',
}

const ServerGroup = (props) => {
  const { t, serverGroup, pricingServerGroup, currentCustomer, documentation } = props;

  const onMenuSelect = ({ key }) => {
    setActiveContentState(key);
  }

  const renderContent = () => {
    switch (activeContentState) {
      case ContentState.OVERVIEW:
        return <Overview serverGroup={serverGroup}/>

      case ContentState.SERVER_DETAILS:
        return <ServerDetails serverGroup={serverGroup}/>

      case ContentState.DASHBOARD:
        return <Dashboard/>

        case ContentState.DOCUMENTATION:
          return <Documentation documentation={documentation}/>

      case ContentState.INVOICE_INFO:
        return <InvoiceInfo data={pricingServerGroup}/>

      default:
        return <></>
    }
  }

  useEffect(() => {
    const serverGroupId = props.match.params?.serverGroupId;
    if (serverGroupId && currentCustomer) {
      props.fetchPricingForServerGroup(serverGroupId);
      props.fetchDocumentation(serverGroupId);
    }
  }, [currentCustomer]);

  const [activeContentState, setActiveContentState] = useState(ContentState.OVERVIEW);

  const accountingPermissionsGranted = () => {
    return PermissionService.isGranted(currentCustomer?.permissions, PERMISSIONS.ACCOUNTING);
  }

  if (!serverGroup) {
    return <></>;
  }

  return (
    <Row>
      <Col span={5}>
        <Menu onSelect={onMenuSelect} defaultSelectedKeys={[ContentState.OVERVIEW]}>
          <Menu.ItemGroup title={t('serverGroup')}>
            <Menu.Item key={ContentState.OVERVIEW}>{t('overview')}</Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup title={t('infrastructure')}>
            <Menu.Item key={ContentState.SERVER_DETAILS} className="menu-item">{t('serverDetails')}</Menu.Item>
            {/* <Menu.Item key={ContentState.DASHBOARD} className="menu-item">{t('dashboard')}</Menu.Item> */}

            {!!documentation?.doc &&
            <Menu.Item key={ContentState.DOCUMENTATION} className="menu-item">{t('documentation')}</Menu.Item>
            }
          </Menu.ItemGroup>

          {/* <Menu.ItemGroup title={t('managedServices')}>
            <Menu.Item className="menu-item">Debian</Menu.Item>
          </Menu.ItemGroup> */}

          {accountingPermissionsGranted() &&
          <Menu.ItemGroup title={t('billing')}>
            <Menu.Item key={ContentState.INVOICE_INFO} className="menu-item">{t('invoiceInfo')}</Menu.Item>
          </Menu.ItemGroup>
          }
        </Menu>
      </Col>
      <Col span={19} className="server-group-content-container">
        {renderContent()}
      </Col>
    </Row>
  )
}

ServerGroup.propTypes = {
  t: PropTypes.func,
  serverGroup: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveCustomerReference,
    fetchPricingForServerGroup,
    fetchDocumentation,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    serverGroup: state.serverGroup,
    pricingServerGroup: state.pricingServerGroup,
    documentation: state.documentation,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('systemEnvironment')(ServerGroup));