import { takeEvery, put, call, select } from "redux-saga/effects";
import * as types from "../types";
import UserService from "../../service/user";
import i18n, { overrideDefaultTFunction } from "../../shared/i18n";
import { waitFor } from "./general";
import {
  errorNotification,
  successNotification
} from "../actions/notification";
import history from "../../history";
import PermissionService from "../../service/permission";
import { PERMISSIONS } from "../../shared/constants/permissions";
import { verifyEmailStatus } from "../actions/user";

function* fetchUsers() {
  try {
    yield call(waitFor, (state) => state.currentCustomer != null);

    const { token, currentCustomer } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });

    const userService = yield call(() => new UserService(token));
    const users = yield call(
      [userService, userService.getUsers],
      currentCustomer.id
    );
    yield put({
      type: types.SET_USERS,
      payload: users
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchUsersError", { error: error.message })
      })
    );
    console.error("fetchUsersError", error);
  }
}

function* fetchServiceAccounts() {
  try {
    yield call(waitFor, (state) => state.currentCustomer != null);

    const { token, currentCustomer } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });

    const userService = yield call(() => new UserService(token));
    const serviceAccounts = yield call(
      [userService, userService.getServiceAccounts],
      currentCustomer.id
    );
    yield put({
      type: types.SET_SERVICE_ACCOUNTS,
      payload: serviceAccounts
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchUsersError", { error: error.message })
      })
    );
    console.error("fetchUsersError", error);
  }
}

function* deleteUser(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const userService = yield call(() => new UserService(token));
    let { id } = action.payload;
    yield call([userService, userService.deleteUser], id);
    yield put(
      successNotification({ message: i18n.t("saga:deleteUserSuccess") })
    );
    yield put({ type: types.FETCH_SERVICE_ACCOUNTS });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:deleteUserError", { error: error.message })
      })
    );
    console.error("deleteUserError", error);
  }
}

function* fetchInvites() {
  try {
    yield call(waitFor, (state) => state.currentCustomer != null);

    const { currentCustomer } = yield select((state) => state);
    if (
      PermissionService.isGranted(
        currentCustomer.permissions,
        PERMISSIONS.CREATE_INVITATION
      )
    ) {
      yield put({ type: types.INC_LOADING });

      const userService = yield call(() => new UserService());
      const users = yield call(
        [userService, userService.getInvites],
        currentCustomer.id
      );
      yield put({
        type: types.SET_INVITES,
        payload: users
      });
      yield put({ type: types.DEC_LOADING });
    }
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchInvitesError", { error: error.message })
      })
    );
    console.error("fetchInvitesError", error);
  }
}

function* fetchInvite(action) {
  try {
    yield call(waitFor, (state) => state.loggingIn === false);

    const { authenticatedUser } = yield select((state) => state);

    yield put({ type: types.INC_LOADING });

    const userService = yield call(() => new UserService());

    const invite = yield call(
      [userService, userService.getInvite],
      action.payload
    );
    
    if (!authenticatedUser || authenticatedUser?.email === invite?.email) {
      yield put({
        type: types.SET_INVITE,
        payload: invite
      });
    } else {
      yield put(
        errorNotification({ message: i18n.t("saga:errorInviteWrongUser") })
      );
    }

    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchInviteError", { error: error.message })
      })
    );
    console.error("fetchInviteError", error);
  }
}

function* saveInvite(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);

    yield put({ type: types.INC_LOADING });
    const userService = yield call(() => new UserService(token));
    yield call([userService, userService.updateInvite], action.payload);

    yield put(
      successNotification({
        message: `The Invite was ${"accepted"}`
      })
    );
    yield put({ type: types.DEC_LOADING });

    history.push("/");
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchInviteError", { error: error.message })
      })
    );
    console.error("fetchInviteError", error);
  }
}

function* uninviteUser(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const userService = yield call(() => new UserService(token));
    let { userId, customerId } = action.payload;

    yield call([userService, userService.uninviteUser], userId, customerId);
    yield put(
      successNotification({ message: i18n.t("saga:uninviteUserSuccess") })
    );
    yield put({ type: types.FETCH_USERS });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:uninviteUserError", { error: error.message })
      })
    );
    console.error("uninviteUserError", error);
  }
}

function* fetchRoles(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));
    const roles = yield call([userService, userService.getRoles]);
    yield put({
      type: types.SET_ROLES,
      payload: roles
    });
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchRolesError", { error: error.message })
      })
    );
    console.error("fetchRolesError", error);
  }
}

function* fetchUserTypes(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));
    const userTypes = yield call([userService, userService.getUserTypes]);
    yield put({
      type: types.SET_USER_TYPES,
      payload: userTypes
    });
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchUserTypesError", { error: error.message })
      })
    );
    console.error("fetchRolesError", error);
  }
}

function* registerUser(action) {
  try {
    const userService = yield call(() => new UserService());

    yield call([userService, userService.registerUser], action.payload);
    yield put(
      successNotification({ message: "The Registration was successful" })
    );

    yield put({
      type: types.LOGIN,
      payload: {
        username: action.payload?.data?.email,
        password: action.payload?.data?.password
      }
    });

    history.push("/");
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:registerUserError", { error: error.message })
      })
    );
    console.error("registerUserError", error);
  }
}

function* createInvite(action) {
  try {
    yield call(waitFor, (state) => state.currentCustomer !== null);

    const { token, currentCustomer } = yield select((state) => state);
    if (
      PermissionService.isGranted(
        currentCustomer.permissions,
        PERMISSIONS.CREATE_INVITATION
      )
    ) {
      yield put({ type: types.INC_LOADING });

      const userService = yield call(() => new UserService(token));
      yield call(
        [userService, userService.createInvite],
        currentCustomer.id,
        action.payload
      );

      yield put(successNotification({ message: "The Invite was sent" }));
      yield call(fetchInvites);
      yield put({ type: types.DEC_LOADING });
    }
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:createInviteError", { error: error.message })
      })
    );
    console.error("createInviteError", error);
  }
}

function* deleteInvite(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });
    const userService = yield call(() => new UserService(token));
    let { inviteId } = action.payload;

    yield call([userService, userService.deleteInvite], inviteId);
    yield put(
      successNotification({ message: i18n.t("saga:uninviteUserSuccess") })
    );
    yield put({ type: types.FETCH_INVITES });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:deleteInviteError", { error: error.message })
      })
    );
    console.error("deleteInviteError", error);
  }
}

function* fetchUser(action) {
  try {
    yield call(waitFor, (state) => state.token != null);

    const { token } = yield select((state) => state);
    yield put({ type: types.INC_LOADING });

    const userService = yield call(() => new UserService(token));
    const user = yield call(
      [userService, userService.getUser],
      action?.payload
    );
    yield put({
      type: types.SET_USER,
      payload: user
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchUserError", { error: error.message })
      })
    );
    console.error("fetchUserError", error);
  }
}

function* saveUserCustomer(action) {
  yield call(waitFor, (state) => state.currentCustomer != null);

  try {
    const userService = yield call(() => new UserService());
    yield call([userService, userService.updateUserCustomer], action.payload);
    yield put({ type: types.FETCH_USERS });
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:saveUserCustomerError", { error: error.message })
      })
    );
    console.error("saveUserCustomerError", error);
  }
}

function* saveUser(action) {
  try {
    yield call(waitFor, (state) => state.currentCustomer != null);

    const { authenticatedUser, currentCustomer } = yield select(
      (state) => state
    );
    let user = action?.payload;

    if (!user) {
      console.error("no user given error");
      return;
    }
    const userService = yield call(() => new UserService());
    let result;
    if (user.id !== null && user.id !== undefined) {
      result = yield call([userService, userService.updateUser], user);
      if (user.id === authenticatedUser.id) {
        const currentUser = yield call([
          userService,
          userService.getCurrentUser
        ]);
        yield put({
          type: types.SET_AUTHENTICATED_USER,
          payload: currentUser
        });
      }
      if (parseInt(user.type, 10) === 1) {
        history.push("/settings/users/");
      } else {
        const { location } = history;
        if (location.pathname.includes("/my-profile")) {
          history.push("/my-profile");
        } else if (location.pathname.includes("/service_accounts")) {
          history.push("/settings/service_accounts/");
        } else {
          history.push("/settings/users/");
        }
      }
    } else {
      result = yield call(
        [userService, userService.createServiceAccount],
        currentCustomer.id,
        user
      );
      yield put({ type: types.FETCH_SERVICE_ACCOUNTS });
      yield put({ type: types.DEC_LOADING });
    }
    yield put(successNotification({ message: "The User was updated" }));
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:saveUserError", { error: error.message })
      })
    );
    console.error("saveUserError", error);
  }
}

function* fetchSshEncryptionTypes() {
  try {
    yield call(waitFor, (state) => state.token != null);

    const { token } = yield select((state) => state);

    const userService = yield call(() => new UserService(token));
    const encryptionTypes = yield call([
      userService,
      userService.getSshEncryptionTypes
    ]);
    yield put({
      type: types.SET_SSH_ENCRYPTION_TYPES,
      payload: encryptionTypes
    });
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchSshEncryptionTypesError", {
          error: error.message
        })
      })
    );
    console.error("fetchSshEncryptionTypesError error", error);
  }
}

function* sendVerifyEmail(action) {
  try {
    const userService = yield call(() => new UserService());

    yield call([userService, userService.sendVerifyEmail], action.payload);

    yield put(
      successNotification({ message: i18n.t("saga:verificationMailSent") })
    );
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:verificationMailError", {
          error: error.message
        })
      })
    );
    console.error("send verification mail error", error);
  }
}

function* verifyEmail(action) {
  try {
    const userService = yield call(() => new UserService());

    yield call([userService, userService.verifyEmail], action.payload);

    yield put(
      successNotification({ message: i18n.t("saga:emailSuccessfullyVerified") })
    );

    yield put(verifyEmailStatus("success"));
    history.push("/my-profile");
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:emailVerificationFailed", {
          error: error.message
        })
      })
    );
    yield put(verifyEmailStatus("failed"));
    console.error("Email verification error", error);
  }
}

function* cancelEmailVerification(action) {
  try {
    const userService = yield call(() => new UserService());

    yield call(
      [userService, userService.cancelEmailVerification],
      action.payload
    );

    yield put(
      successNotification({
        message: i18n.t("saga:emailVerificationCancelled")
      })
    );
    yield put(verifyEmailStatus("success"));
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:failedCancelVerification", {
          error: error.message
        })
      })
    );
    yield put(verifyEmailStatus("failed"));
    console.error("Cancel email verification error", error);
  }
}

function* fetchTranslations(action) {
  try {
    const userService = yield call(() => new UserService());

    const translations = yield call(
      [userService, userService.fetchTranslations],
      action.payload
    );

    for(const langKey of Object.keys(translations)) {
      const lang = translations[langKey];
      for(const namespaceKey of Object.keys(lang)) {
        const resources = translations[langKey][namespaceKey];
        i18n.addResourceBundle(langKey, namespaceKey, resources);
      }
    }

    overrideDefaultTFunction();

  } catch (error) {
    console.error("Fetch translations error", error);
  }
}

export function* userSagas() {
  yield takeEvery(types.FETCH_USERS, fetchUsers);
  yield takeEvery(types.FETCH_SERVICE_ACCOUNTS, fetchServiceAccounts);
  yield takeEvery(types.FETCH_INVITES, fetchInvites);
  yield takeEvery(types.FETCH_INVITE, fetchInvite);
  yield takeEvery(types.UNINVITE_USER, uninviteUser);
  yield takeEvery(types.SAVE_INVITE, saveInvite);
  yield takeEvery(types.FETCH_ROLES, fetchRoles);
  yield takeEvery(types.FETCH_USER_TYPES, fetchUserTypes);
  yield takeEvery(types.REGISTER_USER, registerUser);
  yield takeEvery(types.FETCH_USER, fetchUser);
  yield takeEvery(types.SAVE_USER, saveUser);
  yield takeEvery(types.DELETE_USER, deleteUser);
  yield takeEvery(types.SAVE_USER_CUSTOMER, saveUserCustomer);
  yield takeEvery(types.CREATE_INVITE, createInvite);
  yield takeEvery(types.DELETE_INVITE, deleteInvite);
  yield takeEvery(types.FETCH_SSH_ENCRYPTION_TYPES, fetchSshEncryptionTypes);
  yield takeEvery(types.SEND_VERIFICATION_MAIL, sendVerifyEmail);
  yield takeEvery(types.CANCEL_EMAIL_VERIFICATION, cancelEmailVerification);
  yield takeEvery(types.VERIFY_EMAIL, verifyEmail);
  yield takeEvery(types.FETCH_TRANSLATIONS, fetchTranslations);
}

export default userSagas;
