import React from 'react';
import { Row, Col } from 'antd';
import StatusLabel, { Status } from './StatusLabel';

const ServersStatus = (props) => {
  const {goodCurrent, warningCurrent, errorCurrent, total} = props;
  return (
    <Row>
      {goodCurrent > 0 &&
      <Col span={8}>
        <StatusLabel status={Status.SUCCESS} current={goodCurrent} total={total}/>
      </Col>
      }
      {warningCurrent > 0 &&
      <Col span={8}>
        <StatusLabel status={Status.WARNING} current={warningCurrent} total={total}/>
      </Col>
      }
      {errorCurrent > 0 &&
      <Col span={8}>
        <StatusLabel status={Status.ERROR} current={errorCurrent} total={total}/>
      </Col>
      }
    </Row>
  )
}

export default ServersStatus;