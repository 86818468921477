import ApiService from './api';

export default class SystemEnvironmentService extends ApiService {
  constructor(token){
    super(token);
  }

  async getClusters(customerId) {
    return this.get(`/hsinfra/clusters/for_customer/${customerId}/`);
  }
  async getPools(customerId) {
    return this.get(`/hsinfra/pools/for_customer/${customerId}/`);
  }
  async getPool(poolId) {
    return this.get(`/hsinfra/pools/${poolId}/`);
  }
  async getServerGroups(clusterId) {
    return this.get(`/hsinfra/servergroups/for_cluster/${clusterId}/`);
  }
  async getServerGroup(groupId) {
    return this.get(`/hsinfra/servergroups/${groupId}/`);
  }
  async getServices(groupId) {
    return this.get(`/hscatalog/services/for_servergroup/${groupId}/`);
  }
  async getVhosts(groupId) {
    return this.get(`/hsinfra/vhosts/for_servergroup/${groupId}/`);
  }
  async getVhost(vhostId) {
    return this.get(`/hsinfra/vhosts/${vhostId}/`);
  }
  async putVhost(vhost) {
    return this.put(`/hsinfra/vhosts/${vhost.id}/`,vhost);
  }
  async postVhost(vhost, serverGroupId) {
    return this.post(`/hsinfra/vhosts/for_servergroup/${serverGroupId}/`,vhost);
  }
  async getCluster(clusterId) {
    return this.get(`/hsinfra/clusters/${clusterId}`);
  }
  async getDocumentation(serverGroupId) {
    return this.get(`/hsinfra/servergroups/${serverGroupId}/documentation`);
  }

  static getPrivateNetworkAddresses = (server) => {
    return server?.network_addresses.filter((item) => item.network_range_type === 1 ) || [];
  }
  static getPublicNetworkAddresses = (server) => {
    return server?.network_addresses.filter((item) => item.network_range_type !== 1 ) || [];
  }
}
