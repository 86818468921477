import React from "react";
import { Layout } from "antd";
import Sidebar from "./layout/Sidebar";
import "./Page.less";
import Container from "./layout/Container";

const { Content } = Layout;

const Page = ({ children, breadcrumb, dynamicBreadcrumbs, className }) => {
  return (
    <Content className="page-content">
      <Layout className="site-layout-background">
        <Sidebar />
        <Container className={className} breadcrumb={breadcrumb} dynamicBreadcrumbs={dynamicBreadcrumbs}>{children}</Container>
      </Layout>
    </Content>
  );
};

export default Page;
