import React from "react";
import { Form, Checkbox } from "antd";

export default function AntdCheck({
  label,
  disabled,
  infoKey,
  value,
  name,
  className,
  ...props
}) {
  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      className={className}
      tooltip={infoKey}
    >
      <Checkbox disabled={disabled}>{label}</Checkbox>
    </Form.Item>
  );
}
