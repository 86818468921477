import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Row, Col, Divider } from 'antd';
import GrayedLabel from '../../../components/global/GrayedLabel';
import { withTranslation } from 'react-i18next';
import './styles.less';
import SystemEnvironmentService from '../../../service/systemEnvironment';

const ServerCard = (props) => {
  const { t, server } = props;

  const privateAddresses = SystemEnvironmentService.getPrivateNetworkAddresses(server);
  const publicAddresses = SystemEnvironmentService.getPublicNetworkAddresses(server);

  const renderNetworkAddresses = (networkAddresses) => {
    return (
      <>
      {networkAddresses.map((networkAddresses, index) => {
        return (
          <div key={`${index}`}>
            <Typography.Text key={`${index}`}>{networkAddresses?.address || ''}</Typography.Text>
          </div>
        )
      })}
      </>
    )
  }

  return (
    <Card>
      <Typography.Title level={4}>{server.name}</Typography.Title>

      <Row style={{ marginBottom: 20 }}>
        <Col span={8}>
          <GrayedLabel>{t('type')}</GrayedLabel>
          <Typography.Text>{server.server_type}</Typography.Text>
        </Col>
        <Col span={8}>
          <GrayedLabel>{t('cpuInfo')}</GrayedLabel>
          <Typography.Text>{t('cpuCount', {
            count: server.cpu,
          })}</Typography.Text>
        </Col>
        <Col span={8}>
          <GrayedLabel>{t('memoryInfo')}</GrayedLabel>
          <Typography.Text>{t('memoryCount', {
            count: server.memory,
          })}</Typography.Text>
        </Col>
      </Row>

      <Row>
        <Typography.Title level={5}>{t('disks')}</Typography.Title>
      </Row>

      {Array.isArray(server.mount_points) && server.mount_points?.length > 0 &&
      <>
        <Row>
          <Col span={8}>
            <GrayedLabel>{t('mountpoint')}</GrayedLabel>
          </Col>
          <Col span={8}>
            <GrayedLabel>{t('size')}</GrayedLabel>
          </Col>
          <Col span={8}>
            <GrayedLabel>{t('type')}</GrayedLabel>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }}/>
      </>
      }

      {Array.isArray(server.mount_points) && server.mount_points?.map((diskServer, index) => {
        return (
          <React.Fragment key={`${index}`}>
            <Row>
              <Col span={8}>
                <Typography.Text>{diskServer.mount || ''}</Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text>{diskServer.size || '0GB'}</Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text>{diskServer.type}</Typography.Text>
              </Col>
            </Row>
            <Divider style={{ marginTop: 5, marginBottom: 5 }}/>
          </React.Fragment>
        )
      })}

      <Row style={{ marginTop: 15 }}>
        <Typography.Title level={5}>{t('network')}</Typography.Title>
      </Row>

      <Row>
        <Col span={12}>
          {privateAddresses.length > 0 &&
          <>
            <GrayedLabel>{t('privateIp')}</GrayedLabel>
            {renderNetworkAddresses(privateAddresses)}
          </>
          }
        </Col>
        <Col span={12}>
          {publicAddresses.length > 0 &&
          <>
            <GrayedLabel>{t('publicIp')}</GrayedLabel>
            {renderNetworkAddresses(publicAddresses)}
          </>
          }
        </Col>
      </Row>

    </Card>
  )
}

ServerCard.propTypes = {
  server: PropTypes.object,
  t: PropTypes.func,
}

export default withTranslation('systemEnvironment')(ServerCard);