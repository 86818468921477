export default {
  settings_company_profile: [
    {
      name: "settings",
      path: "",
      breadcrumbName: "navigation:settings"
    },
    {
      name: "settings_company_profile",
      breadcrumbName: "navigation:customerEdit",
      path: "/settings/company_profile"
    }
  ],
  settings_users: [
    {
      name: "settings",
      path: "",
      breadcrumbName: "navigation:settings"
    },
    {
      name: "settings_users",
      breadcrumbName: "navigation:users",
      path: "/settings/users"
    }
  ],
  settings_user_profile: [
    {
      name: "settings",
      path: "",
      breadcrumbName: "navigation:settings"
    },
    {
      name: "settings_users",
      breadcrumbName: "navigation:users",
      path: "/settings/users"
    },
    {
      name: "settings_user_profile",
      breadcrumbName: "navigation:userProfile",
      path: "/settings/users/:id"
    }
  ],
  my_profile: [
    {
      name: "my-profile",
      breadcrumbName: "navigation:my_profile",
      path: "/my-profile"
    }
  ],
  settings_service_accounts: [
    {
      name: "settings",
      path: "",
      breadcrumbName: "navigation:settings"
    },
    {
      name: "service_accounts",
      breadcrumbName: "navigation:service-accounts",
      path: "/settings/service_accounts"
    }
  ],
  settings_service_account_edit: [
    {
      name: "settings",
      path: "",
      breadcrumbName: "navigation:settings"
    },
    {
      name: "service_accounts",
      breadcrumbName: "navigation:service-accounts",
      path: "/settings/service_accounts"
    },
  ],
  system_environment: [
    {
      name: "system_environment",
      path: "",
      breadcrumbName: "navigation:projects"
    },
  ],
  server_groups: [
    {
      name: "server_groups",
      path: "/environment",
      breadcrumbName: "navigation:projects"
    },
  ],
  locations: [
    {
      name: "locations",
      path: "",
      breadcrumbName: "navigation:locations"
    },
  ],
  location: [
    {
      name: "location",
      path: "/pools",
      breadcrumbName: "navigation:locations"
    },
  ],
  tickets: [
    {
      name: "tickets",
      path: "",
      breadcrumbName: "navigation:support"
    }
  ],
  ticket: [
    {
      name: "tickets",
      path: "/tickets",
      breadcrumbName: "navigation:support"
    },
  ]
};
