import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Row, Col, Divider } from 'antd';
import StatusLabel, { Status } from '../../components/shared/StatusLabel';
import { withTranslation } from 'react-i18next';
import './styles.less';
import SupportOpenTickets from './SupportOpenTickets';

const ProjectCard = (props) => {
  const { cluster } = props;

  const goodCount = cluster?.checks?.checks?.Good || 0;
  const warningCount = cluster?.checks?.checks?.Warning || 0;
  const errorCount = cluster?.checks?.checks?.Error || 0;
  const totalServersCount = goodCount + warningCount + errorCount;
  const ticketsCount = cluster?.checks?.checks?.Tickets?.length || 0;

  const renderServersStat = (status, current, total) => {
    return (
      <Col span={8}>
        {current > 0 &&
        <StatusLabel status={status} current={current} total={total}/>
        }
      </Col>
    )
  }

  return (
    <Card className="project-card" onClick={() => props.onClick(cluster)}>
      <Typography.Title level={5}>{cluster.name}</Typography.Title>

      <Row>
        {goodCount > 0 &&
        renderServersStat(Status.SUCCESS, goodCount, totalServersCount)
        }
        {warningCount > 0 &&
        renderServersStat(Status.WARNING, warningCount, totalServersCount)
        }
        {errorCount > 0 &&
        renderServersStat(Status.ERROR, errorCount, totalServersCount)
        }
      </Row>

      <Divider className="divider"/>

      <SupportOpenTickets ticketsCount={ticketsCount} onTicketsPress={props.onTicketsPress}/>
    </Card>
  )
}

ProjectCard.propTypes = {
  t: PropTypes.func,
  cluster: PropTypes.object,
  onTicketsPress: PropTypes.func,
  onClick: PropTypes.func,
};

export default withTranslation('systemEnvironment')(ProjectCard);
