import * as types from '../types';

const moduleReducers = {
  userAccessServerGroup: setUserAccessServerGroup,
  userAccessCluster: setUserAccessCluster, 
};

function setUserAccessServerGroup(state = [], action) {
  switch (action.type) {
  case types.SET_USER_ACCESS_SERVER_GROUP:
    return action.payload;
  default:
    return state;
  }
}
function setUserAccessCluster(state = [], action) {
  switch (action.type) {
  case types.SET_USER_ACCESS_CLUSTER:
    return action.payload;
  default:
    return state;
  }
}


export default moduleReducers;
