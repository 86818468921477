import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Row, Col } from 'antd';
import ServersStatus from '../../components/shared/ServersStatus';
import GrayedLabel from '../../components/global/GrayedLabel';
import SupportOpenTickets from './SupportOpenTickets';
import { withTranslation } from 'react-i18next';
import history from '../../history';
import './styles.less';

const ServerGroupCard = (props) => {
  const { serverGroup, serverGroupId, t } = props;

  const goodCurrent = serverGroup?.checks?.Good || 0;
  const warningCurrent = serverGroup?.checks?.Warning || 0;
  const errorCurrent = serverGroup?.checks?.Error || 0;
  const total = goodCurrent + warningCurrent + errorCurrent;
  const ticketsCount = serverGroup?.checks?.Tickets?.length || 0;

  const onTicketsPress = () => {
    history.push('/tickets');
  }

  return (
    <Card className="server-group-card" onClick={() => props.onClick(serverGroupId)}>
      <Row>
        <Typography.Title level={5}>{serverGroup?.name}</Typography.Title>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={8} className="data-column border-right">
          <ServersStatus
            goodCurrent={goodCurrent}
            warningCurrent={warningCurrent}
            errorCurrent={errorCurrent}
            total={total}
          />
        </Col>
        <Col xs={24} sm={24} md={8} className="data-column border-right left-padding">
          <GrayedLabel>{t('managedServices')}</GrayedLabel>
          <Typography.Text>{serverGroup?.services?.join(', ')}</Typography.Text>
        </Col>
        <Col xs={24} sm={24} md={8} className="data-column left-padding">
          <SupportOpenTickets ticketsCount={ticketsCount} onTicketsPress={onTicketsPress}/>
        </Col>
      </Row>
    </Card>
  )
}

ServerGroupCard.propTypes = {
  serverGroup: PropTypes.object,
  onClick: PropTypes.func,
  serverGroupId: PropTypes.string,
  t: PropTypes.func,
}

export default withTranslation('systemEnvironment')(ServerGroupCard);