import React, { useEffect } from 'react';
import Page from "../../components/Page";
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchServerGroup, fetchPool } from '../../store/actions/systemEnvironment';
import ServerGroupInfo from '../../components/shared/ServerGroupInfo';
import { Button, Row, PageHeader } from 'antd';

const Location = (props) => {

  const { t, pool, serverGroup } = props;

  const renderHeaderComponents = () => {
    return (
      <Row key="header-components">
        {/* <Button onClick={() => {}}>{t('contactSupport')}</Button> */}
      </Row>
    );
  }

  const dynamicBreadcrumbs = (pool && serverGroup) ? [
    {
      name: "pool-name",
      breadcrumbName: pool.name,
      path: "",
    },
    {
      name: "servergroup-name",
      breadcrumbName: serverGroup.name,
      path: "",
    }
  ] : []

  useEffect(() => {
    if (props.match.params?.serverGroupId) {
      props.fetchServerGroup(props.match.params.serverGroupId);
    }
  }, [props.match.params?.serverGroupId]);

  useEffect(() => {
    props.fetchPool(props.match.params?.poolId);
  }, [props.match.params?.poolId]);

  return (
    <Page
      breadcrumb="location"
      dynamicBreadcrumbs={dynamicBreadcrumbs}
    >
      <PageHeader
        className="site-page-header"
        title={serverGroup?.name}
        onBack={() => window.history.back()}
        extra={[renderHeaderComponents()]}
      />

      <ServerGroupInfo pool={pool} serverGroup={serverGroup} />
    </Page>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchServerGroup,
    fetchPool,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    pool: state.pool,
    serverGroup: state.serverGroup,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('systemEnvironment')(Location));
