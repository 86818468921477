import PasswordValidator from 'password-validator';
import i18n from '../i18n';

export const passwordValidationRule = () => ({
    message: i18n.t('users:passwordInvalid'),
    validator: (_, value) => {
        const schema = new PasswordValidator();
        schema
            .is().min(8)
            .is().max(30)
            .has().uppercase()
            .has().lowercase()
            .has().digits(1)
            .has().symbols(1);

        if (schema.validate(value)) {
            return Promise.resolve();
        } else {
            return Promise.reject(i18n.t('users:passwordInvalid'));
        }
    }
})

export const requiredRule = () => ({
    required: true,
    message: i18n.t("form:fieldWarning"),
})