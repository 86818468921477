const routes = [
  {
    name: "locations",
    path: "/pools"
  },
  {
    name: "system-overview",
    path: "/environment",
  },
  {
    name: "settings_users",
    path: "/settings/users",
    openSubMenu: "company-settings"
  },
  {
    name: "settings_service_accounts",
    path: "/settings/service_accounts",
    openSubMenu: "company-settings"
  },
  {
    name: "settings_company_profile",
    path: "/settings/company_profile",
    openSubMenu: "company-settings"
  },
  {
    name: "my-profile",
    path: "/my-profile"
  },
  {
    name: "tickets",
    path: "/tickets"
  }
];

export default routes;
