import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Typography } from "antd";
import AntdInput from "../../../components/form/AntdInput";
import { saveUser, sendVerificationMail } from "../../../store/actions/user";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import "./styles.less";

const { Title, Text } = Typography;

const LoginInfoForm = (props) => {
  const { t, user, isUpdateGranted } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isFormUpdated, setIsFormUpdated] = useState(false);

  useEffect(() => {
    if (user && user.email_verification.length > 0) {
      const [{ email }] = user.email_verification;
      form.setFieldsValue({ email });
      setIsFormUpdated(false);
    }
  }, [user, form]);

  const handleSubmit = (values) => {
    sendInvite();
    dispatch(saveUser(values));
    setIsFormUpdated(false);
  };

  const onValuesChange = (changedValues) => {
    const { user } = props;

    let isUpdated = false;

    Object.keys(changedValues).forEach((propertyName) => {      
      if (user[propertyName] !== changedValues[propertyName]) {
        isUpdated = true;
        return;
      }
    });

    setIsFormUpdated(isUpdated);
  };

  const sendInvite = () => {
    const { user } = props;
    const email = form.getFieldValue("email");
    const userData = { ...user, email };

    dispatch(sendVerificationMail(userData));
  };

  const cancelInvite = () => {
    const { user } = props;
    form.setFieldsValue({ email: user.email });

    setIsFormUpdated(false);
  };

  const { email_verification = [] } = user || {};

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={user}
      className="my-profile"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      validateMessages={{ required: t("form:fieldWarning" )}}
    >
      <Title className="sub-header" level={5}>
        {t("myProfile:loginInfo")}
      </Title>

      <AntdInput hidden name="id" />
      <AntdInput hidden name="username" />

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <AntdInput
            name="email"
            rules={[{ type: "email" }]}
            placeholder={t("labelEmail")}
            label={t("labelEmail")}
            required
            disabled={!isUpdateGranted}
            help={
              isFormUpdated && <Text type="warning">{t("emailWarning")}</Text>
            }
          />
        </Col>
      </Row>
      {isUpdateGranted &&
      <Row>
        <Col xs={24} md={12}>
          <Row gutter={8} justify="end">
            {email_verification.length === 0 || isFormUpdated ? (
              <>
                <Col>
                  <Button
                    color="primary"
                    className="right"
                    htmlType="button"
                    disabled={!isFormUpdated}
                    onClick={cancelInvite}
                  >
                    {t("buttons:cancelShort")}
                  </Button>
                </Col>

                <Col>
                  <Button
                    type="primary"
                    className="right"
                    htmlType="submit"
                    disabled={!isFormUpdated}
                  >
                    {t("buttons:sendVerification")}
                  </Button>
                </Col>
              </>
            ) : (
              <Col>
                <Button
                  htmlType="submit"
                  disabled={!isFormUpdated && email_verification.length === 0}
                >
                  {t("buttons:resendVerification")}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      }
    </Form>
  )
}

export default withTranslation("users")(LoginInfoForm);
