import React, { useState } from "react";
import { Form, Row, Col, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import AntdInput from "../../../components/form/AntdInput";
import AntdSelect from "../../../components/form/AntdSelect";
import AntdTextArea from "../../../components/form/AntdTextArea";
import { saveUser } from "../../../store/actions/user";
import DeleteBtn from "../DeleteBtn";
import { useDispatch } from "react-redux";
import "./styles.less";

const { Title } = Typography;

const SshKeysForm = (props) => {
  const { t, user, sshEncryptionTypes, isUpdateGranted } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const handleSubmit = (values) => {
    const formattedValues = convertUserSshKeyEncAlgoritmToNumbers(values);
    dispatch(saveUser(formattedValues));
  }

  const onResetClick = () => {
    form.setFieldsValue(convertUserSshKeyEncAlgoritmToStrings(user));
    setIsFormUpdated(false);
  }

  const getEncryptionAlgoritmOptions = () => {
    const data = sshEncryptionTypes?.data || {};
    const options = Object.keys(data).map((key) => ({
      label: data[key] || "",
      value: key,
    }));
    return options
  }

  const convertUserSshKeyEncAlgoritmToStrings = (userToUpdate) => {
    const result = structuredClone(userToUpdate);
    result?.ssh_keys?.forEach((sshKey) => {
      sshKey.public_key_encryption_algorithm = `${sshKey.public_key_encryption_algorithm}`;
    })
    return result;
  }

  const convertUserSshKeyEncAlgoritmToNumbers = (userToUpdate) => {
    const result = structuredClone(userToUpdate);
    result?.ssh_keys?.forEach((sshKey) => {
      sshKey.public_key_encryption_algorithm = Number(sshKey.public_key_encryption_algorithm);
    })
    return result;
  }

  const onValuesChange = (changedValues, allValues) => {
    let isUpdated = false;

    const formattedUser = convertUserSshKeyEncAlgoritmToStrings(user);

    if (allValues.ssh_keys.length !== formattedUser.ssh_keys.length) {
      isUpdated = true
    } else {
      changedValues.ssh_keys.forEach((sshKey, index) => {
        const existingKey = formattedUser.ssh_keys[index];

        Object.keys(sshKey).forEach((propertyName) => {
          if (sshKey[propertyName] !== existingKey[propertyName]) {
            isUpdated = true;
            return;
          }
        })
      })
    }

    setIsFormUpdated(isUpdated);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={convertUserSshKeyEncAlgoritmToStrings(user)}
      className="my-profile"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      validateMessages={{ required: t("form:fieldWarning") }}
    >
      <Title className="sub-header" level={5}>
        {t("myProfile:sshPublicKeys")}
      </Title>

      <AntdInput hidden name="id" />
      <AntdInput hidden name="username" />

      <Form.List name="ssh_keys">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <React.Fragment key={field.key}>
                <AntdInput hidden name={[field.name, "id"]} />
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Row>
                      <Col span={24}>
                        <AntdInput
                          {...field}
                          name={[field.name, "name"]}
                          type="text"
                          placeholder={t("labelSshPublicKeyName")}
                          label={t("labelSshPublicKeyName")}
                          required
                          disabled={!isUpdateGranted}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <AntdSelect
                          {...field}
                          name={[field.name, "public_key_encryption_algorithm"]}
                          options={getEncryptionAlgoritmOptions()}
                          placeholder={t("labelSshPublicKeyEncryption")}
                          label={t("labelSshPublicKeyEncryption")}
                          required
                          disabled={!isUpdateGranted}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row gutter={16} justify="space-around" align="middle">
                      <Col span={22}>
                        <AntdTextArea
                          {...field}
                          name={[field.name, "public_key"]}
                          type="textarea"
                          placeholder={t("labelSshPublicKey")}
                          label={t("labelSshPublicKey")}
                          required
                          disabled={!isUpdateGranted}
                        />
                      </Col>
                      <Col span={2}>
                        <DeleteBtn
                          onConfirm={() => remove(field.name)}
                          confirmTitle={t("confirmDeletePublicKey")}
                          okText={t("buttons:confirm")}
                          cancelText={t("buttons:cancel")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
            {isUpdateGranted &&
            <Row>
              <Col>
                <Form.Item>
                  <Button
                    type="link"
                    onClick={() => add({ id: null })}
                    block
                    icon={<PlusOutlined />}
                    align="left"
                  >
                    {t("buttons:addPublicKey")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            }
          </>
        )}
      </Form.List>
      {isUpdateGranted &&
      <Row gutter={8} justify="end">
        <Col>
          <Button
            color="primary"
            className="right"
            htmlType="button"
            disabled={!isFormUpdated || !isUpdateGranted}
            onClick={onResetClick}
          >
            {t("buttons:cancelShort")}
          </Button>
        </Col>
        <Col>
          <Button
            color="primary"
            className="right"
            htmlType="submit"
            disabled={!isFormUpdated || !isUpdateGranted}
          >
            {t("buttons:save")}
          </Button>
        </Col>
      </Row>
      }
    </Form>
  )
}

export default withTranslation("users")(SshKeysForm);
