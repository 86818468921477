import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form as AntdForm, PageHeader, Row, Col, Button } from "antd";
import { bindActionCreators, compose } from "redux";
import countries from "i18n-iso-countries";
import AntdInput from "../../components/form/AntdInput";
import AntdSelect from "../../components/form/AntdSelect";
import PermissionService from "../../service/permission";
import { PERMISSIONS } from "../../shared/constants/permissions";
import { saveCustomer, fetchCustomer } from "../../store/actions/customer";
import { setPageheader } from "../../store/actions/navigation";
import sanitizeEmptyValues from "../../components/form/sanitizeEmptyValues";
import Page from "../../components/Page";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

class CompanyProfile extends Component {
  static propTypes = {
    currentCustomer: PropTypes.object,
    authenticatedUser: PropTypes.object,
    saveCustomer: PropTypes.func,
    t: PropTypes.func
  };

  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      isFormUpdated: false
    };

    this.setIsFormUpdated = this.setIsFormUpdated.bind(this);
  }

  setIsFormUpdated(changedFields, allFields) {
    let isUpdated = false;
    const { currentCustomer } = this.props;

    allFields.forEach((field) => {
      const name = field.name[0];

      if (currentCustomer[name] !== field.value) {
        isUpdated = true;
        return;
      }
    });

    this.setState({ isFormUpdated: isUpdated });
  }

  componentDidMount() {
    const customerId = this.props.currentCustomer?.id;

    if (customerId) {
      this.props.fetchCustomer(customerId);
    }
  }

  componentDidUpdate(prevProps) {
    const { currentCustomer, fetchCustomer } = this.props;

    const prevCustomerId = prevProps.currentCustomer?.id;
    const customerId = currentCustomer?.id;

    if (customerId && customerId !== prevCustomerId) {
      fetchCustomer(customerId);
      this.formRef.current.setFieldsValue(currentCustomer);
    }
  }

  onSaveCustomer(values) {
    const errors = this.validateEditCustomer(values);

    const { currentCustomer, saveCustomer } = this.props;

    if (errors.length > 0) {
      this.formRef.current.setFields(errors);
      return;
    }

    const { id, state } = currentCustomer;

    const customer = {
      ...sanitizeEmptyValues(currentCustomer, values),
      id,
      state
    };

    saveCustomer(customer);
  }

  validateEditCustomer(values) {
    const errors = [];
    const { t } = this.props;
    const warning = t("form:fieldWarning");
    if (!values.name) {
      errors.push({
        errors: [warning],
        name: "name"
      });
    }
    if (!values.street) {
      errors.push({
        errors: [warning],
        name: "street"
      });
    }
    if (!values.postal_code) {
      errors.push({
        errors: [warning],
        name: "postal_code"
      });
    }
    if (!values.city) {
      errors.push({
        errors: [warning],
        name: "city"
      });
    }
    if (!values.country_code) {
      errors.push({
        errors: [warning],
        name: "country_code"
      });
    }

    return errors;
  }

  onResetForm = () => {
    this.formRef.current?.resetFields();
  }

  render() {
    const { currentCustomer, t } = this.props;
    if (!currentCustomer) return <React.Fragment />;

    const codes = {
      "": null,
      ...countries.getNames("en", { select: "official" })
    };
    const isUpdateGranted = PermissionService.isGranted(
      currentCustomer.permissions,
      PERMISSIONS.UPDATE
    );

    const { isFormUpdated } = this.state;

    return (
      <Page breadcrumb="settings_company_profile">
        <PageHeader
          className="site-page-header"
          title={t("myCompany")}
          subTitle={t("myCompanySubtitle")}
        />
        <AntdForm
          layout="vertical"
          ref={this.formRef}
          initialValues={currentCustomer}
          onFinish={this.onSaveCustomer.bind(this)}
          onFieldsChange={this.setIsFormUpdated}
        >
          <AntdInput
            name="name"
            type="text"
            placeholder={t("name")}
            label={t("name")}
            required
            disabled
          />

          <AntdInput
            name="contact_person"
            type="text"
            placeholder={t("contact_person")}
            label={t("contact_person")}
            disabled={!isUpdateGranted}
          />
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <AntdInput
                name="street"
                type="text"
                required
                placeholder={t("street")}
                label={t("street")}
                disabled={!isUpdateGranted}
              />
            </Col>
            <Col xs={24} md={12}>
              <AntdInput
                name="number"
                type="text"
                placeholder={t("number")}
                label={t("number")}
                disabled={!isUpdateGranted}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={8}>
              <AntdInput
                name="postal_code"
                type="text"
                required
                placeholder={t("postal_code")}
                label={t("postal_code")}
                disabled={!isUpdateGranted}
              />
            </Col>
            <Col xs={24} md={8}>
              <AntdInput
                name="city"
                type="text"
                required
                placeholder={t("city")}
                label={t("city")}
                disabled={!isUpdateGranted}
              />
            </Col>
            <Col xs={24} md={8}>
              <AntdSelect
                name="country_code"
                type="select"
                required
                placeholder={t("country_code")}
                label={t("country_code")}
                options={Object.keys(codes).map((key) => ({
                  value: key,
                  label: codes[key]
                }))}
                disabled={!isUpdateGranted}
              />
            </Col>
          </Row>
          <AntdInput
            name="email"
            type="text"
            placeholder={t("email")}
            label={t("email")}
            disabled={!isUpdateGranted}
          />
          <AntdInput
            name="phone"
            type="text"
            placeholder={t("phone")}
            label={t("phone")}
            disabled={!isUpdateGranted}
          />
          <Row gutter={8} justify="end">
            <Col>
              <Button
                color="primary"
                className="right"
                htmlType="button"
                disabled={!isFormUpdated || !isUpdateGranted}
                onClick={this.onResetForm}
              >
                {t("buttons:cancelShort")}
              </Button>
            </Col>
            <Col>
              <Button
                color="primary"
                className="right"
                htmlType="submit"
                disabled={!isFormUpdated || !isUpdateGranted}
              >
                {t("buttons:save")}
              </Button>
            </Col>
          </Row>
        </AntdForm>
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveCustomer,
      fetchCustomer,
      setPageheader
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    authenticatedUser: state.authenticatedUser,
    currentCustomer: state.currentCustomer
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation("customers")(CompanyProfile));
