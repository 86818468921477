import React from "react";
import { Layout, Spin } from "antd";
import { Route, Router } from "react-router-dom";
import history from "./history";

import "./App.less";
import Page from "./components/Page";
import Data from "./containers/Data";
import ProtectedRoute from "./components/global/ProtectedRoute";
import Notification from "./containers/Notification";
import CompanyProfile from "./pages/Settings/CompanyProfile";
import Users from "./pages/Settings/Users";
import ServiceAccounts from "./pages/Settings/ServiceAccounts";
import ServiceAccountEdit from "./pages/Settings/ServiceAccountEdit";
import MyProfile from "./pages/MyProfile";
import VerifyEmail from "./pages/VerifyEmail";
import UserEdit from "./pages/Settings/UserEdit";
import SystemEnvironment from "./pages/SystemEnvironment";
import ServerGroups from "./pages/SystemEnvironment/ServerGroups";
import ServerGroup from "./pages/SystemEnvironment/ServerGroup";
import Locations from "./pages/Locations";
import Location from "./pages/Location";
import Tickets from "./pages/Tickets";
import Ticket from "./pages/Ticket";
import Register from "./pages/Register";
import UserInvitation from "./pages/Settings/UserInvitation";
import { useSelector } from "react-redux";

const App = () => {
  const loadingCount = useSelector(state => state.loadingCount);  
  return (
  <Layout>
    {loadingCount>0 && <Spin />}                   
    <Router history={history}>
      <Data />
      <Notification />
      <Route
        exact
        path="/verify_email/:uid"
        component={VerifyEmail}
      />
      <Route 
        exact 
        path="/invitations/register/:id" 
        component={Register} 
      />
      <ProtectedRoute exact path="/" component={Page} />
      <ProtectedRoute
        name="settings_company_profile"
        path="/settings/company_profile"
        component={CompanyProfile}
      />
      <ProtectedRoute
        exact
        name="settings_users"
        path="/settings/users"
        component={Users}
      />
      <ProtectedRoute
        exact
        name="settings_service_accounts"
        path="/settings/service_accounts"
        component={ServiceAccounts}
      />
      <ProtectedRoute
        exact
        name="settings_service_account_edit"
        path="/settings/service_accounts/:id"
        component={ServiceAccountEdit}
      />
      <ProtectedRoute
        name="my-profile"
        path="/my-profile"
        component={MyProfile}
      />
      <ProtectedRoute
        exact
        name="edit-user"
        path="/settings/users/:id"
        component={UserEdit}
      />
      <ProtectedRoute
        exact
        name="system_environment"
        path="/environment"
        component={SystemEnvironment}
      />
      <ProtectedRoute
        exact
        name="server_groups" 
        path="/environment/:clusterId" 
        component={ServerGroups} 
      />
      <ProtectedRoute
        exact
        name="server_group" 
        path="/environment/:clusterId/server_groups/:serverGroupId" 
        component={ServerGroup} 
      />
      <ProtectedRoute 
        exact
        name="locations"
        path="/pools"
        component={Locations}
      />
      <ProtectedRoute 
        exact
        name="location"
        path="/pools/:poolId/server_groups/:serverGroupId"
        component={Location}
      />
      <ProtectedRoute 
        exact
        name="tickets"
        path="/tickets"
        component={Tickets}
      />

      <ProtectedRoute 
        exact
        name="ticket"
        path="/tickets/:ticketId"
        component={Ticket}
      />
      <ProtectedRoute 
        exact
        name="invitation" 
        path="/invitations/:id" 
        component={UserInvitation} 
      />
    </Router>
  </Layout>
)
};

export default App;
