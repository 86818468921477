import React from "react";
import { Table } from "antd";
import "./AntdTable.less";

class AntdTable extends React.Component {
  render() {
    const { columns, rowKey, data } = this.props;
    return (
      <>
        <Table
          {...this.props}
          scroll={{ x: true }}
          rowKey={rowKey}
          columns={columns}
          dataSource={data}
        />
      </>
    );
  }
}

export default AntdTable;
