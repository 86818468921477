export const PERMISSIONS = {
  UPDATE: 'update',
  DELETE: 'destroy',
  CREATE: 'create',
  READ: 'read',
  CREATE_ENVIRONMENT_ACCESS: 'create_environment_access',
  ACCOUNTING: 'accounting',
  CREATE_INVITATION: 'create_invitation',
  CREATE_VHOST: 'create_vhost',
  CREATE_SERVER: 'create_server',
  CREATE_SERVICE: 'create_service',
  READ_LIST_USERS : 'read_list_users',
  SERVICE_SETTINGS : 'service_settings',
  TICKET : 'ticket',
  CREATE_SERVICE_ACCOUNT: 'create_service_account',
  READ_AND_DELETE_USER : 'read_and_delete_user',
};
