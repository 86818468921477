import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import KeycloakService from "../service/KeycloakService";
import i18n from "../shared/i18n";
import { RESTORE_USER } from "../store/types";

export default function Data() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/verify_email/")) {
      return;
    }

    // Invitation for new user. Restore user to set loginin flag to false to fetch invite object
    if (location.pathname.includes("/invitations/register/")) {
      dispatch({ type: RESTORE_USER });
      return;
    }

    if (KeycloakService.isLoggedIn()) {
      dispatch({ type: RESTORE_USER });
    } else {
      let redirectUri = window.location.origin + '/environment';
      // Invitation for already existing user. Redirect to the same page after login to accept/refuse invitation
      if (location.pathname.includes('/invitations/')) {
        redirectUri = window.location.origin + location.pathname;
      } else if (location.pathname.includes('/my-profile')) {
        redirectUri = window.location.origin + location.pathname;
      }
      KeycloakService.doLogin({ redirectUri, locale: i18n.language.substring(0, 2) });
    }
  }, [dispatch, location]);

  return <React.Fragment />;
}
