import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Progress } from 'antd';
import { withTranslation } from 'react-i18next';
import AntdTable from "../../../../components/global/AntdTable";
import ServiceCard from '../ServiceCard';
import SystemEnvironmentService from '../../../../service/systemEnvironment';
import { ServerTableRow } from './ServerTableRow';

const Overview = (props) => {
  const { t, serverGroup } = props;

  const sortByPropertyValue = (a, b, propertyName) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }

    if (a[propertyName] > b[propertyName]) {
      return 1;
    }

    return 0;
  }

  const sortByPrivateIP = (a, b) => {
    const aNetworkAddress = SystemEnvironmentService.getPrivateNetworkAddresses(a);
    const bNetworkAddress = SystemEnvironmentService.getPrivateNetworkAddresses(b);

    return sortByPropertyValue(aNetworkAddress, bNetworkAddress, 'address');
  }

  const sortByPublicIP = (a, b) => {
    const aNetworkAddress = SystemEnvironmentService.getPublicNetworkAddresses(a);
    const bNetworkAddress = SystemEnvironmentService.getPublicNetworkAddresses(b);

    return sortByPropertyValue(aNetworkAddress, bNetworkAddress, 'address');
  }

  const renderProgressBar = (percent) => {
    return (
      <Progress strokeColor="#52c41a" percent={percent}/>
    )
  }

  const renderNetworkAddresses = (networkAddresses) => {
    if (networkAddresses.length === 0) {
      return <></>
    }

    return (
      <>
      {networkAddresses.map((networkAddress, index) => {
        return (
          <div key={`${index}`}>
            <Typography.Text key={`${index}`}>{networkAddress.address}</Typography.Text>
          </div>
        )
      })}
      </>
    )
  }

  const tableColumns = [
    {
      title: t('server'),
      dataIndex: 'name',
      sorter: (a, b) => sortByPropertyValue(a, b, 'name'),
      render: (_, server) => {
        const privateAddresses = SystemEnvironmentService.getPrivateNetworkAddresses(server);
        const publicAddresses = SystemEnvironmentService.getPublicNetworkAddresses(server);
        const rowsAmount = Math.max(privateAddresses.length, publicAddresses.length, 1);
        const rowHeight = 17;

        return (
          <ServerTableRow
            server={server}
            tableWidth={tableWidth}
            rowsAmount={rowsAmount}
            rowHeight={rowHeight}
          />
        )
      }
    },
    {
      title: t('privateIp'),
      render: (_, server) => {
        const networkAddresses = SystemEnvironmentService.getPrivateNetworkAddresses(server);
        return renderNetworkAddresses(networkAddresses);
      },
      width: 200,
      // sorter: sortByPrivateIP,
    },
    {
      title: t('publicIp'),
      render: (_, server) => {
        const networkAddresses = SystemEnvironmentService.getPublicNetworkAddresses(server);
        return renderNetworkAddresses(networkAddresses);
        
      },
      width: 200,
      // sorter: sortByPublicIP,
    },
    // {
    //   title: t('cpu'),
    //   render: (_, server) => {
    //     // TODO: Change to real value
    //     return renderProgressBar(50);
    //   },
    //   sorter: (a, b) => sortByPropertyValue(a, b, 'name'),
    // },
    // {
    //   title: t('memory'),
    //   render: (_, server) => {
    //     // TODO: Change to real value
    //     return renderProgressBar(50);
    //   },
    //   sorter: (a, b) => sortByPropertyValue(a, b, 'name'),
    // },
  ]

  const tableRef = useRef();

  const updateTableWidth = () => {
    setTableWidth(tableRef.current.offsetWidth);
  }

  useEffect(() => {
    updateTableWidth();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateTableWidth);

    return () => {
      window.removeEventListener('resize', updateTableWidth);
    }
  }, [tableRef.current]);

  const [tableWidth, setTableWidth] = useState(0);

  if (!serverGroup) {
    return <></>;
  }

  return (
    <>
      <Typography.Title level={3}>{t('infrastructureLowercase')}</Typography.Title>
      <div ref={tableRef}>
        <AntdTable
          className='server-group-table'
          rowKey={(record) => record.id}
          columns={tableColumns}
          data={serverGroup.servers}
        />
      </div>

      {serverGroup.services?.length > 0 &&
      <Typography.Title level={3}>{t('managedServices')}</Typography.Title>
      }
      <Row gutter={10}>
        {serverGroup.services?.map((service, index) => {
          return (
            <Col key={`${index}`} xs={24} sm={24} md={8} style={{ marginBottom: 10 }}>
              <ServiceCard service={service}/>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

Overview.propTypes = {
  t: PropTypes.func,
  serverGroup: PropTypes.object,
};

export default withTranslation('systemEnvironment')(Overview);