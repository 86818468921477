import ApiService from "./api";

export default class UserService extends ApiService {
  async getCurrentUser() {
    return this.get("/hscrm/current_user/");
  }
  async getUsers(customerId) {
    return this.get(`/hscrm/users/for_customer/${customerId}/`);
  }
  async getInvites(customerId) {
    return this.get(`/hscrm/invitations/for_customer/${customerId}/`);
  }
  async getInvite(inviteId) {
    return this.get(`/hscrm/invitations/${inviteId}/`);
  }
  async updateInvite(invite) {
    return this.put(`/hscrm/invitations/${invite.id}/`, invite);
  }
  async uninviteUser(userId, customerId) {
    return this.post(`/hscrm/uninvite/${userId}/`, { customer: customerId });
  }
  async getRoles() {
    return this.get("/hscrm/roles/");
  }
  async registerUser({ inviteId, data }) {
    return this.post(`/hscrm/invitations/register/${inviteId}/`, data);
  }
  async createInvite(customerId, data) {
    return this.post(`/hscrm/invitations/for_customer/${customerId}/`, data);
  }
  async deleteInvite(inviteId) {
    return this.delete(`/hscrm/invitations/${inviteId}/`);
  }
  async deleteUser(userId) {
    return this.delete(`/hscrm/users/${userId}/`);
  }
  async getUser(userId) {
    return this.get(`/hscrm/users/${userId}/`);
  }
  async updateUser(user) {
    return this.put(`/hscrm/users/${user.id}/`, user);
  }
  async createServiceAccount(customerId, data) {
    return this.post(
      `/hscrm/service_accounts/for_customer/${customerId}/`,
      data
    );
  }
  async updateUserCustomer(userCustomer) {
    return this.patch(`/hscrm/user_customer/${userCustomer.id}/roles/`, {
      roles: userCustomer.roles,
      technical_contact: userCustomer.technical_contact
    });
  }
  async createUser(customerId, user) {
    return this.post(`/hscrm/customers/${customerId}/users/`, user);
  }
  async getSshEncryptionTypes() {
    return this.get("/hscrm/encryption_types/");
  }
  async getUserTypes() {
    return this.get("/hscrm/user_types/");
  }
  async getServiceAccounts(customerId) {
    return this.get(`/hscrm/service_accounts/for_customer/${customerId}/`);
  }
  async sendVerifyEmail(user) {
    return this.post(`/hscrm/users/${user.id}/send_verification_email/`, {
      email: user.email
    });
  }

  async verifyEmail(uid) {
    return this.withoutToken().post(`/hscrm/email_verification/${uid}/`);
  }

  async cancelEmailVerification(uid) {
    return this.delete(`/hscrm/email_verification/${uid}`);
  }

  async fetchTranslations() {
    return this.get("/hstranslation/translations/");
  }
}
