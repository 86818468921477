import {
  FETCH_TICKETS,
  FETCH_TICKET,
  SAVE_TICKET,
  ANSWER_TICKET,
  CLOSE_TICKET,
  FETCH_CUSTOMER_OBJECTS,
  SET_CUSTOMER_OBJECTS,
  FETCH_REQUESTS,
  DOWNLOAD_ATTACHMENT,
} from '../types';

export function fetchTickets(customerId, archived) {
  return {
    type: FETCH_TICKETS,
    payload: {
      customerId,
      archived,
    }
  };
}

export function fetchTicket(ticketId) {
  return {
    type: FETCH_TICKET,
    payload: ticketId,
  };
}

export function saveTicket({ customerId, ticket, attachments }) {
  return {
    type: SAVE_TICKET,
    payload: {
      customerId,
      ticket,
      attachments,
    },
  };
}

export function answerTicket({ ticketId, answer, attachments, onSuccess }) {
  return {
    type: ANSWER_TICKET,
    payload: {
      ticketId,
      answer,
      attachments,
      onSuccess,
    },
  };
}

export function closeTicket(ticketId) {
  return {
    type: CLOSE_TICKET,
    payload: ticketId,
  };
}

export function fetchCustomerObjects(customerId, relation) {
  return {
    type: FETCH_CUSTOMER_OBJECTS,
    payload: { customerId, relation },
  };
}

export function resetCustomerObjects() {
  return {
    type: SET_CUSTOMER_OBJECTS,
    payload: [],
  };
}

export function fetchRequests() {
  return {
    type: FETCH_REQUESTS,
    payload: {},
  };
}

export function downloadAttachment({ ticketId, articleId, attachmentId, filename }) {
  return {
    type: DOWNLOAD_ATTACHMENT,
    payload: { ticketId, articleId, attachmentId, filename },
  };
}

