import React from 'react';
import { Typography } from 'antd';
import './GrayedLabel.less';

const GrayedLabel = (props) => {
  return (
    <div>
      <Typography.Text {...props} className={`grayed-label ${props.className || ''}`}>{props.children}</Typography.Text>
    </div>
  )
}

export default GrayedLabel;