import * as types from '../types';

const moduleReducers = {
  authenticatedUser: setAuthenticatedUser,
  token: setToken,
  actualToken: setActualToken,
  loggingIn: setLoggingIn,
  redirectAfterLogin: setRedirectAfterLogin
};


function setAuthenticatedUser(state = null, action) {
  switch (action.type) {
  case types.SET_AUTHENTICATED_USER:
    return action.payload;
  default:
    return state;
  }
}

function setToken(state = null, action) {
  switch (action.type) {
  case types.SET_TOKEN:
    return action.payload;
  default:
    return state;
  }
}

function setActualToken(state = null, action) {
  switch (action.type) {
  case types.SET_ACTUAL_TOKEN:
    return action.payload;
  default:
    return state;
  }
}

function setLoggingIn(state = true, action) {
  switch (action.type) {
  case types.SET_LOGING_IN:
    return action.payload;
  default:
    return state;
  }
}
function setRedirectAfterLogin(state = null, action) {
  switch (action.type) {
  case types.SET_REDIRECT_AFTER_LOGIN:
    return action.payload;
  default:
    return state;
  }
}

export default moduleReducers;
