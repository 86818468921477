import React, { useState } from "react";
import { Form, Button, Typography } from "antd";
import AntdInput from "../../components/form/AntdInput";
import AntdSelect from "../../components/form/AntdSelect";
import { useDispatch } from "react-redux";
import { createInvite } from "../../store/actions/user";
import AntdCheck from "../../components/form/AntdCheck";
import AntdModal from "../../components/global/AntdModal";

const InviteUser = ({ t, isModalOpen, roles, closeModal }) => {
  const dispatch = useDispatch();
  const [infoText, setInfoText] = useState(t("info:userRole.Default"));
  const [infoType, setInfoType] = useState("secondary");

  const [form] = Form.useForm();
  const { Text } = Typography;

  const onSubmitInvite = (values) => {
    dispatch(createInvite(values));
    closeModal();
    form.setFieldsValue({ email: "", roles: "" });
    setInfoText(t("info:userRole.Default"));
    setInfoType("secondary");
  };

  const userRoles = roles?.data || {};

  const handleChange = (value) => {
    if (value) {
      let role = userRoles[value];
      if (role.includes("SSH")) {
        role = "SSH";
      }
      setInfoText(t(`info:userRole.${role}`));
      setInfoType("warning");
    } else {
      setInfoText(t("info:userRole.Default"));
      setInfoType("secondary");
    }
  };

  return (
    <AntdModal
      header={t("buttons:inviteUser")}
      isOpen={isModalOpen}
      onClose={() => closeModal()}
      footer={[
        <Button key="cancel" onClick={() => closeModal()}>
          {t("buttons:cancel")}
        </Button>,
        <Button type="primary" key="submit" onClick={() => form.submit()}>
          {t("buttons:submit")}
        </Button>
      ]}
    >
      <Form
        form={form}
        onSubmit={onSubmitInvite}
        layout="vertical"
        onFinish={onSubmitInvite}
      >
        <AntdInput
          name="email"
          type="email"
          required
          placeholder={t("users:labelEmail")}
          label={t("users:labelEmail")}
        />
        <AntdSelect
          name="roles"
          type="select"
          required
          label={t("users:labelRole")}
          placeholder={t("users:labelSelectRole")}
          options={Object.keys(userRoles).map((key) => ({
            label: userRoles[key],
            value: key
          }))}
          onChange={handleChange}
          className="mb-0"
        />
        <Text type={infoType}>{infoText}</Text>
        <AntdCheck
          name="technical_contact"
          label={t("users:labelTechnicalContact")}
          className="mb-0 mt-3"
        />
      </Form>
    </AntdModal>
  );
};

export default InviteUser;
