import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, } from 'antd';
import ServersStatus from '../../components/shared/ServersStatus';
import './styles.less';

const PrivateLocationCard = (props) => {
  const { pool, height, onClick } = props;

  const onCardClick = () => {
    onClick(pool);
  }

  const goodCurrent = pool?.checks?.checks?.Good || 0;
  const warningCurrent = pool?.checks?.checks?.Warning || 0;
  const errorCurrent = pool?.checks?.checks?.Error || 0;
  const total = goodCurrent + warningCurrent + errorCurrent;
  const isClickable = pool?.servergroup !== null;

  return (
    <Card style={{ height }} className={`${isClickable && 'clickable'}`} onClick={isClickable ? onCardClick : () => {}}>
      <Typography.Title ellipsis={true} level={5}>{pool?.name || ''}</Typography.Title>
      <ServersStatus goodCurrent={goodCurrent} warningCurrent={warningCurrent} errorCurrent={errorCurrent} total={total}/>
    </Card>
  )
}

PrivateLocationCard.propTypes = {
  pool: PropTypes.object,
  height: PropTypes.number,
  onClick: PropTypes.func,
}

PrivateLocationCard.defaultProps = {
  pool: null,
  height: 0,
  onClick: () => {},
}



export default PrivateLocationCard;