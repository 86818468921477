import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Page from "../../components/Page";
import { Typography, Button, Row, Col, Tooltip, PageHeader, Tabs, } from 'antd';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchPool, fetchClusters, fetchCluster,
} from '../../store/actions/systemEnvironment';
import { InfoCircleOutlined,  } from "@ant-design/icons";
import ServerGroupCard from './ServerGroupCard';
import GrayedLabel from '../../components/global/GrayedLabel';
import { saveCustomerReference, fetchPricingForCluster } from '../../store/actions/billing';
import InvoiceTable from '../../components/shared/InvoiceTable';
import PermissionService from '../../service/permission';
import { PERMISSIONS } from '../../shared/constants';
import InvoiceInfo from '../../components/shared/ServerGroupInfo/Content/InvoiceInfo';

const ServerGroups = (props) => {
  const { t, cluster, pool, pricingCluster } = props;

  const renderHeaderComponents = () => {
    return (
      <Row key="header-components">
        {/* <Button onClick={() => {}}>{t('contactSupport')}</Button> */}
      </Row>
    );
  }

  const onServerGroupClick = (serverGroupId) => {
    const clusterId = props.match.params?.clusterId;
    if (clusterId) {
      props.history.push(`/environment/${clusterId}/server_groups/${serverGroupId}`);
    }
  }

  const onLocationClick = () => {
    if (pool?.id && pool.servergroup) {
      props.history.push(`/pools/${pool.id}/server_groups/${pool.servergroup}`);
    }
  }

  useEffect(() => {
    const clusterId = props.match.params?.clusterId;
    if (clusterId) {
      props.fetchCluster(clusterId);
    }
  }, []);

  useEffect(() => {
    const clusterId = props.match.params?.clusterId;

    if (clusterId && props.currentCustomer) {
      props.fetchPricingForCluster(clusterId);
    }
  }, [props.currentCustomer]);

  useEffect(() => {
    if (props.cluster) {
      props.fetchPool(props.cluster.pool);
    }
  }, [props.cluster]);

  const accountingPermissionsGranted = () => {
    return PermissionService.isGranted(props.currentCustomer?.permissions, PERMISSIONS.ACCOUNTING);
  }

  if (!cluster) {
    return <></>;
  }

  return (
    <Page 
      breadcrumb="server_groups" 
      dynamicBreadcrumbs={cluster && [{
        breadcrumbName: cluster.name,
        path: "",
      }]}
    >
      <PageHeader
        className="site-page-header"
        title={cluster?.name}
        onBack={() => window.history.back()}
        extra={[renderHeaderComponents()]}
      />

      <Tabs defaultActiveKey="0">
        <Tabs.TabPane tab={t('overview')} key="0">
          <Row justify="space-between" style={{ marginBottom: 20 }}>
            <Col>
              <Row justify="middle">
                <Typography.Title level={3} style={{ marginRight: 10, marginBottom: 0 }}>{t('serverGroups')}</Typography.Title>
                <Tooltip
                  trigger="click"
                  color="black"
                  title={t('serverGroupsHint')}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </Row>
            </Col>
            <Col>
              <Row justify="middle">
                <GrayedLabel style={{ marginRight: 5 }}>{t('location')}</GrayedLabel>
                {pool?.servergroup
                ?
                <Button className="no-padding" type="link" onClick={onLocationClick}>{pool?.name} {pool?.identifier}</Button>
                :
                <Typography.Text>{pool?.name} {pool?.identifier}</Typography.Text>
                }
              </Row>
            </Col>
          </Row>

          {cluster.Servergroups && Object.keys(cluster.Servergroups).map((serverGroupId, index) => {
            return (
              <div key={serverGroupId} style={{ padding: 1, marginBottom: 20 }}>
                <ServerGroupCard serverGroupId={serverGroupId} serverGroup={cluster.Servergroups[serverGroupId]} onClick={onServerGroupClick}/>
              </div>
            )
          })}

        </Tabs.TabPane>
        {accountingPermissionsGranted() &&
        <Tabs.TabPane tab={t('invoiceInfo')} key="1">
          <InvoiceInfo data={pricingCluster} />
        </Tabs.TabPane>
        }
      </Tabs>
    </Page>
  )
}

ServerGroups.propTypes = {
  t: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPool,
    saveCustomerReference,
    fetchClusters,
    fetchCluster,
    fetchPricingForCluster,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    serverGroups: state.serverGroups,
    cluster: state.cluster,
    pool: state.pool,
    pricingCluster: state.pricingCluster,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('systemEnvironment')(ServerGroups));