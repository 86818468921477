import { takeEvery, put, call, select } from "redux-saga/effects";
import * as types from "../types";
import BillingService from "../../service/billing";
import i18n from "../../shared/i18n";
import {
  errorNotification,
  successNotification
} from "../actions/notification";
import { waitFor } from "./general";
import PermissionService from "../../service/permission";
import { PERMISSIONS } from "../../shared/constants/permissions";

function* fetchPricingForServerGroup(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    if (action.payload != undefined) {
      const { token, currentCustomer } = yield select((state) => state);
      if (
        PermissionService.isGranted(
          currentCustomer.permissions,
          PERMISSIONS.ACCOUNTING
        )
      ) {
        yield put({ type: types.INC_LOADING });
        const billingService = yield call(() => new BillingService(token));
        const pricings = yield call(
          [billingService, billingService.getPricingForServerGroup],
          action.payload
        );
        yield put({
          type: types.SET_PRICING_SERVER_GROUP,
          payload: pricings
        });
        yield put({ type: types.DEC_LOADING });
      }
    }
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("billing:fetchPricingError", { error: error.message })
      })
    );
    console.error("fetchCustomers error", error);
  }
}

function* fetchPricingForCluster(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token, currentCustomer } = yield select((state) => state);
    if (
      PermissionService.isGranted(
        currentCustomer.permissions,
        PERMISSIONS.ACCOUNTING
      )
    ) {
      yield put({ type: types.INC_LOADING });
      const billingService = yield call(() => new BillingService(token));
      const pricings = yield call(
        [billingService, billingService.getPricingForCluster],
        action.payload
      );
      yield put({
        type: types.SET_PRICING_CLUSTER,
        payload: pricings
      });
      yield put({ type: types.DEC_LOADING });
    }
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("billing:fetchPricingError", { error: error.message })
      })
    );
    console.error("fetchCustomers error", error);
  }
}

function* saveCustomerReference(action) {
  try {
    const { token, serverGroup, cluster } = yield select((state) => state);
    const billingService = yield call(() => new BillingService(token));
    yield call(
      [billingService, billingService.saveCustomerReference],
      action.payload
    );
    if (serverGroup) {
      yield put({
        type: types.FETCH_PRICING_SERVER_GROUP,
        payload: serverGroup.id
      });
    }
    if (cluster) {
      yield put({
        type: types.FETCH_PRICING_CLUSTER,
        payload: cluster.id
      });
    }

    // yield put(successNotification({ message: i18n.t('billing:saveCustomerReferenceSuccess') }));
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("billing:saveCustomerReferenceError", {
          error: error.message
        })
      })
    );
    console.error("saveCustomerReference error", error);
  }
}

function* fetchPricingForCustomer(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token, currentCustomer } = yield select((state) => state);
    let customerId = action.payload;
    if (
      PermissionService.isGranted(
        currentCustomer.permissions,
        PERMISSIONS.ACCOUNTING
      )
    ) {
      yield put({ type: types.INC_LOADING });
      const billingService = yield call(() => new BillingService(token));
      const pricings = yield call(
        [billingService, billingService.getBillingsForCustomer],
        action.payload
      );
      yield put({
        type: types.SET_PRICING_CUSTOMER,
        payload: pricings
      });
      yield put({ type: types.DEC_LOADING });
    }
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("billing:fetchPricingError", { error: error.message })
      })
    );
    console.error("fetchPricingForCustomer error", error);
  }
}
function* fetchPdf(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token, currentCustomer } = yield select((state) => state);
    let billingId = action.payload;
    if (
      PermissionService.isGranted(
        currentCustomer.permissions,
        PERMISSIONS.ACCOUNTING
      )
    ) {
      yield put({ type: types.INC_LOADING });
      const billingService = yield call(() => new BillingService(token));
      const pricingPdf = yield call(
        [billingService, billingService.getPricingPdf],
        action.payload
      );
      yield put({
        type: types.SET_PDF,
        payload: pricingPdf
      });
      yield put({ type: types.DEC_LOADING });
    }
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("billing:fetchPricingError", { error: error.message })
      })
    );
    console.error("fetchPricingPdf error", error);
  }
}
export function* billingSagas() {
  yield takeEvery(types.FETCH_PRICING_SERVER_GROUP, fetchPricingForServerGroup);
  yield takeEvery(types.FETCH_PRICING_CLUSTER, fetchPricingForCluster);
  yield takeEvery(types.SAVE_PRICING_CUSTOMER_REFERENCE, saveCustomerReference);
  yield takeEvery(types.FETCH_BILLINGS, fetchPricingForCustomer);
  yield takeEvery(types.FETCH_PDF, fetchPdf);
}

export default billingSagas;
