import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Popconfirm } from "antd";
import AntdInput from "./AntdInput";

const InlineEditText = (props) => {
  const { 
    value,
    okText,
    cancelText,
    name,
    isGranted,
    text,
    onUpdate,
  } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();

  const onCancel = () => {
    form.resetFields();
    setIsVisible(false);
  };

  const handleOnChange = () => {
    if (value !== form.getFieldValue(name)) {
      setIsVisible(true);
    }
  };

  const handleConfirm = () => {
    form.submit();
    setIsVisible(false);
  };

  return (
    <Form
      form={form}
      initialValues={{
        [name]: value,
      }}
      onFinish={(values) => {
        onUpdate(values[name]);
      }}
    >
      <Popconfirm
        placement="bottomRight"
        title={text}
        visible={isVisible}
        onConfirm={handleConfirm}
        onCancel={onCancel}
        okText={okText}
        cancelText={cancelText}
      >
        <AntdInput 
          name={name}
          disabled={!isGranted}
          value={value}
          fieldProps={{
            onBlur: handleOnChange,
          }}
        />
      </Popconfirm>
    </Form>
  );
}

InlineEditText.propTypes = {
  value: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  name: PropTypes.string,
  isGranted: PropTypes.bool,
  text: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default InlineEditText;
