import { takeEvery, put, call, select } from "redux-saga/effects";
import * as types from "../types";
import AuthService from "../../service/auth";
import UserService from "../../service/user";
import i18n from "../../shared/i18n";
import {
  errorNotification,
  successNotification
} from "../actions/notification";
import history from "../../history";
import { waitFor, parseErrors } from "./general";

function* login(action) {
  try {
    const authService = yield call(() => new AuthService());
    const result = yield call([authService, authService.login], action.payload);
    const { token } = { ...result };
    localStorage.setItem("token", JSON.stringify(token));
    yield call(initUser, token);
    const { redirectAfterLogin } = yield select((state) => state);

    if (redirectAfterLogin && redirectAfterLogin.length) {
      history.push(redirectAfterLogin);
      yield put({
        type: types.SET_REDIRECT_AFTER_LOGIN,
        payload: null
      });
    }
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:loginError", { error: error.message })
      })
    );
    console.error("login error", error);
  }
}

function* initUser(token) {
  try {
    const userService = yield call(() => new UserService());
    const currentUser = yield call([userService, userService.getCurrentUser]);
    yield put({
      type: types.SET_AUTHENTICATED_USER,
      payload: currentUser
    });
    yield put({ type: types.FETCH_CUSTOMERS });
    yield put({
      type: types.SET_TOKEN,
      payload: token
    });

    yield put({ type: types.FETCH_TRANSLATIONS });
    i18n.changeLanguage(currentUser.language);

  } catch (error) {
    console.error(error);
  }
  yield put({
    type: types.SET_LOGING_IN,
    payload: false
  });
}

function* restoreUser() {
  try {
    let token = null;
    try {
      token = yield JSON.parse(localStorage.getItem("token"));
    } catch (e) {}
    console.log(token);
    if (token) {
      yield call(initUser, token);
    } else {
      yield put({
        type: types.SET_LOGING_IN,
        payload: false
      });
    }
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:restoreUserError", { error: error.message })
      })
    );
    console.error("login error", error);
  }
}

function* logout() {
  try {
    localStorage.removeItem("token");

    yield put({
      type: types.SET_AUTHENTICATED_USER,
      payload: null
    });
    yield put({
      type: types.SET_ACTUAL_TOKEN,
      payload: null
    });
    yield put({ type: types.RESET_STATE });
    window.location = "/";
  } catch (error) {
    // yield call(errorAlert,  { message: i18n.t('saga:logoutError', { error: error.message }) });
    console.error("login error", error);
  }
}

function* resetPassword(action) {
  try {
    yield put({ type: types.INC_LOADING });
    const authService = yield call(() => new AuthService());

    yield call([authService, authService.resetPassword], action.payload);
    yield put({ type: types.DEC_LOADING });

    yield put(
      successNotification({
        message: "The message with instructions was sent to your email!"
      })
    );
    history.push("/login");
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:resetPasswordError", { error: error.message })
      })
    );
    console.error("reset password error", error);
  }
}

function* setNewPassword(action) {
  try {
    yield put({ type: types.INC_LOADING });
    const authService = yield call(() => new AuthService());

    yield call([authService, authService.setNewPassword], action.payload);
    yield put({ type: types.DEC_LOADING });

    yield put(successNotification({ message: "Password was set!" }));
    history.push("/login");
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:resetPasswordError", { error: error.message })
      })
    );
    yield put({ type: types.DEC_LOADING });
    console.error("reset password error", error);
  }
}

function* impersonate(action) {
  try {
    yield call(waitFor, (state) => state.token !== null);
    const { token } = yield select((state) => state);
    const authService = yield call(() => new AuthService(token));
    const { key: impersonateToken } = yield call(
      [authService, authService.impersonate],
      action.payload
    );

    yield put({
      type: types.SET_TOKEN,
      payload: impersonateToken
    });
    yield put({
      type: types.SET_ACTUAL_TOKEN,
      payload: token
    });

    yield call(initUser, impersonateToken);

    history.push("/");
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:impersonateError", { error: error.message })
      })
    );
    console.error("impersonate error", error);
  }
}

function* stopImpersonate() {
  try {
    const { actualToken } = yield select((state) => state);

    yield put({
      type: types.SET_TOKEN,
      payload: actualToken
    });
    yield put({
      type: types.SET_ACTUAL_TOKEN,
      payload: null
    });

    yield call(initUser, actualToken);
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:stopImpersonateError", { error: error.message })
      })
    );
    console.error("stop impersonate error", error);
  }
}

export function* authSagas() {
  yield takeEvery(types.LOGIN, login);
  yield takeEvery(types.LOGOUT, logout);
  yield takeEvery(types.RESET_PASSWORD, resetPassword);
  yield takeEvery(types.SET_NEW_PASSWORD, setNewPassword);
  yield takeEvery(types.RESTORE_USER, initUser);
  yield takeEvery(types.IMPERSONATE, impersonate);
  yield takeEvery(types.STOP_IMPERSONATE, stopImpersonate);
}

export default authSagas;
