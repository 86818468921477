import KeycloakService from "./KeycloakService";

const apiUrl = process.env.REACT_APP_API_URL;

let interval = null;
export default class ApiService {
  constructor() {
    this.token = KeycloakService.getToken();
    if (!interval) {
      interval = setInterval(() => {
        if (KeycloakService.isLoggedIn()) {
          KeycloakService.updateToken((success) => {
            if (success) {
              console.log("token will be refreshed");
              this.token = KeycloakService.getToken();
            } else {
              console.log("token is still valid");
            }
          });
        }
      }, 10000);    
    }
    this.useToken = true;
  }

  withoutToken = () => {
    this.useToken = false;

    return this;
  };

  updateToken = () => {
    return new Promise((resolve) => {
      KeycloakService.updateToken((success) => {
        if (success) {
          this.token = KeycloakService.getToken();
        }
        resolve(success);
      });
    });
  }

  async get(url) {
    try {
      let options = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      };

      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(apiUrl + url, options);
      const body = await result.json();
      if (result.status !== 200) {
        throw new Error(body.detail);
      }
      return body;
    } catch (err) {
      throw err;
    }
  }

  async post(url, data, isJson = true) {
    try {
      let options = {
        method: "POST",
        headers: {}
      };
      if (data) {
        options.body = data;
      }
      if (isJson) {
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(data);
      }
      if (this.useToken && this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(apiUrl + url, options);
      const body = await result.json();
      if (![200, 201].includes(result.status)) {
        throw new Error(this.parseErrors(body));
      }
      return body;
    } catch (err) {
      throw err;
    }
  }

  async put(url, data) {
    try {
      let options = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(apiUrl + url, options);
      const body = await result.json();
      if (result.status !== 200) {
        throw new Error(this.parseErrors(body));
      }
    } catch (err) {
      throw err;
    }
  }

  async patch(url, data) {
    try {
      let options = {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(apiUrl + url, options);
      const body = await result.json();
      if (result.status !== 200) {
        throw new Error(this.parseErrors(body));
      }
    } catch (err) {
      throw err;
    }
  }
  async delete(url) {
    try {
      let options = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" }
      };
      if (this.useToken && this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(apiUrl + url, options);
      const body = await result.json();
      if (result.status !== 200) {
        throw new Error(this.parseErrors(body));
      }
      return body;
    } catch (err) {
      throw err;
    }
  }
  parseErrors(body) {
    if (body.detail) {
      return body.detail;
    } else if (body.non_field_errors) {
      return body.non_field_errors.join(", ");
    } else if (Object.keys(body).length) {
      let errors = "";
      Object.keys(body).forEach((key) => {
        errors += body[key].join("#br# ");
      });
      return errors;
    } else {
      return "some undefined error";
    }
  }
}
