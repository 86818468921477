import * as types from "../types";

let notifications = [];

export const initialState = [];
const moduleReducers = { notifications: setNotification };

function addNotification(notification) {
  notifications.push(notification);
  return [...notifications];
}

function removeNotification(notification) {
  notifications = notifications.filter(function (item) {
    return item.uuid !== notification.uuid;
  });
  return notifications;
}

function setNotification(state = initialState, action) {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return addNotification(action.payload);
    case types.REMOVE_NOTIFICATION:
      return removeNotification(action.payload);
    default:
      return state;
  }
}

export default moduleReducers;
