import React from "react";
import { Layout, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import breadcrumbs from "../../shared/breadcrumbs";
import i18n from "../../shared/i18n";
import "./Container.less";

const Container = ({ children, breadcrumb, dynamicBreadcrumbs, className }) => {
  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    const absolutePaths = paths.map((path) => `/${path}`);

    return last || !route.path ? (
      <span>{i18n.t(route.breadcrumbName)}</span>
    ) : (
      <Link to={absolutePaths.join("")}>{i18n.t(route.breadcrumbName)}</Link>
    );
  }

  let breadcrumbRoutes = breadcrumbs[breadcrumb];

  if (dynamicBreadcrumbs) {
    breadcrumbRoutes = [ ...breadcrumbRoutes, ...dynamicBreadcrumbs ];
  }

  return (
    <Layout className={`content-container ${className ?? ''}`}>
      <Breadcrumb
        style={{ margin: "16px 0" }}
        itemRender={itemRender}
        routes={breadcrumbRoutes}
      />
      {children}
    </Layout>
  );
};

export default Container;
