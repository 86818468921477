import React from "react";
import { PropTypes } from "prop-types";
import { withTranslation } from "react-i18next";
import {
  Button,
  Popconfirm,
  Tooltip
} from "antd";
import {
  DeleteOutlined,
} from "@ant-design/icons";
import "./styles.less";

export const DeleteBtn = (props) => {
    const { onConfirm, t, confirmTitle, okText, cancelText } = props;

    const onConfirmClick = (e) => {
        e.stopPropagation();
        onConfirm();
    }

    const onCancelClick = (e) => {
        e.stopPropagation();
    }

    return (
        <Popconfirm
            okText={okText}
            cancelText={cancelText}
            onConfirm={onConfirmClick}
            onCancel={onCancelClick}
            title={confirmTitle}
            placement="bottomRight"
        >
            <Tooltip
                placement="bottom"
                title={t("buttons:delete")}
                placement="bottom"
            >
                <Button
                    color="secondary"
                    shape="circle"
                    className="btn-delete-user"
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={(e) => e.stopPropagation() }
                />
            </Tooltip>
        </Popconfirm>
    )
}

DeleteBtn.propTypes = {
    onConfirm: PropTypes.func,
    t: PropTypes.func,
    confirmTitle: PropTypes.string,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
}

DeleteBtn.defaultProps = {
    onConfirm: () => {},
    t: () => {},
    confirmTitle: "",
    okText: "",
    cancelText: "",
}

export default withTranslation('buttons')(DeleteBtn);
