import React from 'react';
import PropTypes from 'prop-types';
import { PaperClipOutlined } from "@ant-design/icons";
import { Typography, Row } from 'antd';
import DeleteBtn from '../../components/shared/DeleteBtn';
import { withTranslation } from 'react-i18next';

export const Attachment = withTranslation('users')((props) => {
    const { title, index, style, t } = props;

    const onDeleteClick = () => {
        props.onDeleteClick(index);
    }

    return (
        <Row style={{ ...styles.container, ...style }} align="middle">
            <PaperClipOutlined />
            <span style={styles.title}><Typography.Text>{title}</Typography.Text></span>
            <DeleteBtn
                onConfirm={onDeleteClick} 
                confirmTitle={t("confirmDeleteAttachment")}
                okText={t("buttons:confirm")}
                cancelText={t("buttons:cancel")}
            />
        </Row>
    )
})

Attachment.propTypes = {
    title: PropTypes.string,
    style: PropTypes.object, 
    onDeleteClick: PropTypes.func,
    index: PropTypes.number,
};

Attachment.defaultProps = {
    title: '',
    style: {},
    onDeleteClick: () => {},
    index: 0,
}

const styles = {
    container: {
        marginTop: 10,
    },
    title: {
        marginLeft: 5, 
        marginRight: 20
    }
}