import React from "react";
import { Form, Input } from "antd";

export default function AntdInput({
  required,
  infoKey,
  placeholder,
  disabled,
  type,
  help,
  rules,
  fieldProps,
  ...props
}) {
  return (
    <Form.Item
      {...props}
      rules={[ ...(rules || []), { required: required || false }]}
      required={required || false}
      tooltip={infoKey}
      help={help}
    >
      <Input
        {...fieldProps}
        disabled={disabled}
        type={type || "text"}
        placeholder={placeholder}
      />
    </Form.Item>
  );
}
