import React, { useState } from "react";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Row, Col, Upload, Typography } from 'antd';
import { PaperClipOutlined, SendOutlined, LinkOutlined, OrderedListOutlined, UnorderedListOutlined, BoldOutlined, ItalicOutlined, UnderlineOutlined } from "@ant-design/icons";
import { Editor, EditorState, RichUtils, CompositeDecorator,  } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';
import 'draft-js/dist/Draft.css';
import "./styles.less";
import { Attachment } from "../../../pages/Tickets/Attachment";
import { isRichEditorEmpty } from "../../../shared/richEditorUtil";

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const Link = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url}>
      {props.children}
    </a>
  );
};

const RichEditor = (props) => {
  const { t, withAttachment, withSendBtn, onEditorChange, attachments, error } = props;

  const shouldHidePlaceholder = (editorState) => {
		// If the user changes block type before entering any text, we can
		// either style the placeholder or hide it. Let's just hide it now.
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== 'unstyled') {
				return true;
			}
		}
		return false;
	};

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(decorator),
  );
  const [placeholderHidden, setPlaceholderHidden] = useState(() => shouldHidePlaceholder(editorState));
  const [sendBtnDisabled, setSendBtnDisabled] = useState(true);


  const onRichEditorChange = (newState) => {
		setEditorState(newState);
		setPlaceholderHidden(shouldHidePlaceholder(newState));
	};

  const onBoldClick = () => {
		onRichEditorChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
	};
	
	const onItalicClick = () => {
		onRichEditorChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
	};
	
	const onUnderlineClick = () => {
		onRichEditorChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
	};

  const onOrderedListClick = () => {
		onRichEditorChange(RichUtils.toggleBlockType(editorState, "ordered-list-item"));
	};
	
	const onUnorderedListClick = () => {
		onRichEditorChange(RichUtils.toggleBlockType(editorState, "unordered-list-item"));
	};

  const onLinkClick = () => {
    const currentSelection = editorState.getSelection();
    const blockKey = currentSelection.getStartKey();
    const selectedBlock = editorState.getCurrentContent().getBlockForKey(blockKey);

    let linkFound = false;
    selectedBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && editorState.getCurrentContent().getEntity(entityKey).getType() === 'LINK'
    }, () => {
      linkFound = true;
    });

    if (linkFound) {
      onRichEditorChange(RichUtils.toggleLink(editorState, editorState.getSelection(), null));
    } else {
      let selectedText = getFragmentFromSelection(editorState);
      selectedText = selectedText ? selectedText.map(x => x.getText()).join('\n') : '';

      const contentState = editorState.getCurrentContent();
      const link = window.prompt('Paste the link -');
      const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: link, text: selectedText });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

      onRichEditorChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    }
  };

  const uploadProps = {
    beforeUpload: (_, fileList) => {
      props.onAttachmentChange(fileList);
      return Upload.LIST_IGNORE;
    },
    onChange(_) {
    },
    multiple: true,
  };

  const onChange = (newState) => {
    const currentContentState = editorState.getCurrentContent();
    const newContentState = newState.getCurrentContent();

    if (currentContentState !== newContentState) {
      const content = convertToHTML({
                 blockToHTML: { // I put this here to have <div> in my output instead of <p>
                        unstyled: {
                            start: '<div>',
                            end: '</div>',
                            empty: '<br>',
                        }
                    },
                    entityToHTML: (entity, originalText) => {
                        console.log('entity.type', entity.type);
                        if (entity.type === 'LINK') {
                            return {
                                start: `<a href="//${entity.data.url}" target="_blank">`,
                                end: '</a>',
                            }
                        }
                        return originalText
                    }
                })(newState.getCurrentContent());
      //const content = convertToHTML(newState.getCurrentContent());
      onEditorChange(content);
      setSendBtnDisabled(isRichEditorEmpty(content));
    }

    setEditorState(newState);
  };

  const onSendClick = () => {
    props.onSendClick(attachments);

    setEditorState(EditorState.createEmpty(decorator));
  }

  const onDeleteAttachmentClick = (index) => {
    props.onDeleteAttachmentClick(index);
  }

  return (
    <Row className="rich-editor-wrapper">
      <Col span={24}>
        <Row>
          <Col span={24} className={`rich-editor-container ${error && 'error'}`}>
            <Row className="editor-btns-container">
              <Button className="editor-btn" type="text" onClick={onBoldClick}><BoldOutlined /></Button>
              <Button className="editor-btn" type="text" onClick={onItalicClick}><ItalicOutlined /></Button>
              <Button className="editor-btn" type="text" onClick={onUnderlineClick}><UnderlineOutlined /></Button>
              <div className="separator"/>
              <Button className="editor-btn" type="text" onClick={onOrderedListClick}><OrderedListOutlined /></Button>
              <Button className="editor-btn" type="text" onClick={onUnorderedListClick}><UnorderedListOutlined /></Button>
              {withAttachment &&
              <>
                <div className="separator"/>
                <Upload { ...uploadProps }>
                  <Button className="editor-btn" type="text"><PaperClipOutlined /></Button>
                </Upload>
              </>
              }

            </Row>
            <div className="editor-container">
              <Editor
                editorState={editorState}
                onChange={onChange}
                stripPastedStyles={true}
              />
            </div>

            {withSendBtn &&
            <Row justify={`${attachments?.length > 0 ? 'space-between' : 'end'}`} style={{ paddingBottom: 10 }}>
              <div>
                {attachments.map((attachment, index) => (
                <Attachment 
                  key={`${index}`}
                  index={index}
                  title={attachment.name}
                  onDeleteClick={() => { onDeleteAttachmentClick(index) }}
                  style={{ marginLeft: 15 }}
                />
                ))}
              </div>
              <Button onClick={onSendClick} disabled={sendBtnDisabled} icon={<SendOutlined />} className="submit-btn">{t("sendReply")}</Button>
            </Row>
            }
          </Col>
        </Row>
        <Row style={{ height: 22 }}>
          <Typography.Text type="danger">{error}</Typography.Text>
        </Row>
      </Col>
    </Row>
  )
}

RichEditor.propTypes = {
  withAttachment: PropTypes.bool,
  withSendBtn: PropTypes.bool,
  onEditorChange: PropTypes.func,
  onSendClick: PropTypes.func,
  attachments: PropTypes.array,
  onAttachmentChange: PropTypes.func,
  onDeleteAttachmentClick: PropTypes.func,
  error: PropTypes.string,
}

RichEditor.defaultProps = {
  withAttachment: false,
  withSendBtn: false,
  onEditorChange: () => {},
  onSendClick: () => {},
  attachments: [],
  onAttachmentChange: () => {},
  onDeleteAttachmentClick: () => {},
  error: '',
}

export default withTranslation('tickets')(RichEditor);
