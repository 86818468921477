import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { notification } from "antd";
import { NOTIFICATION } from "../shared/constants";
import { REMOVE_NOTIFICATION } from "../store/types";

export default function Notification() {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications || []);
  const [shownNotifications, setShownNotifications] = useState([]);

  useEffect(() => {
    notifications.map((notification) => openNotification(notification));
  }, [notifications]);

  const openNotification = (info) => {
    const { level, title, message, uuid } = info;
    if (shownNotifications.includes(uuid)) {
      return;
    }
    const config = {
      message: title,
      description: message,
      onClick: () =>
        dispatch({
          type: REMOVE_NOTIFICATION,
          payload: info
        })
    };
    switch (level) {
      case NOTIFICATION.LEVELS.SUCCESS:
        notification.success(config);
        break;
      case NOTIFICATION.LEVELS.ERROR:
        notification.error(config);
        break;
      case NOTIFICATION.LEVELS.INFO:
        notification.info(config);
        break;
      case NOTIFICATION.LEVELS.WARNING:
        notification.warning(config);
        break;
      default:
        notification.open(config);
    }
    setShownNotifications([...shownNotifications, info.uuid]);
  };

  return null;
}
