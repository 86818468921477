import * as types from '../types';


let showSidebar = false;
const moduleReducers = { 
  pageHeader: setPageheader, 
  showSidebar: toggleSidebar, 
};

function setPageheader(state = {}, action) {
  switch (action.type) {
  case types.SET_PAGE_HEADER:
    return action.payload;
  default:
    return state;
  }
}

function toggleSidebar(state = showSidebar, action) {
  switch (action.type) {
  case types.TOGGLE_SIDEBAR:
    showSidebar =!showSidebar;
    return showSidebar;
  default:
    return state;
  }
}



export default moduleReducers;
