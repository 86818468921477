import React from "react";
import { Form, Select, Tooltip } from "antd";

export default function AntdSelect({
  placeholder,
  disabled,
  infoKey,
  required,
  options,
  onChange,
  tooltip,
  help,
  validationStatus,
  rules,
  ...props
}) {
  return (
    <Form.Item
      {...props}
      rules={[ ...(rules || []), { required: required || false }]}
      required={required || false}
      tooltip={infoKey}
      validateStatus={validationStatus}
    >
      <Select
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
      />
    </Form.Item>
  );
}
