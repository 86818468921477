import ApiService from "./api";

export default class CustomerService extends ApiService {
  async getCustomers() {
    return this.get("/hscrm/customers/");
  }

  async getCustomerById(id) {
    return this.get(`/hscrm/customers/${id}/`);
  }

  async saveCustomer(customer) {
    return this.put(`/hscrm/customers/${customer.id}/`, customer);
  }
}
