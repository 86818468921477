import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Badge,
  Popconfirm,
  PageHeader,
  Input,
  Typography,
  Row,
  Col,
  Tooltip
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { bindActionCreators, compose } from "redux";
import { PERMISSIONS } from "../../shared/constants/permissions";
import {
  fetchUsers,
  fetchInvites,
  createInvite,
  uninviteUser,
  deleteInvite,
  fetchRoles,
  saveUserCustomer
} from "../../store/actions/user";
import "./Users.less";
import PermissionService from "../../service/permission";
import InlineEditSelect from "../../components/form/InlineEditSelect";
import Page from "../../components/Page";
import AntdTable from "../../components/global/AntdTable";
import PermissionedComponent from "../../components/global/PermissionedComponent";
import InviteUser from "./InviteUser";
import DeleteBtn from "../../components/shared/DeleteBtn";

class Users extends Component {
  static propTypes = {
    authenticatedUser: PropTypes.object,
    currentCustomer: PropTypes.object,
    users: PropTypes.array,
    t: PropTypes.func,
    fetchUsers: PropTypes.func,
    fetchInvites: PropTypes.func,
    fetchRoles: PropTypes.func,
    uninviteUser: PropTypes.func,
    deleteInvite: PropTypes.func,
    saveUserCustomer: PropTypes.func
  };

  state = { isModalOpen: false, tableData: [], filteredData: [], searchValue: "" };

  userTableColumns = [
    {
      title: this.props.t("tableHeaderName"),
      dataIndex: "first_name",
      render: (_, user) => this.renderUsername(user),
      sorter: this.sortName.bind(this),
      width: "230px"
    },
    {
      title: this.props.t("tableHeaderEmail"),
      dataIndex: "email",
      sorter: this.sortEmail.bind(this),
      render: (_, user) => (
        <Typography.Text type={!user.id ? "secondary" : ""}>
          {user.email}
        </Typography.Text>
      ),
      width: "300px",
      textWrap: "word-break"
    },
    {
      title: this.props.t("tableHeaderRole"),
      dataIndex: ["user_customer", "roles"],
      sorter: this.sortRoles.bind(this),
      render: (_, user) => this.renderRoleEdit(user),
      width: "239px"
    },
    {
      title: this.props.t("tableHeaderTechnicalContact"),
      sorter: this.sortTechnicalContact.bind(this),
      dataIndex: ["user_customer", "technical_contact"],
      render: (_, user) => this.renderTechnicalContactEdit(user),
      width: "239px"
    },
    {
      title: "",
      key: "action",
      width: "52px",
      render: (_, user) => {
        const { t } = this.props;
        return (
          <>
            {this.props.authenticatedUser.id !== user.id && (
              <PermissionedComponent
                grantedPermissions={this.props.currentCustomer.permissions}
                requestedPermission={PERMISSIONS.READ_AND_DELETE_USER}
              >
                <DeleteBtn 
                  onConfirm={() => { user.id ? this.onUninviteUser(user.id) : this.onDeleteInvite(user.uid) }}
                  confirmTitle={t("confirmDeleteUserText")}
                  okText={t("buttons:confirm")}
                  cancelText={t("buttons:cancel")}
                />
              </PermissionedComponent>
            )}
          </>
        );
      }
    }
  ];

  hasUserCustomer(user) {
    return user.user_customer && user.user_customer.length;
  }

  getUserRoleForCustomer(user) {
    if (!this.hasUserCustomer(user)) {
      return null;
    }

    const { currentCustomer } = this.props;

    const userRole = user.user_customer.find(
      (userRole) => userRole.customer === currentCustomer.id
    );

    return userRole;
  }

  sortData(a, b) {
    if (a < b) {
      return -1;
    }

    if (a > b) {
      return 1;
    }

    return 0;
  }

  sortName(a, b) {
    if (!a.first_name || !b.first_name) {
      return 0;
    }

    const aFullName = `${a.first_name} ${a.last_name}`;
    const bFullName = `${b.first_name} ${b.first_name}`;

    return this.sortData(aFullName, bFullName);
  }

  sortEmail(a, b) {
    return this.sortData(a.email, b.email);
  }

  sortRoles(a, b) {
    const {
      roles: { data: roles }
    } = this.props;

    const userRoleA = this.getUserRoleForCustomer(a);
    const userRoleB = this.getUserRoleForCustomer(b);

    if (!userRoleA || !userRoleB) {
      return 0;
    }

    return this.sortData(roles[userRoleA.roles], roles[userRoleB.roles]);
  }

  sortTechnicalContact(a, b) {
    const userRoleA = this.getUserRoleForCustomer(a);
    const userRoleB = this.getUserRoleForCustomer(b);

    if (!userRoleA || !userRoleB) {
      return 0;
    }

    const aTechnicalContact = userRoleA.technical_contact ? "Yes" : "No";
    const bTechnicalContact = userRoleB.technical_contact ? "Yes" : "No";

    return this.sortData(aTechnicalContact, bTechnicalContact);
  }

  componentDidMount() {
    const { fetchUsers, fetchInvites, fetchRoles, users, invites } = this.props;

    fetchUsers();
    fetchInvites();
    fetchRoles();

    if (users.length > 0 || invites.length > 0) {
      const data = [...users, ...invites];
      this.setState({ tableData: [...data], filteredData: [...data] });
    }
  }

  onUninviteUser = (id) => {
    this.props.uninviteUser(id, this.props.currentCustomer.id);
  };

  componentDidUpdate(prevProps) {
    const { currentCustomer, fetchUsers, fetchInvites, users, invites } =
      this.props;

    if (
      prevProps.currentCustomer !== currentCustomer &&
      prevProps.currentCustomer !== null
    ) {
      fetchUsers();
      fetchInvites();
    }

    if (
      JSON.stringify(prevProps.users) !== JSON.stringify(users) ||
      JSON.stringify(prevProps.invites) !== JSON.stringify(invites)
    ) {
      const data = [...users, ...invites];
      this.setState({ tableData: data });
      this.updateFilteredDataBySearchValue(data, this.state.searchValue);
    }
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => this.setState({ isModalOpen: false });

  onSubmitInvite = (values) => {
    this.props.createInvite(values);
    this.closeModal();
  };

  onDeleteInvite = (inviteId) => {
    this.props.deleteInvite(inviteId);
  };

  renderForm = () => {
    const { t, roles } = this.props;
    const { isModalOpen } = this.state;

    return (
      <InviteUser
        roles={roles}
        t={t}
        isModalOpen={isModalOpen}
        closeModal={this.closeModal.bind(this)}
      />
    );
  };

  onSaveUserCustomer = (userRole, values) => {
    this.props.saveUserCustomer({ ...userRole, ...values });
  };

  renderRoleEdit = (user) => {
    let { currentCustomer, roles, authenticatedUser, t } = this.props;

    let userRole = this.getUserRoleForCustomer(user);

    const userRoles = roles?.data || {};

    return (
      userRole && (
        <InlineEditSelect
          value={{ roles: roles?.data?.[userRole.roles] }}
          name="roles"
          options={Object.keys(userRoles).map((key) => ({
            label: userRoles[key],
            value: key
          }))}
          text={t("confirmRoleUpdateText")}
          okText={t("buttons:update")}
          cancelText={t("buttons:cancel")}
          isGranted={
            PermissionService.isGranted(
              currentCustomer?.permissions,
              PERMISSIONS.UPDATE
            ) && user.id !== authenticatedUser.id
          }
          onUpdate={(value) => this.onSaveUserCustomer(userRole, value)}
        />
      )
    );
  };

  editRolePermissionsGranted = () => {
    const { currentCustomer } = this.props;
    return PermissionService.isGranted(currentCustomer?.permissions, PERMISSIONS.UPDATE);
  }

  renderTechnicalContactEdit = (user) => {
    let { t } = this.props;

    let userRole = this.getUserRoleForCustomer(user);

    const options = { false: t("general:no"), true: t("general:yes") };

    return (
      userRole && (
        <InlineEditSelect
          value={{ technical_contact: userRole.technical_contact.toString() }}
          name="technical_contact"
          displayValue={userRole.technical_contact ? t("general:yes").toUpperCase() : t("general:no").toUpperCase()}
          options={Object.keys(options).map((key) => ({
            label: options[key],
            value: key
          }))}
          text={t("confirmTechnicalContactText")}
          okText={t("buttons:update")}
          cancelText={t("buttons:cancelShort")}
          isGranted={this.editRolePermissionsGranted()}
          onUpdate={(value) => this.onSaveUserCustomer(userRole, value)}
        />
      )
    );
  };

  redirectToUserDetailsPermissionsGranted = () => {
    const { currentCustomer } = this.props;
    return PermissionService.isGranted(currentCustomer?.permissions, PERMISSIONS.READ_AND_DELETE_USER);
  }

  renderUsername = (user) => {
    const { t } = this.props;
    if (user.id !== undefined) {
      return (
        <>
          <Tooltip placement="bottom" title={t("activeUser")}>
            <Badge status="success" />
          </Tooltip>
          {this.redirectToUserDetailsPermissionsGranted()
          ? 
          <Link
            to={`/settings/users/${user.id}`}
          >{`${user.first_name} ${user.last_name}`}</Link>
          :
          <Typography.Text>{`${user.first_name} ${user.last_name}`}</Typography.Text>
          }
        </>
      );
    }

    return (
      <>
        <Tooltip placement="bottom" title={t("invitationPending")}>
          <Badge status="warning" />
        </Tooltip>
        <Typography.Text type="secondary">{t("labelPending")}</Typography.Text>
      </>
    );
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = this.props.t("form:fieldWarning");
    }
    if (!values.roles) {
      errors.roles = this.props.t("form:fieldWarning");
    }
    return errors;
  };

  handleSearch({ target: { value } }) {
    this.setState({ searchValue: value });
    this.updateFilteredDataBySearchValue(this.state.tableData, value);
  }

  updateFilteredDataBySearchValue = (tableData, searchValue) => {
    if (searchValue) {
      const filteredRecords = tableData.filter((data) => {
        if (data.id !== undefined) {
          return (
            data.first_name.includes(searchValue) ||
            data.last_name.includes(searchValue) ||
            data.email.includes(searchValue)
          );
        }
        return data.email.includes(searchValue);
      });

      this.setState({ filteredData: filteredRecords });
    } else {
      this.setState({ filteredData: tableData });
    }
  }

  headerComponents() {
    const { currentCustomer, t } = this.props;
    return (
      <Row gutter={[24, 24]} key="header-components">
        <Col xs={24} sm={16}>
          <Input
            prefix={<SearchOutlined />}
            key="search"
            name="search"
            placeholder={t("placeholderSearch")}
            onChange={this.handleSearch.bind(this)}
          />
        </Col>
        <Col xs={24} sm={8}>
          <PermissionedComponent
            key="button"
            grantedPermissions={currentCustomer?.permissions}
            requestedPermission={PERMISSIONS.CREATE_INVITATION}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              onClick={this.openModal}
            >
              {t("buttons:inviteUser")}
            </Button>
          </PermissionedComponent>
        </Col>
      </Row>
    );
  }

  render() {
    const { t } = this.props;
    const { filteredData } = this.state;
    const headerComponents = this.headerComponents();

    return (
      <Page breadcrumb="settings_users">
        <PageHeader
          className="site-page-header"
          title={t("pageTitleUsers")}
          extra={[headerComponents]}
        />
        <AntdTable
          rowKey={(record) => record.email}
          columns={this.userTableColumns}
          data={filteredData}
        />
        {this.renderForm()}
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers,
      fetchInvites,
      createInvite,
      uninviteUser,
      deleteInvite,
      fetchRoles,
      saveUserCustomer
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    authenticatedUser: state.authenticatedUser,
    users: state.users,
    invites: state.invites,
    roles: state.roles
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation("users")(Users));
