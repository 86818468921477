import {
  FETCH_CUSTOMERS,
  FETCH_CUSTOMER,
  SAVE_CUSTOMER,
  SWITCH_CUSTOMER
} from './../types';

export function fetchCustomers() {
  return {
    type: FETCH_CUSTOMERS,
    payload: {},
  };
}

export function fetchCustomer(id) {
  return {
    type: FETCH_CUSTOMER,
    payload: id,
  };
}

export function switchCustomer(customer = null) {
  return {
    type: SWITCH_CUSTOMER,
    payload: customer,
  };
}

export function saveCustomer(company) {
  return {
    type: SAVE_CUSTOMER,
    payload: company,
  };
}
