import React, { useState } from "react";
import { Drawer, Layout } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

import Navigation from "./Navigation";
import './Sidebar.less';


const { Sider } = Layout;

const Sidebar = () => {
  const width = window.innerWidth;
  const [collapsed, setCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(width < 1200);

  window.addEventListener("resize", (e) => {
    const width = e.currentTarget.innerWidth;

    if (width < 1200 && !collapsed) {
      setIsMobile(true);
    } else if (width >= 1200 && collapsed) {
      setIsMobile(false);
    }
  });

  return (
    <>
      {React.createElement(collapsed ? MenuOutlined : CloseOutlined, {
        className: "trigger ant-layout-sider-zero-width-trigger",
        onClick: () => setCollapsed(!collapsed)
      })}

      {isMobile ? (
        <Drawer
          placement="left"
          closable={false}
          visible={!collapsed}
          className="sidebar"
          getContainer={false}
          width={false}
          destroyOnClose
        >
          <Navigation />
        </Drawer>
      )
      :(
        <Sider
          theme="light"
          align="end"
          className="site-layout-background"
          breakpoint="lg"
          collapsible
          collapsedWidth={0}
          width={256}
          trigger={null}
          style={{ height: "94vh" }}
        >
          <Navigation />
        </Sider>
      )}
    </>
  );
};

export default Sidebar;
