import React from "react";
import { Form, Input } from "antd";

export default function AntdTextArea({
  name,
  required,
  label,
  infoKey,
  placeholder,
  disabled,
  type,
  rows,
}) {
  const { TextArea } = Input;
  return (
    <Form.Item
      name={name}
      label={label}
      type={type}
      rules={[{ required: required || false }]}
      required={required || false}
      tooltip={infoKey}
    >
      <TextArea rows={rows || 6} disabled={disabled} placeholder={placeholder} />
    </Form.Item>
  );
}
