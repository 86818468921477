import * as types from '../types';

const moduleReducers = {
  availableCustomers: setCustomers,
  recentCustomers: setRecentCustomers,
  currentCustomer: setCurrentCustomer,
};

function setCustomers(state = null, action) {
  switch (action.type) {
  case types.SET_CUSTOMERS:
    return action.payload;
  default:
    return state;
  }
}


function setRecentCustomers(state = [], action) {
  switch (action.type) {
  case types.SET_RECENT_CUSTOMERS:
    return action.payload;
  default:
    return state;
  }
}

function setCurrentCustomer(state = null, action) {
  switch (action.type) {
  case types.SET_CURRENT_CUSTOMER:
    return action.payload;
  default:
    return state;
  }
}
export default moduleReducers;
