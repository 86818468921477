import ApiService from './api';

export default class BillingService extends ApiService {
  constructor(token) {
    super(token);
  }

  async getPricingForServerGroup(serverGroupId) {
    return this.get(`/hsbilling/pricings/for_servergroup/${serverGroupId}/`);
  }
  async getPricingForCluster(clusterId) {
    return this.get(`/hsbilling/pricings/for_environment/${clusterId}/`);
  }
  async saveCustomerReference(pricing) {
    return this.patch(`/hsbilling/pricings/${pricing.id}/customer_reference/`,{ customer_reference:pricing.customer_reference });
  }
  async getBillingsForCustomer(customerId) {
    return this.get(`/hsbilling/pricings/for_customer/${customerId}/`);
  }
  async getPricingPdf(billingId) {
    return this.get(`/hsbilling/pricings/${billingId}/pdf/`);
  }
}
