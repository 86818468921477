import React from "react";
import PropTypes from "prop-types";
import { Tag, } from 'antd';
import i18n from '../../shared/i18n';
import "./styles.less";

export const TICKET_STATE = {
  NEW: 'new', 
  CLOSED: 'closed', 
  ARCHIVED: 'archived', 
  OPEN: 'open', 
  REMOVED: 'removed', 
  PENDING_AGENT_ACTION: 'pending agent action', 
  PENDING_AUTO_CLOSE_PLUS: 'pending auto close+', 
  PENDING_AUTO_CLOSE_MINUS: 'pending auto close-', 
  MERGED: 'merged', 
  CLOSED_WITH_WORKAROUND: 'closed with workaround', 
  IN_PROGRESS: 'in progress', 
  PENDING_CUSTOMER_REVIEW: 'pending customer review ', 
  PENDING_CUSTOMER_ACTION: 'pending customer action', 
  PENDING_CUSTOMER_REMINDER: 'pending customer reminder',
};

export const TICKET_STATE_TYPE = {
  NEW: 'new',
  OPEN: 'open',
  PENDING: 'pending',
  CLOSED: 'closed', 
}

export const TicketState = (props) => {
  const { state, stateType } = props;

  let color = "";
  switch(stateType) {
    case TICKET_STATE_TYPE.NEW:
      color = "yellow";
      break;

    case TICKET_STATE_TYPE.OPEN:
      color = "orange";
      break;

    case TICKET_STATE_TYPE.PENDING:
      color = "blue";
      break;

    case TICKET_STATE_TYPE.CLOSED:
      color = "green";
      break;

    default: 
      color = "gray";
  }

  return (
    <Tag className="ticket-state" color={color}>{i18n.t(`state:${state}`, { ns: "tickets" })}</Tag>
  )
}

TicketState.propTypes = {
  state: PropTypes.string,
  stateType: PropTypes.string,
}

