import alertsSagas from "./alert";
import authSagas from "./auth";
import customerSagas from "./customer";
import userSagas from "./user";
import systemEnvironmentSagas from "./systemEnvironment";
import userAccessSagas from "./userAccess";
import ticketSagas from "./ticket";
import notificationSagas from "./notification";
import billingSagas from "./billing";
import { fork } from "redux-saga/effects";

export default function* root() {
  yield fork(alertsSagas);
  yield fork(authSagas);
  yield fork(customerSagas);
  yield fork(userSagas);
  yield fork(systemEnvironmentSagas);
  yield fork(userAccessSagas);
  yield fork(ticketSagas);
  yield fork(billingSagas);
  yield fork(notificationSagas);
}
