import React from 'react';
import { Typography, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import './StatusLabel.less';

export const Status = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

const StatusLabel = (props) => {
  const { status, t, current, total } = props;

  const label = {
    [Status.SUCCESS]: t('statusGood'),
    [Status.WARNING]: t('statusWarning'),
    [Status.ERROR]: t('statusError'),
  }

  return (
    <Col>
      <Row justify="middle" className="status-label">
        <div className={`dot ${status}`}></div>
        <Typography.Text className={`label ${status}`}>{label[status] || ''}</Typography.Text>
      </Row>
      <Typography.Text>{t("serversStat", {
        current,
        total,
      })}</Typography.Text>
    </Col>
  )
}

export default withTranslation('systemEnvironment')(StatusLabel);