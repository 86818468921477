import * as types from '../types';

const moduleReducers = {
  clusters: setClusters,
  cluster: setCluster,
  pools: setPools,
  pool: setPool,
  serverGroups: setServerGroups,
  serverGroup: setServerGroup,
  documentation: setDocumentation,
  services: setServices,
  vhosts: setVhosts,
  vhost: setVhost,
};

function setClusters(state = [], action) {
  switch (action.type) {
  case types.SET_CLUSTERS:
    return action.payload;
  default:
    return state;
  }
}

function setCluster(state = null, action) {
  switch (action.type) {
  case types.SET_CLUSTER:
    return action.payload;
  default:
    return state;
  }
}

function setPools(state = [], action) {
  switch (action.type) {
  case types.SET_POOLS:
    return action.payload;
  default:
    return state;
  }
}

function setPool(state = null, action) {
  switch (action.type) {
  case types.SET_POOL:
    return action.payload;
  default:
    return state;
  }
}

function setServerGroups(state = [], action) {
  switch (action.type) {
  case types.SET_SERVER_GROUPS:
    return action.payload;
  default:
    return state;
  }
}

function setDocumentation(state = null, action) {
  switch (action.type) {
  case types.SET_DOCUMENTATION:
    return action.payload;
  default:
    return state;
  }
}

function setServices(state = [], action) {
  switch (action.type) {
  case types.SET_SERVICES:
    return action.payload;
  default:
    return state;
  }
}

function setVhosts(state = [], action) {
  switch (action.type) {
  case types.SET_VHOSTS:
    return action.payload;
  default:
    return state;
  }
}

function setVhost(state = null, action) {
  switch (action.type) {
  case types.SET_VHOST:
    return action.payload;
  default:
    return state;
  }
}

function setServerGroup(state = null, action) {
  switch (action.type) {
  case types.SET_SERVER_GROUP:
    return action.payload;
  default:
    return state;
  }
}

export default moduleReducers;
