import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Spin } from "antd";
import { bindActionCreators, compose } from "redux";
import { verifyEmail } from "../../store/actions/user";

const VerifyEmail = ({
  verifyEmailStatus,
  verifyEmail,
  match: {
    params: { uid }
  }
}) => {
  useEffect(() => {
    verifyEmail(uid);
  }, [uid]);

  if (!verifyEmailStatus) {
    return <Spin size="large" />;
  }

  return <React.Fragment />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ verifyEmail }, dispatch);
}

function mapStateToProps({ verifyEmailStatus }) {
  return { verifyEmailStatus };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VerifyEmail);
