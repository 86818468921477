import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { Avatar, Divider, Typography, Button, Row, Col, PageHeader, } from 'antd';
import Page from "../../components/Page";
import moment from "moment";
import 'draft-js/dist/Draft.css';
import "./styles.less";
import RichEditor from "../../components/shared/RichEditor";
import { fetchTicket, answerTicket, closeTicket } from "../../store/actions/ticket";
import { TicketState } from "../Tickets/TicketState";
import { Attachment } from "../../components/global/Attachment";

const Ticket = (props) => {
  const { t, ticket } = props;

  const onMarkSolvedClick = () => {
    if (ticket) {
      props.closeTicket(ticket.id);
    }
  }

  const renderHeaderComponents = () => {
    return (
      <Row gutter={[24, 24]} key="header-components">
          <Button
            type="primary"
            shape="round"
            onClick={onMarkSolvedClick}
          >
            {t("markSolved")}
          </Button>
      </Row>
    );
  }

  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const ticketId = props.match?.params?.ticketId;
    if (ticketId) {
      props.fetchTicket(ticketId);
    }
  }, [props.match.params?.ticketId]);

  const onEditorChange = (value) => {
    setMessage(value);
  }

  const onSendClick = (attachments) => {
    if (message && ticket) {
      const answer = {
        subject: ticket.title,
        body: message,
      }

      props.answerTicket({
        ticketId: ticket.id,
        answer,
        attachments,
        onSuccess: () => {
          setMessage("");
          setAttachments([]);
        }
      });
    }
  }
  
  const renderAttachement = (article, attachment) => {
    return (
      <Attachment
        ticket={ticket}
        article={article}
        attachment={attachment}
      />
    )
  }

  const getHistoryEventText = (event) => {
    const dateFormat = "DD.MM.YYYY, HH:mm:ss";
    if (event.type === "created") {
      return t(event.message_key, {
        create_at: moment(event.created_at).format(dateFormat),
      })
    } else if (event.type === "updated") {
      switch (event.attribute) {
        case "state":
          return t(event.message_key, {
            created_at: moment(event.created_at).format(dateFormat),
            value_from: t(`state:${event.value_from}`),
            value_to: t(`state:${event.value_to}`),
          })

        case "owner":
          return t(event.message_key, {
            created_at: moment(event.created_at).format(dateFormat),
            value_from: event.value_from,
            value_to: event.value_to,
          })

        case "group":
          return t(event.message_key, {
            created_at: moment(event.created_at).format(dateFormat),
            value_from: t(`category:${event.value_from}`),
            value_to: t(`category:${event.value_to}`),
          });

        default:
          return ""
      }
    }
    return "";
  }

  const onAttachmentChange = (fileList) => {
    setAttachments([...attachments, ...fileList]);
  }

  const onDeleteAttachmentClick = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  }

  return (
    <Page className="ticket-page" breadcrumb="ticket" dynamicBreadcrumbs={[
      {
        name: "ticket",
        path: "",
        breadcrumbName: ticket?.number || "",
      }
    ]}>
      
      <PageHeader
        className="site-page-header"
        title={ticket?.title ?? ""}
        extra={[renderHeaderComponents()]}
        onBack={() => window.history.back()}
      />

      <Row align="middle">
        <TicketState state={ticket?.state} stateType={ticket?.state_type} />
        <Typography.Text className="label">{t("ticketId")} </Typography.Text>
        <Typography.Text>{ticket?.id || ""}</Typography.Text>
        <Typography.Text className="label">{t("category")}</Typography.Text>
        <Typography.Text>{ticket?.category ? t(`category:${ticket?.category}`) : ""}</Typography.Text>
        {ticket?.related_object?.length > 0 &&
        <>
          <Typography.Text className="label">{t("relatedTo")}</Typography.Text>
          <Button type="link" className="no-padding">{ticket?.related_object?.map((obj) => ( obj.name )).join(", ")}</Button>
        </>
        }
      </Row>

      {Object.values(ticket?.events || {}).map((event, index) => {
        if (event.event_type === "history") {
          return (
            <Row key={`${index}`} align="middle">
              <Divider className="divider" plain>{getHistoryEventText(event)}</Divider>
            </Row>
          )
        } else if (event.event_type === "message") {
          const isMine = event.from === `${props.authenticatedUser.first_name} ${props.authenticatedUser.last_name}`;
          const justify = isMine ? "end" : "start";

          return (
            <Row className="message" key={`${index}`} justify={justify}>
              <Col>
                <Row justify={justify}>
                  <Typography.Text className="author">{event.from} - {moment(event.created_at).format("DD.MM.YYYY, HH:mm")}</Typography.Text>
                </Row>
                <Row justify={justify}>
                  {!isMine &&
                  <Col>
                    <Avatar size={32} className={`avatar ${isMine && "mine"}`}>{event.from?.substring(0, 1).toUpperCase()}</Avatar>
                  </Col>
                  }
                  <Col span={18} className={`message-container ${isMine && "mine"}`}>
                    <Row>
                      <Typography.Text><div dangerouslySetInnerHTML={{ __html: event.body }}></div></Typography.Text>
                    </Row>
                    {event.attachments?.map((attachment, index) => (
                    <Row key={`${index}`} style={{ whiteSpace: "nowrap" }}>
                      {renderAttachement(event, attachment)}
                    </Row>
                    ))}
                  </Col>
                  {isMine && 
                  <Col>
                    <Avatar size={32} className={`avatar ${isMine && "mine"}`}>{event.from?.substring(0, 1).toUpperCase()}</Avatar>
                  </Col>
                  }
                </Row>
              </Col>
            </Row>
          )
        } else {
          return null;
        }
      })}

      <Row>
        <RichEditor 
          withAttachment={true} 
          withSendBtn={true} 
          onEditorChange={onEditorChange}
          onSendClick={onSendClick}
          attachments={attachments}
          onAttachmentChange={onAttachmentChange}
          onDeleteAttachmentClick={onDeleteAttachmentClick}
        />
      </Row>

    </Page>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchTicket,
    answerTicket,
    closeTicket,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    authenticatedUser: state.authenticatedUser,
    ticket: state.ticket,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation("ticket")(Ticket));
