import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { Form, Button, Typography, Row, Col, Image, Select } from 'antd';
import { bindActionCreators, compose } from 'redux';
import { fetchInvite, registerUser, fetchTranslations } from '../../store/actions/user';
import './styles.less';
import AntdInput from '../../components/form/AntdInput';
import { passwordValidationRule } from '../../shared/helper/inputRules';
import logoImage from "../../assets/logoShort.svg";
import i18n from '../../shared/i18n';

const Register = (props) => {
  const { invite, authenticatedUser, t  } = props;
  const [language, setLanguage] = useState(i18n.language.substring(0, 2));

  useEffect(() => {
    const id = props.match?.params?.id;
    props.fetchInvite(id, true);

    props.fetchTranslations();
  }, []);

  const register = (values) => {
    props.registerUser({
      inviteId: invite?.uid,
      data: { ...values, language },
    });
  }

  const onLanguageChange = (value) => {
    i18n.changeLanguage(value);
    setLanguage(value);
  }

  if (authenticatedUser) return <Redirect to="/" />;

  if (!invite) return <></>;

  return (
    <div className="container register-page">
      <div className="form-container">
        <Row justify='space-between'>
          <Col>
            <Image style={{ width: 75, height: 24 }} src={logoImage} alt="logo" />
          </Col>
          <Col>
            <Select value={language} onChange={onLanguageChange}>
              <Select.Option value="en">{t("general:langEnglish")}</Select.Option>
              <Select.Option value="de">{t("general:langGerman")}</Select.Option>
              <Select.Option value="fr">{t("general:langFrench")}</Select.Option>
            </Select>
          </Col>
        </Row>
        
        <Typography.Title style={{ marginTop: 30, marginBottom: 20 }} level={3}>{t('registration')}</Typography.Title>

        <Form
          initialValues={{ email: invite?.email }}
          layout="vertical"
          className="register-form"
          onFinish={register}
          autoComplete="off"
          validateMessages={{ required: t('form:fieldWarning') }}
        >
          <AntdInput
            name="email"
            type="email"
            placeholder={t('labelEmail')}
            label={t('labelEmail')}
            required
            disabled
          />
          <AntdInput
            name="password"
            type="password"
            placeholder={t('labelPassword')}
            label={t('labelPassword')}
            required
            rules={[passwordValidationRule()]}
          />
          <AntdInput
            name="first_name"
            type="text"
            placeholder={t('labelFirstName')}
            label={t('labelFirstName')}
            required
          />
          <AntdInput
            name="last_name"
            type="text"
            placeholder={t('labelLastName')}
            label={t('labelLastName')}
            required
          />
          <AntdInput
            name="phone"
            type="text"
            placeholder={t('labelPhone')}
            label={t('labelPhone')}
          />
          <AntdInput
            name="mobile"
            type="text"
            placeholder={t('labelMobile')}
            label={t('labelMobile')}
          />

          <Button block type="primary" htmlType="submit">
            {t('buttons:submit')}
          </Button>
        </Form>

      </div>
    </div>
  );
}

Register.propTypes = {
  history:PropTypes.object,
  fetchInvite: PropTypes.func,
  invite: PropTypes.object,
  t: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvite,
    registerUser,
    fetchTranslations
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    authenticatedUser: state.authenticatedUser,
    invite: state.invite,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('users')(Register));
