import {
  NEW_NOTIFICATION_ERROR,
  NEW_NOTIFICATION_INFO,
  NEW_NOTIFICATION_SUCCESS,
  NEW_NOTIFICATION_WARNING
} from "../types";

export function errorNotification(options) {
  return {
    type: NEW_NOTIFICATION_ERROR,
    payload: options
  };
}
export function successNotification(options) {
  return {
    type: NEW_NOTIFICATION_SUCCESS,
    payload: options
  };
}
export function warningNotification(options) {
  return {
    type: NEW_NOTIFICATION_WARNING,
    payload: options
  };
}
export function infoNotification(options) {
  return {
    type: NEW_NOTIFICATION_INFO,
    payload: options
  };
}
