import React from 'react';
import { withTranslation } from 'react-i18next';
import InvoiceTable from '../../InvoiceTable';
import { Typography } from 'antd';

const InvoiceInfo = (props) => {
  const { data, t } = props;

  return (
    <>
      <Typography.Title level={3}>{t('invoiceInfo')}</Typography.Title>
      <InvoiceTable data={data} />
    </>
  )
}

export default withTranslation('systemEnvironment')(InvoiceInfo);