import ApiService from "./api";

export default class AuthService extends ApiService {
  login(data) {
    return this.post("/auth/token-auth/", data);
  }
  resetPassword(data) {
    return this.post("/auth/reset-password/", data);
  }
  setNewPassword(data) {
    return this.post("/auth/reset-password/confirm/", data);
  }
  impersonate(id) {
    return this.get(`/auth/impersonate/${id}/`);
  }
}
