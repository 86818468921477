import { combineReducers } from "redux";

import alertReducer from "./alert";
import authReducer from "./auth";
import customerReducer from "./customer";
import userReducer from "./user";
import systemEnvironmentReducer from "./systemEnvironment";
import userAccessReducer from "./userAccess";
import ticketReducer from "./ticket";
import notificationReducer from "./notification";
import navigationReducer from "./navigation";
import billingReducer from "./billing";
import loadingReducer from "./loading";
import * as types from "../types";

const appReducer = combineReducers({
  ...alertReducer,
  ...authReducer,
  ...customerReducer,
  ...userReducer,
  ...systemEnvironmentReducer,
  ...userAccessReducer,
  ...ticketReducer,
  ...notificationReducer,
  ...navigationReducer,
  ...billingReducer,
  ...loadingReducer,
});
const rootReducer = (state, action) => {
  if (action.type === types.RESET_STATE) {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
