import * as types from "./../types";

export function fetchUsers() {
  return {
    type: types.FETCH_USERS,
    payload: {}
  };
}

export function fetchInvites() {
  return {
    type: types.FETCH_INVITES,
    payload: {}
  };
}

export function createInvite(data) {
  return {
    type: types.CREATE_INVITE,
    payload: data
  };
}

export function fetchInvite(data) {
  return {
    type: types.FETCH_INVITE,
    payload: data
  };
}

export function saveInvite(data) {
  return {
    type: types.SAVE_INVITE,
    payload: data
  };
}

export function uninviteUser(userId, customerId) {
  return {
    type: types.UNINVITE_USER,
    payload: {
      userId,
      customerId
    }
  };
}

export function deleteInvite(inviteId) {
  return {
    type: types.DELETE_INVITE,
    payload: { inviteId }
  };
}

export function fetchRoles() {
  return { type: types.FETCH_ROLES };
}

export function registerUser(data) {
  return {
    type: types.REGISTER_USER,
    payload: data
  };
}

export function fetchUser(userId) {
  return {
    type: types.FETCH_USER,
    payload: userId
  };
}

export function saveUser(user) {
  return {
    type: types.SAVE_USER,
    payload: user
  };
}

export function deleteServiceAccount(user) {
  return {
    type: types.DELETE_USER,
    payload: user
  };
}

export function saveUserCustomer(userCustomer) {
  return {
    type: types.SAVE_USER_CUSTOMER,
    payload: userCustomer
  };
}

export function fetchSshEncryptionTypes() {
  return { type: types.FETCH_SSH_ENCRYPTION_TYPES };
}

export function fetchServiceAccounts() {
  return {
    type: types.FETCH_SERVICE_ACCOUNTS,
    payload: {}
  };
}
export function fetchUserTypes() {
  return { type: types.FETCH_USER_TYPES };
}

export function sendVerificationMail(user) {
  return {
    type: types.SEND_VERIFICATION_MAIL,
    payload: user
  };
}

export function verifyEmail(uid) {
  return {
    type: types.VERIFY_EMAIL,
    payload: uid
  };
}

export function verifyEmailStatus(status) {
  return {
    type: types.VERIFY_EMAIL_STATUS,
    payload: status
  };
}

export function cancelEmailVerification(uid) {
  return {
    type: types.CANCEL_EMAIL_VERIFICATION,
    payload: uid
  };
}

export function fetchTranslations() {
  return {
    type: types.FETCH_TRANSLATIONS,
    payload: {}
  }
}
