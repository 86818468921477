import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, } from 'antd';
import './styles.less';

const PublicLocationCard = (props) => {
  const { pool, height } = props;
  return (
    <Card style={{ height }}>
      <Typography.Title ellipsis={true} level={5}>{pool?.name || ''}</Typography.Title>
      <Typography.Text className="location-description">{pool?.description || ''}</Typography.Text>
    </Card>
  )
}

PublicLocationCard.propTypes = {
  pool: PropTypes.object,
  height: PropTypes.number,
}

export default PublicLocationCard;