import {
  FETCH_CLUSTERS,
  FETCH_SERVER_GROUPS,
  FETCH_SERVER_GROUP,
  FETCH_SERVICES,
  FETCH_DOCUMENTATION,
  FETCH_VHOSTS,
  FETCH_VHOST,
  SAVE_VHOST,
  FETCH_POOLS,
  FETCH_POOL,
  SET_POOL,
  FETCH_CLUSTER,
  SET_CLUSTER,
} from '../types';

export function fetchClusters(customerId, clusterId) {
  return {
    type: FETCH_CLUSTERS,
    payload: {
      customerId,
      clusterId 
    }
  };
}

export function setCluster(cluster) {
  return {
    type: SET_CLUSTER,
    payload: cluster,
  };
}

export function fetchPools(customerId) {
  return {
    type: FETCH_POOLS,
    payload: customerId,
  };
}

export function fetchPool(poolId) {
  return {
    type: FETCH_POOL,
    payload: poolId,
  };
}

export function setPool(pool) {
  return {
    type: SET_POOL,
    payload: pool,
  };
}

export function fetchServerGroups(clusterId) {
  return {
    type: FETCH_SERVER_GROUPS,
    payload: clusterId,
  };
}

export function fetchServerGroup(groupId) {
  return {
    type: FETCH_SERVER_GROUP,
    payload: groupId,
  };
}

export function fetchCluster(clusterId) {
  return {
    type: FETCH_CLUSTER,
    payload: clusterId,
  };
}

export function fetchServices(groupId) {
  return {
    type: FETCH_SERVICES,
    payload: groupId,
  };
}

export function fetchDocumentation(groupId) {
  return {
    type: FETCH_DOCUMENTATION,
    payload: groupId,
  }
}

export function fetchVhosts(groupId) {
  return {
    type: FETCH_VHOSTS,
    payload: groupId,
  };
}
export function fetchVhost(vhostId) {
  return {
    type: FETCH_VHOST,
    payload: vhostId,
  };
}
export function saveVhost(vhost, serverGroupId) {
  return {
    type: SAVE_VHOST,
    payload: {
      vhost,
      serverGroupId 
    } ,
  };
}
