import { takeEvery, put, call, select } from "redux-saga/effects";
import * as types from "../types";
import TicketService from "../../service/ticket";
import i18n from "../../shared/i18n";
import {
  errorNotification,
  successNotification
} from "../actions/notification";
import { waitFor } from "./general";
import history from "../../history";

function* fetchTickets(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    const ticketService = yield call(() => new TicketService(token));
    yield put({ type: types.INC_LOADING });
    let { customerId, archived } = action.payload;
    const tickets = yield call(
      [ticketService, ticketService.getTickets],
      { customerId, archived },
    );
    yield put({
      type: types.SET_TICKETS,
      payload: tickets
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchTicketsError", { error: error.message })
      })
    );
    console.error("fetchTickets error", error);
  }
}

function* fetchTicket(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    const ticketService = yield call(() => new TicketService(token));
    yield put({ type: types.INC_LOADING });
    const ticket = yield call(
      [ticketService, ticketService.getTicket],
      action.payload
    );
    yield put({
      type: types.SET_TICKET,
      payload: ticket
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:fetchTicketError", { error: error.message })
      })
    );
    console.error("fetchTicket error", error);
  }
}

function* saveTicket(action) {
  try {
    const { token } = yield select((state) => state);
    const ticketService = yield call(() => new TicketService(token));
    yield put({ type: types.INC_LOADING });

    const { customerId, ticket, attachments } = action.payload;

    const formData = new FormData();

    Object.keys(ticket)
      .forEach(key => formData.append(key, ticket[key]));

    if (attachments) {
      for(const attachment of attachments) {
        formData.append("file", attachment);
      }
    }

    const result = yield call([ticketService, ticketService.createTicket], {
      customerId,
      body: formData,
    });

    yield put({ type: types.ADD_TICKET, payload: result });

    history.push(`/tickets/${result.id}`);

    yield put({ type: types.DEC_LOADING });
    yield put(successNotification({ message: i18n.t("saga:ticketSaved") }));
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:saveTicketError", { error: error.message })
      })
    );
    console.error("saveTicket error", error);
  }
}

function* answerTicket(action) {
  try {
    const { token } = yield select((state) => state);
    const ticketService = yield call(() => new TicketService(token));
    yield put({ type: types.INC_LOADING });

    const { ticketId, answer, attachments, onSuccess } = action.payload;

    const formData = new FormData();

    Object.keys(answer)
      .forEach(key => formData.append(key, answer[key]));

    if (attachments) {
      for(const attachment of attachments) {
        formData.append("file", attachment);
      }
    }

    yield call([
      ticketService, 
      ticketService.createAnswer
    ], {
      ticketId,
      body: formData,
    });

    yield put({ type: types.DEC_LOADING });
    yield put(successNotification({ message: i18n.t("saga:answerSent") }));

    if (onSuccess) {
      onSuccess();
    }

    yield call(fetchTicket, { payload: ticketId });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: i18n.t("saga:answerTicketError", { error: error.message })
      })
    );
    console.error("answerTicket error", error);
  }
}

function* closeTicket(action) {
  try {
    const { token, currentCustomer } = yield select((state) => state);
    const ticketService = yield call(() => new TicketService(token));

    yield call([ticketService, ticketService.closeTicket], action.payload);
    let customerId = currentCustomer.id;
    yield put({
      type: types.FETCH_TICKETS,
      payload: {
        customerId,
      }
    });

    history.push("/tickets");
    yield put(successNotification({ message: i18n.t("saga:ticketClosed") }));
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("saga:answerTicketError", { error: error.message })
      })
    );
    console.error("answerTicket error", error);
  }
}

function* fetchCustomerObjects(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    const ticketService = yield call(() => new TicketService(token));
    yield put({ type: types.INC_LOADING });
    const { customerId, relation } = action.payload;
    const objects = yield call(
      [ticketService, ticketService.getCustomerObjects],
      { customerId, relation },
    );
    yield put({
      type: types.SET_CUSTOMER_OBJECTS,
      payload: objects
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      errorNotification({
        message: `Error fetching customer objects: ${error}`,
      })
    );
    console.error("fetchCustomerObjects error", error);
  }
}

function* fetchRequests(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    const ticketService = yield call(() => new TicketService(token));
    yield put({ type: types.INC_LOADING });

    const requests = yield call(
      [ticketService, ticketService.getRequests],
    );

    yield put({
      type: types.SET_REQUESTS,
      payload: requests
    });
    yield put({ type: types.DEC_LOADING });
  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    yield put(
      // errorNotification({
      //   message: `Error fetching customer objects: ${error}`,
      // })
    );
    console.error("fetchRequests error", error);
  }
}

function* downloadAttachment(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token } = yield select((state) => state);
    const ticketService = yield call(() => new TicketService(token));

    yield put({ type: types.INC_LOADING });

    const { ticketId, articleId, attachmentId, filename } = action.payload;
    const response = yield call(
      [ticketService, ticketService.getDownloadUrl],
      { ticketId, articleId, attachmentId },
    );
    
    if (response?.url) {
      const link = document.createElement("a");
      link.href = response.url;
      link.download = filename;
      link.click();
    }

    yield put({ type: types.DEC_LOADING });

  } catch (error) {
    yield put({ type: types.DEC_LOADING });
    console.error("downloadAttachment error", error);
  }
}
    

export function* ticketsSagas() {
  yield takeEvery(types.FETCH_TICKETS, fetchTickets);
  yield takeEvery(types.FETCH_TICKET, fetchTicket);
  yield takeEvery(types.SAVE_TICKET, saveTicket);
  yield takeEvery(types.ANSWER_TICKET, answerTicket);
  yield takeEvery(types.CLOSE_TICKET, closeTicket);
  yield takeEvery(types.FETCH_CUSTOMER_OBJECTS, fetchCustomerObjects);
  yield takeEvery(types.FETCH_REQUESTS, fetchRequests);
  yield takeEvery(types.DOWNLOAD_ATTACHMENT, downloadAttachment);
}

export default ticketsSagas;
