import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: "en",
    keySeparator: ":",
    interpolation: { escapeValue: false },
  });

let overrided = false;
export const overrideDefaultTFunction = () => {
  if (overrided) {
    return;
  }
  overrided = true;

  const debugFunc = (key, options) => {
    if (options?.ns) {
      return `${options.ns}:${key}`;
    }
    return key;
  }

  if (window.location.search.includes("debugTranslations")) {
    i18n.t = debugFunc;
  }
}


export default i18n;
