import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { Upload, Typography, Button, Row, Col, Input, PageHeader, Switch, Tag, Tooltip, Select, TreeSelect } from 'antd';
import { InboxOutlined, PlusOutlined, SearchOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import AntdTable from "../../components/global/AntdTable";
import Page from "../../components/Page";
import moment from "moment";
import AntdModal from '../../components/global/AntdModal';
import AntdInput from "../../components/form/AntdInput";
import RichEditor from "../../components/shared/RichEditor";
import "./styles.less";
import { fetchCustomerObjects, fetchTickets } from "../../store/actions/ticket";
import { TicketState, TICKET_STATE } from "./TicketState";
import CreateTicketModal from "./CreateTicketModal";

const Tickets = (props) => {
  const { t, tickets } = props;

  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchChars, setSearchChars] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const sortName = (a, b) => {
    if (!a.username || !b.username) {
      return 0;
    }

    return sortData(a.username, b.username);
  }

  const sortLastUpdated = (a, b) => {
    if (!a.last_updated || !b.last_updated) {
      return 0;
    }

    return sortData(a.last_updated, b.last_updated);
  }

  const sortType = (a, b) => {
    if (!a.type || !b.type) {
      return 0;
    }

    return sortData(a.type, b.type);
  }

  const sortTitle = (a, b) => {
    if (!a.title || !b.title) {
      return 0;
    }

    return sortData(a.title, b.title);
  }

  const sortCategory = (a, b) => {
    if (!a.category || !b.category) {
      return 0;
    }

    return sortData(a.category, b.category);
  }

  const sortState = (a, b) => {
    if (!a.state || !b.state) {
      return 0;
    }

    return sortData(a.state, b.state);
  }

  const sortData = (a, b) => {
    if (a < b) {
      return -1;
    }

    if (a > b) {
      return 1;
    }

    return 0;
  }

  const getFormattedRelation = (relation) => {
    return relation?.map((obj) => obj.name).join(", ") || "";
  }

  const sortRelation = (a, b) => {
    if (!a.related_object || !b.related_object) {
      return 0;
    }

    return sortData(getFormattedRelation(a.related_object), getFormattedRelation(b.related_object));
  }

  const formatDate = (date) => {
    if (moment(date).isSame(new Date(), "day")) {
      return `Today, ${moment(date).format("hh:mm")}`;
    } else {
      return moment(date).format("DD.MM.YYYY");
    }
  }

  const tableColumns = [
    {
      title: t("tableHeaderSubject"),
      dataIndex: "title",
      sorter: sortTitle,
      render: (_, ticket) => (
        <div className="subject-cell">
          <p><Typography.Text className="ticket-number">{ticket.number}</Typography.Text></p>
          <p><Button className="title" type="link" onClick={() => {}}>{ticket.title || ""}</Button></p>
        </div>
      ),
    },
    {
      title: t("tableHeaderRelation"),
      sorter: sortRelation,
      render: (_, ticket) => (
        <Typography.Text>{getFormattedRelation(ticket.related_object)}</Typography.Text>
      ),
    },
    {
      title: t("tableHeaderCategory"),
      sorter: sortCategory,
      render: (_, ticket) => (
        <Typography.Text>{ticket.category ? t(`category:${ticket.category}`) : ""}</Typography.Text>
      ),
    },
    {
      title: t("tableHeaderType"),
      sorter: sortType,
      render: (_, ticket) => (
        <Typography.Text>{t(`type:${ticket.type}`)}</Typography.Text>
      ),
    },
    {
      title: t("tableHeaderLastUpdated"),
      sorter: sortLastUpdated,
      render: (_, ticket) => (
        <Typography.Text>{formatDate(ticket.last_updated)}</Typography.Text>
      ),
    },
    {
      title: t("tableHeaderState"),
      sorter: sortState,
      render: (_, ticket) => (
        <TicketState state={ticket.state} stateType={ticket.state_type} />
      ),
    },
  ];

  const filterArchivedTickets = (ticketsToFilter) => {
    return ticketsToFilter?.filter((ticket) => ( ticket.state !== TICKET_STATE.ARCHIVED )) || [];
  }

  const handleSearch = ({ target: { value } }) => {
    setSearchChars(value);
  }

  const onTypeSwitch = () => {
    setShowArchived(!showArchived);
  }

  useEffect(() => {
    if (props.currentCustomer) {
      props.fetchTickets(props.currentCustomer?.id, showArchived);
    }
  }, [props.currentCustomer, showArchived]);

  useEffect(() => {
    if (!tickets) {
      return;
    }

    let ticketsToDisplay = showArchived ? tickets : filterArchivedTickets(tickets);

    if (searchChars) {
      ticketsToDisplay = ticketsToDisplay.filter((user) => {
        return user.title?.toLowerCase().includes(searchChars.toLowerCase());
      });

      setDataToDisplay(ticketsToDisplay);
    } else {
      setDataToDisplay([...ticketsToDisplay]);
    }
  }, [tickets, searchChars, showArchived]);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const renderHeaderComponents = () => {
    return (
      <Row gutter={[24, 24]} key="header-components">
        <Col xs={24} sm={13}>
          <Input
            prefix={<SearchOutlined />}
            key="search"
            name="search"
            placeholder={t("placeholderSearch")}
            onChange={handleSearch}
          />
        </Col>
        <Col xs={24} sm={11}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={openModal}
          >
            {t("newRequest")}
          </Button>
        </Col>
      </Row>
    );
  }

  const onTicketClick = (ticket) => {
    props.history.push(`/tickets/${ticket.id}`);
  }

  return (
    <Page breadcrumb="tickets">
      
      <PageHeader
        className="site-page-header"
        title={t("allSupportTickets")}
        extra={[renderHeaderComponents()]}
      />

      <Row justify="middle" className="type-switch" style={{ marginBottom: 15 }}>
        <Switch onChange={onTypeSwitch} />
        <Typography.Text className="label">{t("showArchive")}</Typography.Text>
        <Tooltip
          trigger="click"
          color="black"
          title={t("showArchiveTooltip")}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Row>

      <AntdTable
        className="tickets-table"
        rowKey={(record) => record.id}
        columns={tableColumns}
        data={dataToDisplay || []}
        onRow={(record) => {
          return {
            onClick: () => { onTicketClick(record); },
          };
        }}
      />

      <CreateTicketModal 
        isOpened={isModalOpened} 
        onClose={closeModal}
      />
    </Page>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchTickets,
    fetchCustomerObjects,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    authenticatedUser: state.authenticatedUser,
    tickets: state.tickets,
    customerObjects: state.customerObjects,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('tickets')(Tickets));
