import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { generatePath, withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { PageHeader } from "antd";
import Page from "../../components/Page";
import EditUserForm from "../../components/shared/EditUserForm";
import { fetchUser } from "../../store/actions/user";
import "./UserEdit.less";

const UserEdit = (props) => {
  const {
    t,
    user,
    match: {
      params: { id }
    },
    history,
    fetchUser
  } = props;

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  return (
    <Page breadcrumb="settings_user_profile">
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={t("headerEditUser")}
      />
      {!user ? <React.Fragment /> : <EditUserForm user={user} />}
    </Page>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUser
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation("users")(UserEdit));
