import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown'
import { withTranslation } from 'react-i18next';
import { Typography } from 'antd';
import '../styles.less';

const Documentation = (props) => {
  const { documentation, t } = props;

  return (
    <>
      <Typography.Title level={3}>{t('documentation')}</Typography.Title>
      <div className='documentation-container'>
        {documentation?.doc &&
          <ReactMarkdown children={documentation.doc} />
        }
      </div>
    </>
  )
}

Documentation.propTypes = {
  documentation: PropTypes.object,
  t: PropTypes.func,
}

export default withTranslation('systemEnvironment')(Documentation);