import { put, call, takeEvery, delay } from "redux-saga/effects";
import { v1 as uuid } from "uuid";
import { NOTIFICATION } from "../../shared/constants";
import * as types from "../types";

export function* newNotification(options) {
  const notification = {
    uuid: uuid(),
    delay: 3000,
    ...options
  };

  yield put({
    type: types.ADD_NOTIFICATION,
    payload: notification
  });

  if (notification.delay > 0) {
    yield delay(notification.delay);
    yield put({
      type: types.REMOVE_NOTIFICATION,
      payload: notification
    });
  }
}

export function* newInfoNotification(action) {
  yield call(
    newNotification,
    Object.assign(action.payload, {
      level: NOTIFICATION.LEVELS.INFO,
      title: "Info"
    })
  );
}
export function* newSuccessNotification(action) {
  yield call(
    newNotification,
    Object.assign(action.payload, {
      level: NOTIFICATION.LEVELS.SUCCESS,
      title: "Success"
    })
  );
}
export function* newWarningNotification(action) {
  yield call(
    newNotification,
    Object.assign(action.payload, {
      level: NOTIFICATION.LEVELS.WARNING,
      title: "Warning"
    })
  );
}
export function* newErrorNotification(action) {
  yield call(
    newNotification,
    Object.assign(action.payload, {
      level: NOTIFICATION.LEVELS.ERROR,
      title: "Error",
      delay: 0
    })
  );
}

export function* notificationSagas() {
  yield takeEvery(types.NEW_NOTIFICATION_INFO, newInfoNotification);
  yield takeEvery(types.NEW_NOTIFICATION_SUCCESS, newSuccessNotification);
  yield takeEvery(types.NEW_NOTIFICATION_WARNING, newWarningNotification);
  yield takeEvery(types.NEW_NOTIFICATION_ERROR, newErrorNotification);
}

export default notificationSagas;
