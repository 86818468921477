import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import PermissionService from '../../service/permission';
import { PERMISSIONS } from '../../shared/constants/permissions';
import {
  saveUser, fetchUser, fetchSshEncryptionTypes, fetchRoles, fetchUserTypes
} from '../../store/actions/user';
import Page from "../../components/Page";
import { Typography, Button, Row, Col, Input, PageHeader, Form, } from 'antd';
import AntdSelect from "../../components/form/AntdSelect";
import AntdInput from "../../components/form/AntdInput";
import AntdTextArea from "../../components/form/AntdTextArea";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import DeleteBtn from '../../components/shared/DeleteBtn';
import history from "../../history";
import { passwordValidationRule, requiredRule } from '../../shared/helper/inputRules';


const ServiceAccountEdit = (props) => {
  const { t, user, userTypes, currentCustomer } = props;

  const onSave = (values) => {
    let formValues = { ...user, ...values };

    if (!formValues.password) {
      formValues.password = 'NA';
    }

    props.saveUser(formValues);
  }

  const isUpdateGranted = () => {
    return PermissionService.isGranted(user?.permissions, PERMISSIONS.UPDATE);
  }

  const getTypeSelectOptions = () => {
    return userTypes && userTypes.data ? Object.keys(userTypes.data).map((key) => ({
      label: userTypes.data[key],
      value: key
    })) : [];
  }

  const getEncryptionTypesOptions = () => {
    const result = [{
      label: '',
      value: '',
    }];
    Object.keys(props?.sshEncryptionTypes?.data || {}).map((key) => {
      result.push({
        label: props?.sshEncryptionTypes?.data[key],
        value: key,
      });
    })
    return result;
  };

  const onFormChange = () => {
    setFormChanged(true);
  };

  const onCancelPress = () => {
    form.resetFields();
    setFormChanged(false);
    history.goBack();
  };

  const convertUserToFormValues = (user) => {
    if (!user) {
      return {};
    }

    return {
      ...user,
      type: `${user.type}`,
      ssh_keys: user.ssh_keys?.map((key) => {
        return {
          ...key,
          public_key_encryption_algorithm: `${key.public_key_encryption_algorithm}`,
        }
      }) || [],
    }
  }

  useEffect(() => {
    const id = props.match.params.id || null;
    if (id) {
      props.fetchUser(id);
    }
    props.fetchSshEncryptionTypes();
    props.fetchRoles();
    props.fetchUserTypes();
  }, []);

  useEffect(() => {
    props.fetchUser(props.match.params.id);
  }, [props.match.params.id]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue(convertUserToFormValues(user));
    }
  }, [user]);

  const [form] = Form.useForm();
  const [formChanged, setFormChanged] = useState(false);

  if (!user || !currentCustomer) return <></>;

  return (
    <Page 
      breadcrumb="settings_service_account_edit" 
      dynamicBreadcrumbs={[{
        breadcrumbName: user?.username,
        path: "",
      }]}
    >
      <PageHeader
        className="site-page-header"
        title={user?.username}
        onBack={() => window.history.back()}
      />

      <Form
        name="editServiceAccount"
        layout="vertical"
        onFinish={onSave}
        form={form}
        onValuesChange={onFormChange}
        initialValues={convertUserToFormValues(user)}
      >
        <Typography.Title style={{marginBottom: 20}} level={5}>{t('headerServiceAccount')}</Typography.Title>

        <AntdSelect
          name="type"
          type="select"
          label={t("labelType")}
          options={getTypeSelectOptions()}
          rules={[requiredRule()]}
          disabled={!isUpdateGranted()}
        />
        
        <Row justify="space-between">
          <Col sm={11} xs={24}>
            <AntdInput
              name="username"
              type="text"
              placeholder={t("users:labelUsername")}
              label={t("users:labelUsername")}
              rules={[requiredRule()]}
              disabled={!isUpdateGranted()}
            />
          </Col>

          <Col sm={12} xs={24}>
            <AntdInput
              name="password"
              type="password"
              placeholder={t("labelNodePasswordSet")}
              label={t("labelNodePasswordSet")}
              rules={[passwordValidationRule(), requiredRule()]}
              disabled={!isUpdateGranted()}
            />
          </Col>
        </Row>

        <Typography.Title style={{marginBottom: 20}} level={5}>{t('headerSshKeys')}</Typography.Title>

        <Form.List name="ssh_keys">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row justify="space-between" key={key}>
                  <Col sm={11} xs={24}>
                    <Form.Item 
                      {...restField}
                      name={[name, 'id']} 
                      style={{ display: 'none' }}
                    >
                      <Input value={null} type="text" />
                    </Form.Item>

                    <AntdInput
                      {...restField}
                      name={[name, 'name']}
                      type="text"
                      placeholder={t('labelSshPublicKeyName')}
                      label={t('labelSshPublicKeyName')}
                      rules={[requiredRule()]}
                      disabled={!isUpdateGranted()}
                    />
      
                    <AntdSelect
                      {...restField}
                      name={[name, 'public_key_encryption_algorithm']}
                      type="select"
                      label={t('labelSshPublicKeyEncryption')}
                      options={getEncryptionTypesOptions()}
                      disabled={!isUpdateGranted()}
                    />
                  </Col>
  
                  <Col sm={12} xs={24}>
                    <Row align="middle">
                      <Col span={22}>
                        <AntdTextArea
                          {...restField}
                          name={[name, 'public_key']}
                          label={t('labelSshPublicKey')}
                          placeholder={t('labelSshPublicKey')}
                          rules={[requiredRule()]}
                          rows={5}
                          flex={1}
                          disabled={!isUpdateGranted()}
                        />
                      </Col>
                      <Col span={2} style={{ textAlign: 'center' }}>
                        {isUpdateGranted() &&
                        <DeleteBtn
                          onConfirm={() => remove(name)} 
                          confirmTitle={t("confirmDeletePublicKey")}
                          okText={t("buttons:confirm")}
                          cancelText={t("buttons:cancel")}
                        />
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}

              <Row justify="space-between">
                <Col>
                  {isUpdateGranted() &&
                  <Button 
                    type="link" 
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >{t('buttons:addPublicKey')}</Button>
                  }
                </Col>

                <Col>
                  <Button 
                    onClick={onCancelPress}
                    style={{ marginRight: 10 }}
                    disabled={!formChanged}
                  >{t('buttons:cancel')}</Button>

                  <Button htmlType="submit" disabled={!formChanged}>{t('buttons:save')}</Button>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </Page>
  )
}

ServiceAccountEdit.propTypes = {
  currentCustomer: PropTypes.object,
  authenticatedUser: PropTypes.object,
  users: PropTypes.array,
  user: PropTypes.object,
  saveUser: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchSshEncryptionTypes: PropTypes.func,
  t: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveUser,
    fetchUser,
    fetchSshEncryptionTypes,
    fetchRoles,
    fetchUserTypes
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    authenticatedUser: state.authenticatedUser,
    currentCustomer: state.currentCustomer,
    users: state.users,
    user: state.user,
    roles: state.roles,
    userTypes : state.userTypes,
    sshEncryptionTypes: state.sshEncryptionTypes,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('users')(ServiceAccountEdit));
