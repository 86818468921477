import React from 'react';
import PropTypes from 'prop-types';
import { Typography, } from 'antd';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { saveCustomerReference } from '../../store/actions/billing';
import AntdTable from "../global/AntdTable";
import PermissionService from '../../service/permission';
import { PERMISSIONS } from '../../shared/constants/permissions';
import InlineEditText from '../form/InlineEditTextNew';

const InvoiceTable = (props) => {
  const { data, t } = props;

  const sortByPropertyValue = (a, b, propertyName) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }

    if (a[propertyName] > b[propertyName]) {
      return 1;
    }

    return 0;
  }

  const sortCustomerReference = (a, b) => {
    const aValue = a.customer_reference || '';
    const bValue = b.customer_reference || '';
    if (aValue < bValue) {
      return -1;
    }

    if (aValue > bValue) {
      return 1;
    }

    return 0;
  }

  const onSaveCustomerReference = (pricing, updatedCustomerReference) => {
    const updatedPricing = {
      ...pricing,
      customer_reference: updatedCustomerReference,
    }

    if (!updatedPricing.customer_reference) {
      updatedPricing.customer_reference = '';
    }
    props.saveCustomerReference(updatedPricing);
  }

  const tableColumns = [
    {
      title: t('amount'),
      dataIndex: 'amount',
      sorter: (a, b) => sortByPropertyValue(a, b, 'amount'),
      width: '5%',
    },
    {
      title: t('name'),
      dataIndex: 'name',
      sorter: (a, b) => sortByPropertyValue(a, b, 'name'),
      width: '15%',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      sorter: (a, b) => sortByPropertyValue(a, b, 'description'),
      width: '20%',
    },
    {
      title: t('price'),
      sorter: (a, b) => sortByPropertyValue(a, b, 'price'),
      render: (_, pricing) => (
        <Typography.Text>{pricing.price.toFixed(2)} {pricing.price_unit}</Typography.Text>
      ),
      width: '15%',
    },
    {
      title: t('type'),
      dataIndex: 'price_type',
      sorter: (a, b) => sortByPropertyValue(a, b, 'price_type'),
      width: '15%',
    },
    {
      title: t('customerReference'),
      dataIndex: 'customer_reference',
      sorter: sortCustomerReference,
      render: (_, pricing) => (
        <InlineEditText
          text={t("confirmUpdateCustomerReference")}
          okText={t("buttons:update")}
          cancelText={t("buttons:cancel")}
          name="customer_reference"
          isGranted={PermissionService.isGranted(pricing.permissions, PERMISSIONS.UPDATE)}
          value={pricing.customer_reference}
          onUpdate={(value) => {
            onSaveCustomerReference(pricing, value);
          }}
        />
      ),
    },
  ]

  return (
    <AntdTable
      className='invoice-table'
      rowKey={(record) => record.id}
      columns={tableColumns}
      data={data}
    />
  )
}

InvoiceTable.propTypes = {
  data: PropTypes.array,
  t: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveCustomerReference,
  }, dispatch);
}

export default compose(
  connect(null, mapDispatchToProps)
)(withTranslation('systemEnvironment')(InvoiceTable));
