import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Page from "../../components/Page";
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageHeader, Row, Col } from 'antd';
import { fetchPools, setPool } from '../../store/actions/systemEnvironment';
import history from "../../history";
import PublicLocationCard from './PublicLocationCard';
import PrivateLocationCard from './PrivateLocationCard';
import './styles.less';

const Locations = (props) => {
  const { t, currentCustomer, pools } = props;

  const onPrivateLocationClick = (pool) => {
    if (pool?.servergroup) {
      props.setPool(pool);
      history.push(`/pools/${pool.id}/server_groups/${pool.servergroup}`);
    }
  }

  useEffect(() => {
    const customerId = currentCustomer?.id;
    if (customerId) {
      props.fetchPools(customerId);
    }
  }, [currentCustomer]);

  const cardHeight = 121;

  return (
    <Page breadcrumb="locations">
      <PageHeader
        className="site-page-header"
        title={t('allLocations')}
      />

      <Row gutter={10}>
        {pools.map((pool, index) => {
          return (
            <Col key={`${index}`} style={{ marginBottom: 10 }} xs={24} sm={24} md={8}>
              {pool.is_public
              ?
              <PublicLocationCard height={cardHeight} pool={pool} />
              :
              <PrivateLocationCard height={cardHeight} pool={pool} onClick={onPrivateLocationClick} />
              }
            </Col>
          )
        })}
      </Row>
    </Page>
  )
}

Locations.propTypes = {
  t: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPools,
    setPool,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    pools: state.pools,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation('locations')(Locations));

