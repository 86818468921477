import { takeEvery, put, call, select } from "redux-saga/effects";
import * as types from "../types";
import UserAccessService from "../../service/userAccess";
import i18n from "../../shared/i18n";
import {
  errorNotification,
  successNotification
} from "../actions/notification";
import { waitFor } from "./general";
import PermissionService from "../../service/permission";
import { PERMISSIONS } from "../../shared/constants/permissions";

function* fetchUserAccessForServerGroup(action) {
  try {
    yield call(waitFor, (state) => state.token != null);
    const { token, serverGroup } = yield select((state) => state);
    // if(PermissionService.isGranted(serverGroup.permissions, PERMISSIONS.CREATE_ENVIRONMENT_ACCESS)){

    const userAccessService = yield call(() => new UserAccessService(token));
    const userAccess = yield call(
      [userAccessService, userAccessService.getUserAccessForServerGroup],
      action.payload
    );
    yield put({
      type: types.SET_USER_ACCESS_SERVER_GROUP,
      payload: userAccess
    });
    // }
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("userAccess:fetchUserAccessError", {
          error: error.message
        })
      })
    );
    console.error("fetchUserAccess error", error);
  }
}

function* createUserAccessForServerGroup(action) {
  try {
    const { token } = yield select((state) => state);
    const userAccessService = yield call(() => new UserAccessService(token));
    let { serverGroupId, userAccess } = action.payload;
    const result = yield call(
      [userAccessService, userAccessService.createUserAccessForServerGroup],
      serverGroupId,
      userAccess
    );
    yield put({
      type: types.FETCH_USER_ACCESS_SERVER_GROUP,
      payload: serverGroupId
    });
    yield put(
      successNotification({
        message: i18n.t("userAccess:saveUserAccessSuccess")
      })
    );
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("userAccess:saveUserAccessError", {
          error: error.message
        })
      })
    );
    console.error("saveUserAccessForServerGroupError error", error);
  }
}

function* removeUserAccess(action) {
  try {
    const { token, cluster, serverGroup } = yield select((state) => state);
    const userAccessService = yield call(() => new UserAccessService(token));
    const result = yield call(
      [userAccessService, userAccessService.removeUserAccess],
      action.payload
    );
    yield put({
      type: types.FETCH_USER_ACCESS_SERVER_GROUP,
      payload: serverGroup.id
    });
    yield put({
      type: types.FETCH_USER_ACCESS_CLUSTER,
      payload: cluster.id
    });
    yield put(
      successNotification({
        message: i18n.t("userAccess:removeUserAccessSuccess")
      })
    );
  } catch (error) {
    yield put(
      errorNotification({
        message: i18n.t("userAccess:removeUserAccessError", {
          error: error.message
        })
      })
    );
    console.error("removeUserAccessError error", error);
  }
}
export function* userAccessSagas() {
  yield takeEvery(
    types.FETCH_USER_ACCESS_SERVER_GROUP,
    fetchUserAccessForServerGroup
  );
  yield takeEvery(
    types.CREATE_USER_ACCESS_SERVER_GROUP,
    createUserAccessForServerGroup
  );
  yield takeEvery(types.REMOVE_USER_ACCESS, removeUserAccess);
}

export default userAccessSagas;
