import * as types from '../types';

const moduleReducers = {
  tickets: setTickets,
  ticket: setTicket,
  customerObjects: setCustomerObjects,
  requests: setRequests,
};

function setTickets(state = [], action) {
  switch (action.type) {
  case types.SET_TICKETS:
    return action.payload;
  case types.ADD_TICKET:
    return [ ...state, action.payload ];
  default:
    return state;
  }
}

function setTicket(state = null, action) {
  switch (action.type) {
  case types.SET_TICKET:
    return action.payload;
  default:
    return state;
  }
}

function setCustomerObjects(state = {}, action) {
  switch (action.type) {
  case types.SET_CUSTOMER_OBJECTS:
    return { ...state, ...action.payload };
  default:
    return state;
  }
}

function setRequests(state = {}, action) {
  switch (action.type) {
  case types.SET_REQUESTS:
    return action.payload;
  default:
    return state;
  }
}

export default moduleReducers;
