import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ServerCard from '../ServerCard';
import { Row, Col } from 'antd';

const ServerDetails = (props) => {
  const { serverGroup } = props;

  return (
    <Row gutter={10}>
      {serverGroup.servers?.map((server, index) => {
        return (
          <Col key={`${index}`} style={{ marginBottom: 10 }} span={12}>
            <ServerCard server={server} />
          </Col>
        )
      })}
    </Row>
  )
}

ServerDetails.propTypes = {
  t: PropTypes.func,
  serverGroup: PropTypes.object,
}

export default withTranslation('systemEnvironment')(ServerDetails);