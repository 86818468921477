import React from 'react';
import PermissionService from '../../service/permission';
import PropTypes from 'prop-types';

function PermissionedComponent(props) {
  let isGranted = ()=> PermissionService.isGranted( props.grantedPermissions, props.requestedPermission);

  if(!isGranted()){
    return props.deniedComponent || <React.Fragment/>;
  }else{
    return props.children;
  }  
}

PermissionedComponent.propTypes = {
  grantedPermissions:PropTypes.object,
  requestedPermission:PropTypes.string,
  deniedComponent:PropTypes.func,
};

export default PermissionedComponent;
