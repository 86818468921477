import React, { memo } from "react";
import { Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setRedirectAfterLogin } from "../../store/actions/auth";
import KeycloakService from "../../service/KeycloakService";
import i18n from "../../shared/i18n";

const ProtectedRoute = ({
  component: Comp,
  errorComponent: ErrorComp,
  ...rest
}) => {
  const authenticatedUser = useSelector((state) => state.authenticatedUser);
  const loggingIn = useSelector((state) => state.loggingIn);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggingIn || authenticatedUser) {
          return <Comp {...props} />;
        } else {
          if (ErrorComp) {
            return <ErrorComp />;
          } else {
            dispatch(setRedirectAfterLogin(location.pathname));
            KeycloakService.doLogin({ locale: i18n.language.substring(0, 2) });
          }
        }
      }}
    />
  );
};

export default memo(ProtectedRoute);
